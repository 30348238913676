import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { Modal } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mergeCopy } from '../../utils/cms_copy_provider';

// import '../../utils/fontAwesome';
import './style.scss';

class Winner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            show: false,
            selected_drawings: null,
            error: null,
        };
    }

    async componentDidMount() {
        const winnersUrl = '/api/sweepstake/drawing/' + this.props.selected.id + '/winners';

        await fetch(winnersUrl)
            .then((res) => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        selected_drawings: result,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error,
                    });
                }
            );
    }

    formatPrizeAmount = (amount) => {
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });

        // divide by 100 as our value is in cents
        return currencyFormatter.format(amount / 100);
    };

    handleClose = () => {
        this.setState({ show: false });
    };

    handleShow = () => {
        this.setState({ show: true });
    };

    handleKeyShow = (event) => {
        if (event.key === 'Enter') {
            this.setState({ show: true });
        }
    };

    handleKeyClose = (event) => {
        if (event.key === 'Enter') {
            this.setState({ show: false });
        }
    };

    // see if the given array contains the given value
    arrContains(arr, v) {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] === v) return true;
        }
        return false;
    }

    // essentially a unique item finder for objects in an array
    // this one lists all of the unique prizes
    uniquePrizes = function (winnerList) {
        var arr = [];
        winnerList.forEach((element) => {
            if (!this.arrContains(arr, element.prize_name)) {
                arr.push(element.prize_name);
            }
        });
        return arr;
    };

    render() {
        const { isLoaded, selected_drawings, error } = this.state;

        const defaultCopy = {
            winners: {
                footnote: '',
            },
        };

        const usedCopy = mergeCopy(defaultCopy, this.props.copyData);

        if (isLoaded && selected_drawings) {
            let prizeNameDisplay = {};

            selected_drawings.forEach((element) => {
                if (element.prize_amount && !prizeNameDisplay[element.prize_name]) {
                    prizeNameDisplay[element.prize_name] = this.formatPrizeAmount(element.prize_amount);
                }
            });

            return (
                <>
                    <a
                        id={`winners-link-${this.props.selected.id}`}
                        className='winners-link'
                        role='button'
                        tabIndex='0'
                        onKeyDown={this.handleKeyShow}
                        onClick={this.handleShow}>
                        View Winners
                    </a>
                    <Modal
                        id={`winners-modal-${this.props.selected.id}`}
                        dialogClassName='winners-modal'
                        show={this.state.show}
                        onHide={this.handleClose}
                        centered>
                        <Modal.Header>
                            <a
                                className='modal-close-element'
                                title='Close'
                                tabIndex='0'
                                role='button'
                                onKeyDown={this.handleKeyClose}
                                onClick={this.handleClose}>
                                X
                                {/* <FontAwesomeIcon
                                    icon={['fas', 'times-circle']}
                                    size="3x"
                                /> */}
                            </a>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='winners'>
                                <table className='table table-striped d-none d-md-table'>
                                    <thead>
                                        <tr>
                                            <th>{this.props.drawingName}</th>
                                            <th>
                                                <div className='row text-center'>
                                                    <div className='col'>Winners</div>
                                                    <div className='col'>City, State</div>
                                                    <div className='col'>Ticket Number</div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.uniquePrizes(selected_drawings).map((prize) => {
                                            return (
                                                <tr key={`dsk-${prize + this.props.selected.id}`}>
                                                    <td>{prizeNameDisplay[prize] ? prizeNameDisplay[prize] : prize}</td>
                                                    <td>
                                                        {selected_drawings
                                                            .filter((element) => {
                                                                return element.prize_name == prize;
                                                            })
                                                            .map(({ firstname, lastname, city, state, prize_name, id, source }) => {
                                                                return (
                                                                    <div className='row text-center' key={firstname + lastname + prize_name + id}>
                                                                        <div className='col'>{`${firstname} ${lastname}`}</div>
                                                                        <div className='col'>{`${city}, ${state}`}</div>
                                                                        <div className='col'>{source}</div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                                <table className='table table-striped d-md-none'>
                                    <thead>
                                        <tr>
                                            <th>Drawing {' ' + this.props.selected.id}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.uniquePrizes(selected_drawings).map((prize) => {
                                            return (
                                                <tr key={`mob-${prize + this.props.selected.id}`}>
                                                    <td>
                                                        {selected_drawings
                                                            .filter((element) => {
                                                                return element.prize_name == prize;
                                                            })
                                                            .map(({ firstname, lastname, city, state, prize_name, id, source }) => {
                                                                return (
                                                                    <div className='mobile-winner-chunk' key={firstname + lastname + prize_name + id}>
                                                                        <div className='row'>
                                                                            <div className='col'>
                                                                                <strong>Prize</strong>
                                                                            </div>
                                                                            <div className='col'>
                                                                                {prizeNameDisplay[prize] ? prizeNameDisplay[prize] : prize}
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col'>
                                                                                <strong>Winner</strong>
                                                                            </div>
                                                                            <div className='col'>{`${firstname} ${lastname}`}</div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col'>
                                                                                <strong>City, State</strong>
                                                                            </div>
                                                                            <div className='col'>{`${city}, ${state}`}</div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className='col'>
                                                                                <strong>Ticket Number</strong>
                                                                            </div>
                                                                            <div className='col'>{source}</div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>{usedCopy?.winners?.footnote}</Modal.Footer>
                    </Modal>
                </>
            );
        } else if (isLoaded && error) {
            return null;
        } else {
            return <span className='loading-text'>Loading Winners...</span>;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Winner);

import React from 'react';

// This is a helper class for components that get data through
// useContext rather than through props.

/*
A parent element will look like this:
const DrawingCarousel = ({ children }) => {
    return (
        <DataProvider>
            <div className="drawCarousel">{children}</div>
        </DataProvider>
    );
};

Data can be provided at runtime in a component like this:
<DrawingCarousel>
    <CurrentDrawingData />
</DrawingCarousel>

If rendered afterward, the data can be edited in context:
<DrawingCarousel>
    <CurrentDrawingData />
    <FormatNextDrawings />
</DrawingCarousel>

see FormatNextDrawings for the use.

Then is a child component uses the useData() method,
they can gain access to the data like this:
const { state, dispatch } = useData();

return (
    <CarouselMultiple show={3} infiniteLoop={true}>
        {state.data.map((val, index) => {
            return (
                <div className="next-drawing-card" key={index}>
                    <h3>{val.promotion}</h3>
                    <div>{val.drawing_date}</div>
                    <div className="more-chances">
                        <p>Want more chances to win?</p>
                        <a className="btn theme-btn btn-block" href="enter">
                            Enter Now
                        </a>
                    </div>
                </div>
            );
        })}
    </CarouselMultiple>
);

Which in the CMS looks like this:
<DrawingCarousel>
    <CurrentDrawingData />
    <FormatNextDrawings />
    <CarouselItem />
</DrawingCarousel>
*/

const DataContext = React.createContext({ data: [] });

function dataReducer(state, action) {
    switch (action.type) {
        case 'assign': {
            return { data: action.payload };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function DataProvider({ children }) {
    const [state, dispatch] = React.useReducer(dataReducer, { data: [] });
    const value = { state, dispatch };
    return (
        <DataContext.Provider value={value}>{children}</DataContext.Provider>
    );
}

function useData() {
    const context = React.useContext(DataContext);
    if (context === undefined) {
        throw new Error('useData must be used within a DataProvider');
    }
    return context;
}

export { DataProvider, useData };

import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';

import UpdateLogin from './MyProfile/UpdateLogin';
import UpdateIdentification from './MyProfile/UpdateIdentification';
import UpdateContact from './MyProfile/UpdateContact';
import UpdateNotification from './MyProfile/UpdateNotification';

import UserLockout from '../utils/user_lockout';

import './MyProfile/style.scss';

export class MyProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    passwordExpired(actions) {
        if (actions?.includes('change-password')) {
            return (
                <div className='alert alert-danger text-center my-4' role='alert'>
                    <p className='my-0'>Your password has expired. Please update it now to continue using all of the features of 2nd Chance</p>
                </div>
            );
        }
    }

    render() {
        return (
            <UserLockout currentSection='my-profile'>
                <div className='my-profile-container'>
                    <div className='page-head-title'>My Profile</div>
                    <div className='my-5' />
                    {this.passwordExpired(this.props.user.actions)}
                    <UpdateLogin />
                    <div className='my-5' />
                    <UpdateIdentification />
                    <div className='my-5' />
                    <UpdateContact />
                    <div id='notification' className='my-5' />
                    <UpdateNotification />
                </div>
            </UserLockout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);

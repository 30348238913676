import React from 'react';

export const IfNotMobile = ({ children }) => {
    const mdiIsNativeShell = () => {
        // an array (true) or null (false) is returned and cast to boolean
        return !!window.navigator.userAgent.match(/(Mdi Native Shell)/);
    };

    if (!mdiIsNativeShell()) {
        return <>{children}</>;
    } else {
        return null;
    }
};

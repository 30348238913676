import React, { useContext, useState } from 'react';
import * as yup from 'yup';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import IndexedDataContent from '../IndexedDataContent';
import { PasswordField, getCMSObject } from 'sg-ui-components';
const HandlerContext = React.createContext(() => {});
const ErrorContext = React.createContext(() => {});

export const LoginFormTemplate = ({ user, actions, children }) => {
    const initialState = {
        email: '',
        password: '',
        terms_check: '',
    };

    const schema = yup.object().shape({
        email: yup.string().email('invalid_email').required('email_required'),
        password: yup
            .string()
            .matches(/^(?!\s+$).*/, 'password_required')
            .required('password_required'),
    });

    const [state, setState] = useState(initialState);

    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            [name]: value,
        });
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(state);

        if (valid) {
            actions.userActions.login(state);
        } else {
            await schema.validate(state, { abortEarly: false }).catch(function (err) {
                console.log(getValidationErrors(err));
                actions.userActions.setErrors({
                    errors: getValidationErrors(err),
                });
            });
        }
    };

    const getValidationErrors = (err) => {
        let validationError = '';

        if (err.inner[0].path) {
            validationError = err.inner[0].message;
        }

        return validationError;
    };

    const formUrl = '/users/login.php';

    return (
        <div className='form-container'>
            <div id='login-target'>
                <form name='player_login' id='player_login' action={formUrl} method='post' encType='application/x-www-form-urlencoded' onSubmit={handleLogin}>
                    <input type='hidden' id='form_submitted' name='form_submitted' value='1' />

                    <HandlerContext.Provider value={handleChange}>
                        <ErrorContext.Provider value={{ code: user.errors, data: user.errorData }}>{children}</ErrorContext.Provider>
                    </HandlerContext.Provider>

                    <div className='text-center'>
                        <p>
                            <a href='/register'>Don't have an account? Register Now</a>
                        </p>
                    </div>
                    <div className='text-center'>
                        <p className='important-note'>Do not mail in your scratch tickets for entry into a 2nd Chance Promotion.</p>
                    </div>
                </form>
            </div>
        </div>
    );
};

const LoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginFormTemplate);

const LoginEmail = () => {
    const handler = useContext(HandlerContext);
    const errors = useContext(ErrorContext);

    const emailError =
        errors.code == '_NOT_FOUND_MODEL' || errors.code == 'invalid_email' || errors.code == 'email_required' ? (
            <IndexedDataContent digest='data.messages.errorMessages.jsonBlock' error={errors.code} data={errors.data} />
        ) : null;

    return (
        <div className='form-group'>
            <label htmlFor='email' className={emailError ? 'text-danger' : ''}>
                Email Address:
            </label>
            <input
                type='text'
                id='email'
                name='email'
                required={true}
                onChange={handler}
                placeholder='Enter Email'
                className={`form-control ${emailError ? 'is-invalid' : ''}`}
            />
            <small>username@domain.com</small>
            <small className='text-danger'>{emailError}</small>
        </div>
    );
};

const LoginPassword = () => {
    const [state, setState] = useState({});
    const handler = useContext(HandlerContext);
    const errors = useContext(ErrorContext);

    const pwError =
        errors.code == '_LOGIN_LOCKED' || errors.code == '_TOO_MANY_TRIES' || errors.code == 'password_required' || errors.code == '_BAD_LOGIN' ? (
            <IndexedDataContent digest='data.messages.errorMessages.jsonBlock' error={errors.code} data={errors.data} />
        ) : null;
    const loginTelescript = getCMSObject('data.components.teleScripts.login.jsonBlock');

    return (
        <div className='form-group'>
            <PasswordField
                type={state.page_show_pw ? 'text' : 'password'}
                id='password'
                required={true}
                name='password'
                placeholder={loginTelescript?.passwordPlaceholder ?? 'Enter Password'}
                handleChange={handler}
            />

            <small>
                <a href='/forgot-password'>Forgot password</a>
            </small>
            <small className='text-danger'>{pwError}</small>
        </div>
    );
};

const LoginSubmit = () => {
    return (
        <div>
            <input type='submit' name='submit' value='Submit' className='btn theme-btn login-btn' />
        </div>
    );
};

export { LoginForm, LoginEmail, LoginPassword, LoginSubmit };

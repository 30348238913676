import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import { Row, Col } from 'react-bootstrap';
import isBrowser from '../utils/isBrowser';

import DrawingTable from './SweepstakeDetails/DrawingTable';

import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from '../Components/ContentBox';

import './SweepstakeDetails/style.scss';

//* Retrieve ID from url params
const queryString = isBrowser() ? window.location.search : '';
const urlParams = new URLSearchParams(queryString);
const preselection = {
    sweepId: parseInt(urlParams.get('id')),
};

export class SweepstakeDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.actions.sweepActions.getSweeps();
    }

    render() {
        const sweeps = this.props.sweep.sweeps ?? null;
        if (!sweeps || !Array.isArray(sweeps)) return null;

        const sweep = sweeps.filter((s) => s.id === preselection.sweepId)[0];
        if (!sweep) return null;

        const image = sweep?.extra?.image ?? null;
        const description = sweep?.extra?.description ?? null;
        const drawings = sweep?.drawing_groups ?? null;
        const prizes = sweep?.drawing_groups[0] ? sweep.drawing_groups[0].prizes : null;
        const faqs = sweep?.extra?.faqs ?? null;
        const rules = sweep?.extra?.rules ?? null;

        //? For debug: console.log('🎁 Sweepstake details data:', { "sweepID" : preselection.sweepId , "sweep data" : sweep , "prizes_data" : prizes });

        return (
            <div>
                <div id='promotion-page-title'>
                    {image ? (
                        <Row>
                            <Col lg={4} className='my-3'>
                                <img src={sweep?.extra?.image ?? null} alt={sweep?.name} className='w-100 rounded-image' />
                            </Col>
                            <Col lg={8} className='my-3'>
                                <h3>{sweep.name}</h3>
                                <div dangerouslySetInnerHTML={{ __html: description }}></div>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col lg={12} className='my-3'>
                                <h3>{sweep.name}</h3>
                                <div dangerouslySetInnerHTML={{ __html: description }}></div>
                            </Col>
                        </Row>
                    )}
                </div>

                {prizes && (
                    <ContentBox variant='theme-black' className='promotion-page-section my-4'>
                        <ContentBoxHead>
                            Prizes
                            <ContentCollapser />
                        </ContentBoxHead>
                        <ContentBoxBody>
                            {drawings.map((thisDrawingGroup) => {
                                return (
                                    <>
                                        {thisDrawingGroup.prizes.map((prize_item, index) => {
                                            const prize_image = prize_item?.extra?.image ?? null;
                                            const prize_description = prize_item?.extra?.description ?? null;

                                            return (
                                                <div key={index} className='prize-content my-4 mx-md-5'>
                                                    <h3 className='prizes-tier'>{prize_item?.tier}</h3>
                                                    {prize_image ? (
                                                        <Row>
                                                            <Col lg={4} className='my-3'>
                                                                <div className='prize-img-container'>
                                                                    <img src={prize_image} alt={drawings.name} className='img-fluid prize-img' />
                                                                </div>
                                                            </Col>
                                                            <Col lg={8} className='my-lg-3 pt-4'>
                                                                <h4 className='prizes-title'>{prize_item?.name}</h4>
                                                                <h5 className='prizes-winner'>
                                                                    {prize_item?.quantity} {prize_item?.quantity > 1 ? 'Winners' : 'Winner'}
                                                                </h5>
                                                                <div className='prizes-description' dangerouslySetInnerHTML={{ __html: prize_description }} />
                                                            </Col>
                                                        </Row>
                                                    ) : (
                                                        <Row>
                                                            <Col lg={12} className='my-3'>
                                                                <h4 className='prizes-title'>{prize_item?.name}</h4>
                                                                <h5 className='prizes-winner'>
                                                                    {prize_item?.quantity} {prize_item?.quantity > 1 ? 'Winners' : 'Winner'}
                                                                </h5>
                                                                <div dangerouslySetInnerHTML={{ __html: prize_description }} />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </>
                                );
                            })}
                        </ContentBoxBody>
                    </ContentBox>
                )}

                <ContentBox variant='theme-black' className='promotion-page-section my-5'>
                    <ContentBoxHead>
                        Drawings
                        <ContentCollapser />
                    </ContentBoxHead>
                    <ContentBoxBody>
                        <DrawingTable drawings={drawings} />
                    </ContentBoxBody>
                </ContentBox>

                {faqs && (
                    <ContentBox variant='theme-black' className='promotion-page-section my-5'>
                        <ContentBoxHead>
                            FAQ<span className='lowercase'>S</span>
                            <ContentCollapser />
                        </ContentBoxHead>
                        <ContentBoxBody>
                            <div dangerouslySetInnerHTML={{ __html: faqs }}></div>
                        </ContentBoxBody>
                    </ContentBox>
                )}

                {rules && (
                    <ContentBox variant='theme-black' className='promotion-page-section my-5'>
                        <ContentBoxHead>
                            Rules
                            <ContentCollapser />
                        </ContentBoxHead>
                        <ContentBoxBody>
                            <div dangerouslySetInnerHTML={{ __html: rules }}></div>
                        </ContentBoxBody>
                    </ContentBox>
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SweepstakeDetails);

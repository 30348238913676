import { createSlice } from '@reduxjs/toolkit';
import apiClient from '../Api/api_client';
import { loadingSlice } from './LoadingStore';

export const defaultWinnerState = {
    config: {},
};

// REDUCERS
// basic actions that affect this object only

const getConfigReducer = (state, action) => {
    return {
        config: action.payload,
    };
};

export const configSlice = createSlice({
    name: 'config',
    initialState: defaultWinnerState,
    reducers: {
        getConfig: getConfigReducer,
    },
});

//ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const getConfig = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'getConfig' }));
    const result = await apiClient().getConfig(payload);
    dispatch(configSlice.actions.getConfig(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'getConfig' }));
};

//actions that get exported to the views
export const configActions = (dispatch) => {
    return {
        getConfig: (payload) => getConfig(dispatch, payload),
    };
};

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import * as yup from 'yup';

import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from '../ContentBox';

const ForgotPassword = ({ loading, user, actions }) => {
    const initialStepFields = {
        email: '',
    };

    const schema = yup.object().shape({
        email: yup.string().email('Email is invalid').required('Email is required').max(200, 'Email cannot exceed 200 characters'),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState('');
    const [localErrors, setLocalErrors] = useState({});

    // if the form field changes, we will change the value in the store and
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trimStart();
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(stepFields);

        if (valid) {
            await actions.loadingActions.setLoading({ action: 'forgotPassword' });

            setError('');
            setLocalErrors({});

            await actions.userActions.updateSection({ section: 'forgot-password', status: 'initial' });

            const payloadData = {
                email: stepFields.email,
            };

            await actions.userActions.forgotPassword(payloadData);
        } else {
            await schema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                setLocalErrors(getValidationErrors(err));
                setError(err.message);
            });
        }
    };

    const getValidationErrors = (err) => {
        const validationErrors = {};

        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);

        return validationErrors;
    };

    return (
        <>
            <div className='login-container'>
                <div className='my-5' />
                <ContentBox variant='theme-purple'>
                    <ContentBoxHead>FORGOT MY PASSWORD</ContentBoxHead>
                    <ContentBoxBody>
                        <div className='registration-step'>
                            <div className='inner-step'>
                                {error ? (
                                    <div className='alert alert-danger text-center' role='alert'>
                                        {Object.values(localErrors).map((err, index) => {
                                            return <p key={index}>{err}</p>;
                                        })}
                                    </div>
                                ) : (
                                    user.updateSection.section === 'forgot-password' &&
                                    user.updateSection.status === 'error' && (
                                        <div className='alert alert-danger text-center' role='alert'>
                                            This email does not belong to any users.
                                        </div>
                                    )
                                )}

                                {user.updateSection.section === 'forgot-password' && user.updateSection.status === 'success' && (
                                    <div className='alert alert-success text-center' role='alert'>
                                        An email has been sent. Please follow the link in your email to reset your password.
                                    </div>
                                )}

                                <div className='form-group'>
                                    <label htmlFor='enterEmail'>Email:</label>
                                    <input
                                        type='email'
                                        className='form-control'
                                        autoComplete='off'
                                        id='enterEmail'
                                        value={stepFields.email}
                                        name='email'
                                        onChange={handleChange}
                                        placeholder='Enter email'
                                    />
                                    <small>username@domain.com</small>
                                </div>

                                <button
                                    type='button'
                                    className='btn theme-btn btn-block theme-gray'
                                    onClick={handleSubmit}
                                    disabled={loading.actions['forgotPassword']}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </ContentBoxBody>
                </ContentBox>
            </div>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

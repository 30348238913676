import React, { useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
// import SEO from '../Components/seo';

import { CheckTicketResponse } from '../Components/Postclaim/EntryResponseMatrix';
// import GamePlay from '../Components/reveal/gamePlay';
import promotion from '../promotion_config';
import pluralize from 'pluralize';
import { titleCase } from 'title-case';
import CurrentSweeps from '../Components/Postclaim/CurrentSweeps';

import '../Components/Postclaim/style.scss';

export const PostClaim = ({ user, entry, config, notification, revealToken, actions, location, layoutData, pageData }) => {
    const [revealEntries, setRevealEntries] = useState(true);

    const onNewTicket = (e) => {
        e.preventDefault();

        actions.igt24TicketActions.reset();

        window.location.href = '/enter';
    };

    const bonusAwardedFilter = (bonus) => bonus.result && bonus.result === 'awarded';

    const showEntry = () => {
        const awardedBonuses = entry.last_entry.Bonus ? entry.last_entry.Bonus.filter(bonusAwardedFilter) : [];

        if (awardedBonuses.length) {
            const imgUrl =
                (config.config.image_host &&
                    (config.config.image_host.startsWith('https://') ? config.config.image_host : `https://${config.config.image_host}`)) ||
                '';

            return awardedBonuses.map((bonus) => {
                return bonus.rewards.map((reward) => {
                    if (reward.reward && reward.reward.currency != 'counterfeit') {
                        const rewardPhrase = reward.reward.prize_name.split(' ');
                        let rewardToPluralize = rewardPhrase.pop();
                        rewardToPluralize = pluralize(titleCase(rewardToPluralize), reward.reward.actual_amount, false);
                        const rewardString = titleCase(rewardPhrase.join(' '));
                        return (
                            <div className='row my-2 px-2 px-sm-6 text-center claim-box' key={bonus.promotionname}>
                                <div className='col-xs align-self-center'>
                                    <img
                                        className='img-fluid promotion-logo'
                                        src={`${imgUrl}/fl/promotion_${reward.reward.promotion_id}_thumb.png`}
                                        alt={`${bonus.promotionname} logo`}
                                    />
                                </div>
                                <div className='col-xs align-self-center promotion-info-container'>
                                    <h3 className='sr-only promotion-name'>{bonus.promotionname}</h3>
                                    {reward.reward.currency === 'lotteryproducts' ? (
                                        <>
                                            {promotion.currencyExchange[reward.reward.currency].showQuantity &&
                                                reward.reward.actual_amount > 0 &&
                                                reward.reward.state != 'failed' && (
                                                    <>
                                                        <p className='my-0 reward-amount'>{reward.reward.actual_amount}</p>
                                                    </>
                                                )}
                                            {reward.reward.state != 'failed' && (
                                                <>
                                                    <p className='my-0 prize-name'>
                                                        {rewardString} {rewardToPluralize}
                                                    </p>
                                                </>
                                            )}
                                        </>
                                    ) : reward.reward.state === 'complete' ? (
                                        <>
                                            {promotion.currencyExchange[reward.reward.currency].showQuantity && reward.reward.actual_amount > 0 && (
                                                <p className='my-0 reward-amount'>{reward.reward.actual_amount}</p>
                                            )}
                                            <p className='my-0 prize-name'>
                                                {rewardString} {rewardToPluralize}
                                            </p>
                                            <p className='mt-0 sweeps-cta'>
                                                {/* <CurrentSweeps
                                                    key={
                                                        reward.reward.reward_id
                                                    }
                                                    sweepId={reward.reward.sku}
                                                    state={reward.reward.state}
                                                    linkText="View Draw Schedule"
                                                    failedText="Reward pending. Please check back later."
                                                /> */}
                                            </p>
                                        </>
                                    ) : (
                                        <p className='sweeps-cta'>{pageData.copyInfo?.postlaim?.reward_pending}</p>
                                    )}
                                </div>
                            </div>
                        );
                    } else {
                        return null;
                    }
                });
            });
        }
    };

    function handleRevealEntries(newValue) {
        setRevealEntries(newValue);
    }

    if (entry.last_entry) {
        const lent = entry.last_entry;
        const awardedBonuses = entry.last_entry.Bonus ? entry.last_entry.Bonus.filter(bonusAwardedFilter) : [];

        // const showReveal = () => {
        //     if (awardedBonuses.length) {
        //         return awardedBonuses.map((bonus) => {
        //             if (bonus.reveal.length > 0) {
        //                 const reveals = bonus.reveal;

        //                 // pick a reveal from the array of available reveals
        //                 const randomReveal =
        //                     reveals[Math.floor(Math.random() * reveals.length)];

        //                 const outcomeData = [];
        //                 bonus.rewards[0].awards.forEach((award) => {
        //                     outcomeData.push({
        //                         name: award.name,
        //                         amount: award.amount,
        //                         value: award.value,
        //                         selected: award.selected
        //                             ? award.selected
        //                             : false,
        //                     });
        //                 });

        //                 return (
        //                     <GamePlay
        //                         gameServer={config.config.reveal_host}
        //                         gameId={randomReveal}
        //                         token={revealToken.revealToken}
        //                         outcomeData={outcomeData}
        //                         showEntries={handleRevealEntries}
        //                     />
        //                 );
        //             }
        //         });
        //     }
        // };

        return (
            <Layout user={user} config={config} actions={actions} mainMenu={layoutData} pageData={pageData}>
                {/* <SEO title={pageData.copyInfo?.postlaim?.enter_title} /> */}
                <div className='post-claim'>
                    <div className='row'>
                        <div className='col px-md-5'>
                            <h1>{pageData.copyInfo?.postlaim?.enter_title}</h1>
                        </div>
                    </div>
                    {!!lent.Bonus && lent.Bonus.length && lent.Bonus[0].result && lent.Bonus[0].result === 'playerlimited' ? (
                        <div className='row'>
                            <div className='col-lg-10 offset-lg-1'>
                                <div className='description pb-3'>{pageData.copyInfo?.postlaim?.exceeded_tickets}</div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='row'>
                                <div className='col-lg-10 offset-lg-1'>
                                    <div className='description pb-3'>
                                        <CheckTicketResponse ticketCheckInfo={lent.TicketDetails} />
                                    </div>
                                </div>
                            </div>
                            <div id='entry-result-container' className={`row ${revealEntries ? '' : 'hidden'}`}>
                                <div className='col-lg-8 offset-lg-2 col-xs-10 offset-xs-1'>{showEntry()}</div>
                            </div>
                            <div className='row'>{/* <div className="col">{showReveal()}</div> */}</div>
                        </>
                    )}
                    <div className={`row ${revealEntries ? '' : 'hidden'}`}>
                        <div className='col-md-4 offset-md-4 enter-another my-4 text-center'>
                            <a href='/enter' className='enter-now-button all-caps btn btn-theme' onClick={onNewTicket}>
                                {pageData.copyInfo?.postlaim?.enter_another}
                            </a>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col text-center text-danger my-4 content-footnote'>{pageData.copyInfo?.postlaim?.content_footnote}</div>
                    </div>
                </div>
            </Layout>
        );
    } else {
        return (
            <Layout user={user} config={config} actions={actions} mainMenu={layoutData} copy={pageData.copyInfo}>
                {/* <SEO title={pageData.copyInfo?.postlaim?.enter_title} /> */}
                <div className='post-claim'>
                    <div className='row'>
                        <div className='col px-md-5'>
                            <h1>{pageData.copyInfo?.postlaim?.enter_title}</h1>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4 offset-md-4 mt-4 text-center'>
                            <a href='/enter' className='enter-now-button all-caps btn btn-theme' onClick={onNewTicket}>
                                {pageData.copyInfo?.postlaim?.enter_another}
                            </a>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col text-center text-danger my-4 content-footnote'>{pageData.copyInfo?.postlaim?.content_footnote}</div>
                    </div>
                </div>
            </Layout>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PostClaim);

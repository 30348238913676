import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { Link, Redirect } from 'react-router-dom';
import { getCMSObject, TeleScript } from 'sg-ui-components';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from '../ContentBox';
import { StepOne } from './step_one';
import { StepTwo } from './step_two';
import { StepThree } from './step_three';
import { StepFour } from './step_four';
import './register.scss';
import featuredImage from '../../assets/images/ctlottery_stars.png';

const RegistrationStepsTemplate = ({ presetStep = 1, user, actions }) => {
    const initialStepState = {
        step: presetStep,
    };

    const [step, setStep] = useState(initialStepState);

    const initialVerifyFields = {
        firstname: '',
        lastname: '',
        birthdate: '',
        address: {
            state: '',
            address1: '',
            address2: '',
            city: '',
            zip: '',
        },
        phone: '',
        email: '',
        ssn: '',
    };

    const [verifyFields, setVerifyFields] = useState(initialVerifyFields);

    const finalSubmit = () => {};

    return (
        <div className='registration-container'>
            <div className='page-head-title'>Registration</div>
            <div className='progress-bar-container'>
                <RegistrationProgressBar step={step} />
            </div>
            <div className='form-container'>
                <form>
                    <StepOne step={step} setStep={setStep} verifyFields={verifyFields} setVerifyFields={setVerifyFields} />
                    <StepTwo user={user} step={step} setStep={setStep} verifyFields={verifyFields} setVerifyFields={setVerifyFields} />
                    <StepThree step={step} setStep={setStep} verifyFields={verifyFields} setVerifyFields={setVerifyFields} />
                    <StepFour step={step} setStep={setStep} verifyFields={verifyFields} setVerifyFields={setVerifyFields} finalSubmit={finalSubmit} />
                    <StepFive step={step} setStep={setStep} verifyFields={verifyFields} setVerifyFields={setVerifyFields} user={user} />
                </form>
            </div>
        </div>
    );
};

const RegistrationProgressBar = ({ step }) => {
    return (
        <div className='registration-progress-bar'>
            <div className={`step step1 ${step.step === 1 ? 'active' : ''}`}>
                <div className='circle'>1</div>
                <span>Account</span>
            </div>
            <div className={`step step2 ${step.step === 2 ? 'active' : ''}`}>
                <div className='circle'>2</div>
                <span>Identification</span>
            </div>
            <div className={`step step3 ${step.step === 3 ? 'active' : ''}`}>
                <div className='circle'>3</div>
                <span>Contact Info.</span>
            </div>
            <div className={`step step4 ${step.step === 4 ? 'active' : ''}`}>
                <div className='circle'>4</div>
                <span>Verify</span>
            </div>
        </div>
    );
};

const registrationSuccessTelescript = getCMSObject('data.components.teleScripts.registration.jsonBlock');

const StepFive = ({ step, verifyFields, setStep, user }) => {
    if (step.step === 5) {
        return (
            <div className='registration-step step5'>
                <ContentBox>
                    <ContentBoxHead className='registration-step-title'>
                        <TeleScript line={registrationSuccessTelescript?.successTitle}>CONGRATULATIONS </TeleScript>
                    </ContentBoxHead>
                    <ContentBoxBody>
                        <div className='inner-step'>
                            <div className='text-center'>
                                <div className='second-chance-logo text-center'>
                                    <img src={featuredImage} alt='Congratulations' className='img-fluid' />
                                </div>
                                <p className='my-2'>
                                    <TeleScript line={registrationSuccessTelescript?.successMessage}>Congratulations your account has been created.</TeleScript>
                                </p>
                                <div className='d-flex justify-content-center btn-wrapper'>
                                    <a className=' btn theme-btn btn-block theme-green my-4' href='/home'>
                                        Continue to Home
                                    </a>
                                </div>
                            </div>
                        </div>
                    </ContentBoxBody>
                </ContentBox>
            </div>
        );
    } else {
        return null;
    }
};

const RegistrationSteps = connect(mapStateToProps, mapDispatchToProps)(RegistrationStepsTemplate);

export { RegistrationSteps };

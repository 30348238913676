import { createSlice } from '@reduxjs/toolkit';
import apiClient from '../Api/api_client';
import { loadingSlice } from './LoadingStore';
import { userSlice } from './UserStore';

export const defaultAchievementState = {
    lastRequested: null, // possibly use this to trigger reloading?
    requestError: false,
    pageSize: 5,
    page: 0,
    total: 0,
    rewards: [],
};

export const sampleAchievementState = {
    lastRequested: null, // possibly use this to trigger reloading?
    requestError: false,
    pageSize: 5,
    page: 0,
    total: 0,
    rewards: [
        {
            id: 329,
            promotion_id: 440,
            signal_audit_log_id: 440,
            player_id: 440,
            prize_id: 440,
            state: 10000,
            intent_amount: 9000,
            actual_amount: 500,
            prize_name: '$2 Scratcher',
            prize_description: 'A long description of a $2 scratcher',
            value: 5000,
            currency: 'points',
        },
    ],
};

// REDUCERS
// basic actions that affect this object only

const getAchievementsReducer = (state, action) => {
    const { rewards: newRewards, page, total } = action.payload;

    // TODO: There's a bug in the API that causes more results than expected to be returned, truncate it for now
    if (newRewards && newRewards.length > state.pageSize) {
        newRewards.length = state.pageSize;
    }

    // Merge newRewards in existing rewards making sure that they're slotted in the
    // right place (potentially overwriting existing records if the API messed up)
    const rewards = [...state.rewards];
    rewards.length = state.pageSize * (page - 1);
    newRewards && rewards.push(...newRewards);

    return {
        ...state,
        lastRequested: Date.now(),
        rewards,
        // TODO: There's a bug in the API where these values are returned as strings instead of numbers
        page: Number(page),
        total: Number(total),
    };
};

const setRequestErrorReducer = (state, action) => {
    return {
        ...state,
        lastRequested: Date.now(),
        requestError: true,
    };
};

export const achievementSlice = createSlice({
    name: 'achievement',
    initialState: defaultAchievementState,
    reducers: {
        getAchievements: getAchievementsReducer,
        setRequestError: setRequestErrorReducer,
    },
    extraReducers: {
        [userSlice.actions.logout]: (state) => {
            return defaultAchievementState;
        },
        // [entryFormSlice.actions.setEntryResponse]: state => {
        //     return defaultAchievementState;
        // },
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const getAchievements = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'getEntries' }));
    const result = await apiClient().getAchievements();
    dispatch(achievementSlice.actions.getAchievements(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'getEntries' }));
};

//actions that get exported to the views
export const achievementActions = (dispatch) => {
    return {
        getAchievements: (payload) => getAchievements(dispatch, payload),
    };
};

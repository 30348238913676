import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import { MarkdownArea, TicketForm } from 'ui-kit';

import DrawForm from '../Components/Enter/DrawFormTemplate';
import ScratchForm from '../Components/Enter/ScratchFormTemplate';

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

const pageStyle = {};

// import SEO from '../components/seo';\

const EnterPageTemplate = ({ user, config, actions, contentData, layoutData, pageData }) => {
    const [body, setBody] = useState(null);

    const getContentPage = async (pageName) => {
        const res = await fetch(`/${pageName}.md`, {});
        if (!res.ok) {
            const message = `An error has occured: ${res.status}`;
            throw new Error(message);
        }
        const response = await res.text();
        // Set Pages
        const pagesResult = response;
        setBody(pagesResult);
    };

    useEffect(() => {
        getContentPage(contentData);
    }, [contentData]);

    return (
        <Layout user={user} config={config} actions={actions} mainMenu={layoutData} pageData={pageData}>
            <div css={css(pageStyle)}>{body ? <MarkdownArea components={{ DrawForm, ScratchForm }}>{body}</MarkdownArea> : ''}</div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterPageTemplate);

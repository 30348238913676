import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import {
    ContentBox,
    ContentBoxHead,
    ContentBoxBody,
    ContentCollapser,
} from '../ContentBox';

import UserLockout from '../../utils/user_lockout';

const PasswordExpiring = ({
    user,
    actions,
}) => {

    return (
        <UserLockout>
            <div className='login-container'>
                <div className="my-5"/>
                <ContentBox variant="theme-purple">
                    <ContentBoxHead>
                        UPDATE PASSWORD
                    </ContentBoxHead>
                    <ContentBoxBody>
                        <div className='text-center'>
                            <p>Your password is going to expire in { user.expiration_days } days. Please update it in your profile.</p>
                        </div>
                        <a className='btn theme-btn btn-block theme-gray' href='/my-profile'>Update Now</a>
                        <a className='btn theme-btn btn-block' href='/home'>Remind me Later</a>
                    </ContentBoxBody>
                </ContentBox>
            </div>
        </UserLockout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordExpiring);
import React from 'react';

import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

const LoginPageTemplate = ({ user, actions, children }) => {
    if (user.loggedIn) {
        //? For backup: old way of doing the redirect
        // window.location.href = '/enter';
        // return null;

        actions.userActions.getPlayerData();

        //* Registration steps are not finished
        if (user.actions.includes('update-account')) {
            return <Redirect to='/register' />;
        }

        //* Social Security Number verification is not finished
        if (user.actions.includes('verify-account')) {
            return <Redirect to='/register' />;
        }

        //* User email is not confirmed yet
        if (user.actions.includes('account-confirm')) {
            return <Redirect to='/account-confirm' />;
        }

        //* Password expiring
        if (user.expiration_days < 20) {
            return <Redirect to='/password-expiring' />;
        }

        //* Password expired
        if (user.actions.includes('change-password')) {
            return <Redirect to='/password-expired' />;
        }

        return <Redirect to='/home' />;
    }

    return <>{children}</>;
};

const LoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPageTemplate);

export { LoginPage };

import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import { MarkdownArea } from 'ui-kit';
import { Img } from './Img';

const IndexedDataContext = React.createContext({});

const IndexedDataContent = ({ module = 'webContent', digest, cmsSourceFirebase, error, data }) => {
    const [body, setBody] = useState('');

    const resolveDataNode = (dataToRetrieve, dataObject) => {
        return dataToRetrieve.split('.').reduce(function (obj, objKey) {
            return obj && obj[objKey];
        }, dataObject);
    };

    useEffect(() => {
        if (cmsSourceFirebase.ready) {
            const contentObject = JSON.parse(window.localStorage.getItem(module) ?? '{}') ?? {};
            const contentNode = JSON.parse(resolveDataNode(digest, contentObject) ?? '{}') ?? {};
            setBody(contentNode[error] ?? error);
        }
    }, [digest, error, cmsSourceFirebase.ready]);

    return (
        <div>
            {body ? (
                <IndexedDataContext.Provider value={data}>
                    <MarkdownArea
                        components={{
                            Img,
                            IndexedData,
                            Remaining,
                            Attempts,
                        }}>
                        {body}
                    </MarkdownArea>
                </IndexedDataContext.Provider>
            ) : (
                ''
            )}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexedDataContent);

const IndexedData = ({ val }) => {
    const data = useContext(IndexedDataContext);
    if (data && data[val] != null) {
        return data[val];
    } else {
        return null;
    }
};

const Remaining = ({ val, total }) => {
    const data = useContext(IndexedDataContext);
    if (total && data && data[val]) {
        return total - data[val];
    } else {
        return null;
    }
};

const Attempts = ({ val, total }) => {
    const data = useContext(IndexedDataContext);
    const left = total && data && data[val] ? total - data[val] : total;
    return parseInt(left) === 1 ? 'attempt' : 'attempts';
};

import React from 'react';

export default class Application extends React.Component {
    componentDidMount() {
        if (typeof window.ga === 'function') {
            window.ga('set', 'page', window.location.pathname + window.location.search);
            window.ga('send', 'pageview');
        }
    }

    componentDidUpdate() {
        if (typeof window.ga === 'function') {
            window.ga('set', 'page', window.location.pathname + window.location.search);
            window.ga('send', 'pageview');
        }
    }

    render() {
        return this.props.children;
    }
}

import React from 'react';
import FeedbackSubHeader from '../Components/Layouts/Feedback/FeedbackSubHeader';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import { BoxedContent, hasCMSContent, CMSContent, FeedbackForm, getCMSObject } from 'sg-ui-components';
const feedbackPage = ({ user, cmsSourceFirebase, actions, feedbackCategories, promotion, loading, feedback }) => {
    //? Note: Firebase CMS webContent and webMenu will be requested and stored on first pageload / refresh
    const feedbackFormTelescript = getCMSObject('data.components.teleScripts.feedback.jsonBlock');

    return (
        <div class='feedback-container'>
            <FeedbackSubHeader />
            <BoxedContent
                note='FAQ section'
                hash='faq'
                isActive={false}
                isVisible={hasCMSContent('data.helpAndFaqs.faqs.contentHeaderText')}
                title={
                    <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.faqs.contentHeaderText' cmsSourceFirebase={cmsSourceFirebase} />
                }>
                <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.faqs.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
            </BoxedContent>

            <BoxedContent
                note='How To Claim section'
                hash='claim'
                isActive={false}
                isVisible={hasCMSContent('data.helpAndFaqs.howToClaim.contentHeaderText')}
                title={
                    <CMSContent
                        localStorageObject='webContent'
                        contentPath='data.helpAndFaqs.howToClaim.contentHeaderText'
                        cmsSourceFirebase={cmsSourceFirebase}
                    />
                }>
                <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.howToClaim.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
            </BoxedContent>

            <FeedbackForm
                user={user}
                actions={actions}
                feedbackCategories={feedbackCategories}
                promotion={promotion}
                loading={loading}
                cmsSourceFirebase={cmsSourceFirebase}
                feedback={feedback}
                telescript={feedbackFormTelescript}
            />

            <BoxedContent
                note='2nd Chance Official Rules'
                hash='rules'
                isActive={false}
                isVisible={hasCMSContent('data.helpAndFaqs.rules.contentHeaderText')}
                title={
                    <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.rules.contentHeaderText' cmsSourceFirebase={cmsSourceFirebase} />
                }>
                <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.rules.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
            </BoxedContent>
            <BoxedContent
                note='Terms Of Use'
                hash='terms'
                isActive={false}
                isVisible={hasCMSContent('data.legalAndPolicyItems.termsOfUse.contentHeaderText')}
                title={
                    <CMSContent
                        localStorageObject='webContent'
                        contentPath='data.legalAndPolicyItems.termsOfUse.contentHeaderText'
                        cmsSourceFirebase={cmsSourceFirebase}
                    />
                }>
                <CMSContent
                    localStorageObject='webContent'
                    contentPath='data.legalAndPolicyItems.termsOfUse.contentHTML'
                    cmsSourceFirebase={cmsSourceFirebase}
                />
            </BoxedContent>
            <BoxedContent
                note='Privacy Policy'
                hash='privacy'
                isActive={false}
                isVisible={hasCMSContent('data.legalAndPolicyItems.privacyPolicy.contentHeaderText')}
                title={
                    <CMSContent
                        localStorageObject='webContent'
                        contentPath='data.legalAndPolicyItems.privacyPolicy.contentHeaderText'
                        cmsSourceFirebase={cmsSourceFirebase}
                    />
                }>
                <CMSContent
                    localStorageObject='webContent'
                    contentPath='data.legalAndPolicyItems.privacyPolicy.contentHTML'
                    cmsSourceFirebase={cmsSourceFirebase}
                />
            </BoxedContent>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(feedbackPage);

import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import { ContentBox, ContentBoxBody, ContentBoxHead, ContentCollapser } from '../Components/ContentBox';
import UserLockout from '../utils/user_lockout';
import CustomerContent from '../Components/CustomerContent';
import { BoxedContent, hasCMSContent, CMSContent } from 'sg-ui-components';
import ContentSection from '../Components/ContentSection';

const EnterPage = ({ cmsSourceFirebase }) => {
    React.useEffect(() => {
        // Only scroll to top if there's no hash in the URL
        if (!window.location.hash) {
            window.scrollTo(0, 0);
        }
    }, []);
    //? Note: Firebase CMS webContent and webMenu will be requested and stored on first pageload / refresh
    return (
        <div>
            <UserLockout>
                <div class='enter-container'>
                    <div class='row justify-content-center'>
                        <div class='entry-box col-md-6'>
                            <ContentSection content='ensemble_site_enter_module' />
                        </div>
                        <div class='entry-box col-md-6'>
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.ticketEntry.ticketEntryContent.contentHTML'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        </div>
                    </div>
                    <div class='row justify-content-center'>
                        <div class='col-12'>
                            <ContentBox variant='theme-green' id='enter-help'>
                                <ContentBoxHead>
                                    WHERE ARE MY NUMBERS?
                                    <ContentCollapser />
                                </ContentBoxHead>
                                <ContentBoxBody>
                                    <div class='row'>
                                        <div class='col-md-6'>
                                            <CMSContent
                                                localStorageObject='webContent'
                                                contentPath='data.ticketEntry.ticketEntryTickets.ticketEntryFront.contentHTML'
                                                cmsSourceFirebase={cmsSourceFirebase}
                                            />
                                            <CMSContent
                                                localStorageObject='webContent'
                                                contentPath='data.ticketEntry.ticketEntryTickets.ticketEntryFront.image'
                                                className='img-fluid mx-auto d-block'
                                                cmsSourceFirebase={cmsSourceFirebase}
                                            />
                                        </div>
                                        <div class='col-md-6'>
                                            <CMSContent
                                                localStorageObject='webContent'
                                                contentPath='data.ticketEntry.ticketEntryTickets.ticketEntryBack.contentHTML'
                                                cmsSourceFirebase={cmsSourceFirebase}
                                            />
                                            <CMSContent
                                                localStorageObject='webContent'
                                                contentPath='data.ticketEntry.ticketEntryTickets.ticketEntryBack.image'
                                                className='img-fluid mx-auto d-block'
                                                cmsSourceFirebase={cmsSourceFirebase}
                                            />
                                        </div>
                                    </div>
                                </ContentBoxBody>
                            </ContentBox>
                        </div>
                    </div>
                    <div class='row justify-content-center'>
                        <div class='col-12'>
                            <ContentBox show={false} variant='theme-green'>
                                <ContentBoxHead>
                                    FAQ
                                    <ContentCollapser />
                                </ContentBoxHead>
                                <ContentBoxBody>
                                    <div class='row'>
                                        <div class='col-12'>
                                            <CMSContent
                                                localStorageObject='webContent'
                                                contentPath='data.ticketEntry.ticketEntryFAQ.contentHTML'
                                                cmsSourceFirebase={cmsSourceFirebase}
                                            />
                                        </div>
                                    </div>
                                </ContentBoxBody>
                            </ContentBox>
                        </div>
                    </div>
                </div>
            </UserLockout>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterPage);

import React, { useState, useEffect } from 'react';
import Winners from './WinnerLink';

const DrawingGroup = ({ drawingGroup, index, selection, usedCopy }) => {
    const initialState = selection;

    const [selected, setSelected] = useState(initialState);

    const toggleSelected = () => {
        setSelected(!selected);
    };

    useEffect(() => {
        setSelected(selection || drawingGroup.drawings.length === 1);
    }, [drawingGroup.drawings, selection]);

    const handleKeyDown = (ev) => {
        if (ev.keyCode == 13) {
            this.toggleSelected();
        }
    };

    const titles = (index, usedCopy) => {
        if (index === 0) {
            return (
                <div className="row titles">
                    <div className="col-2"></div>
                    <div className="col-3">{usedCopy.sweeps.column.prizes}</div>
                    <div className="col-3">
                        {usedCopy.sweeps.column.entryperiod}
                    </div>
                    <div className="col-2">
                        {usedCopy.sweeps.column.drawingdate}
                    </div>
                    <div className="col-2">
                        {usedCopy.sweeps.column.numberofentries}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };
    const showHide = (drawingCount, usedCopy) => {
        if (drawingCount > 1) {
            return (
                <div
                    className={'toggle-drawings '}
                    onClick={toggleSelected}
                    onKeyDown={handleKeyDown}
                    role="button"
                    tabIndex="0"
                >
                    {!selected
                        ? usedCopy.sweeps.showdrawings
                        : usedCopy.sweeps.hidedrawings}
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <div className="">
            {titles(index, usedCopy)}
            <div className="row group-listing">
                <div className="col-2 group-name">
                    {drawingGroup.name}
                    {showHide(drawingGroup.drawings.length, usedCopy)}
                </div>
                <div className="col-3">
                    {drawingGroup.prizes.map((prize) => {
                        return (
                            <div key={prize.name} className="prize">
                                {prize.name}
                            </div>
                        );
                    })}
                </div>
                <div className="col-3">
                    {new Date(drawingGroup.quantum_start * 1000).toDateString()}
                    {' - '}
                    {new Date(drawingGroup.quantum_end * 1000).toDateString()}
                </div>
                <div className="col-2"></div>
                <div className="col-2"></div>
            </div>
            <div
                className={'container drawings ' + (!selected ? 'd-none' : '')}
            >
                {drawingGroup.drawings.map((drawing) => {
                    return <Drawing key={drawing.id} drawing={drawing} />;
                })}
            </div>
        </div>
    );
};

const showDrawingDate = (drawing) => {
    if (drawing.certified) {
        // return new Date(drawing.draw_date * 1000).toDateString();
        return <Winners selected={drawing} />;
    } else {
        return new Date(drawing.draw_date * 1000).toDateString();
    }
};

const Drawing = ({ drawing }) => {
    return (
        <div className="row drawing-row">
            <div className="col-2 empty-spot"></div>
            <div className="col-2">{'Drawing ' + drawing.id}</div>
            <div className="col-1">&nbsp;</div>
            <div className="col-3">
                {new Date(drawing.quantum_start * 1000).toDateString()}
                {' - '}
                {new Date(drawing.quantum_end * 1000).toDateString()}
            </div>
            <div className="col-2">{showDrawingDate(drawing)}</div>
            <div className="col-2">
                {drawing.drawing_summaries && drawing.drawing_summaries.length
                    ? drawing.drawing_summaries.map((summary) => {
                          return summary.entry_count ? summary.entry_count : 0;
                      })
                    : 0}
            </div>
        </div>
    );
};

export default DrawingGroup;

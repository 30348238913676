import React from 'react';
import { DataProvider, useData } from '../DataProviders/DataContext';

import './home_drawing_card.scss';
import { CurrentDrawingData } from '../DataProviders/CurrentDrawingData';
import { FormatNextDrawings } from './FormatNextDrawings';
import IfLoggedIn from '../IfLoggedIn';
import IfLoggedOut from '../IfLoggedOut';

// EXAMPLE OF USE IN CMS
/*
<HomeDrawingCard />
*/

const HomeDrawingCard = () => {
    return (
        <DataProvider>
            <CurrentDrawingData />
            <FormatNextDrawings />
            <div className="drawingCard">
                <Card />
            </div>
        </DataProvider>
    );
};

const Card = () => {
    const { state, dispatch } = useData();

    const nextDrawing = state.data
        .filter((val) => {
            return val.draw_date != null;
        })
        .sort((a, b) => {
            return b.draw_date - a.draw_date;
        })[0];

    if (nextDrawing) {
        return (
            <div>
                <div className="next-drawing-card">
                    <h3>{nextDrawing.sweepstake}</h3>
                    <div>{nextDrawing.drawing_date}</div>
                    <div className="more-chances">
                        <IfLoggedIn>
                            <p>
                                Are you a winner?
                                <br />
                                Follow the link below to find out.
                            </p>
                            <a className="btn theme-btn btn-block" href="winners">
                                View all Drawings & Winners
                            </a>
                        </IfLoggedIn>
                        <IfLoggedOut>
                            <a className="are-you-entered" href="login">
                                <p>
                                    Are you entered?
                                    <br />
                                    Login to find out. Good luck!
                                </p>
                            </a>
                        </IfLoggedOut>
                    </div>
                </div>
            </div>
        );
    } else return <></>;
};

export { HomeDrawingCard };

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { Button, Toast } from 'react-bootstrap';
import IndexedDataContent from '../IndexedDataContent';
import './style.scss';

export const ExpirationBanner = ({ notify = 20, warn = 10, user, actions }) => {
    const [expirationDays, setExpirationDays] = useState(user.expiration_days);
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setCookieValueByName('expiration', 'true');
        setShow(false);
    };

    useEffect(() => {
        setExpirationDays(user.expiration_days);
    }, [user.expiration_days]);

    const showToast = () => {
        return (
            <div className="p-3" position="top-center" hidden={!show}>
                <Toast className="" onClose={handleClose} show={show}>
                    <Toast.Header>
                        <IndexedDataContent
                            digest="data.messages.errorMessages.jsonBlock"
                            error="expiration_days"
                            data={{ expiration_days: expirationDays }}
                        />
                    </Toast.Header>
                </Toast>
            </div>
        );
    };

    const warnBox = () => {
        return (
            <div className="col-md-8 p-4 mx-auto">
            <div className="content-box bg-white">
              <div className="content-box-head">
                 UPDATE PASSWORD
               </div>
               <div className="content-box-body text-center">
                 <div className="center-block">
                    <IndexedDataContent
                            digest="data.messages.errorMessages.jsonBlock"
                            error="password_expires_today"
                        />
                    </div>
                 <Button className="my-profile-button" href="/my-profile">My Profile</Button>
               </div>
           </div>
         </div>
        );
    };

    const isSilenced = getCookieByName('expiration');

    if (user.loggedIn) {
        if (expirationDays == 0) {
            return warnBox();
        } else if (expirationDays < notify && isSilenced != 'true') {
            return showToast();
        } else if (expirationDays < warn) {
            return showToast();
        } else {
            return null;
        }
    } else {
        return null;
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpirationBanner);

const getCookieByName = (cookieName) => {
    if (typeof document === 'undefined') {
        return;
    }

    const allCookies = document.cookie.split('; ');
    const cookies = allCookies.reduce((cookieObj, newCookie) => {
        var index = newCookie.indexOf('=');
        cookieObj[newCookie.substring(0, index)] = newCookie.substring(
            index + 1
        );

        return cookieObj;
    }, {});

    return cookies[cookieName];
};

const getTopLevelDomain = (domain) => {
    // this should return only the
    // extension and the top level of the domain
    // ex: futurewalottery.com
    // from stg01.mylottery360.futurewalottery.com
    return domain.split('.').slice(-2).join('.');
};

const setCookieValueByName = (cookieName, cookieValue) => {
    if (typeof document === 'undefined') {
        return;
    }

    document.cookie = `${cookieName}=${cookieValue}; Path=/; Domain=${getTopLevelDomain(
        document.location.hostname
    )}`;
    return cookieValue;
};

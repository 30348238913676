import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import promotion from '../promotion_config';
import isBrowser from './isBrowser';

const UserLockout = ({ children, loading, user, config, currentSection, disableRedirect, actions }) => {
    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, [loading, hasMounted]);

    let myLocation = isBrowser() ? window.location.protocol + '//' + window.location.host + window.location.pathname : '';

    // remove any trailing slashes
    myLocation = myLocation.replace(/\/$/, '');

    const defaultLoginUrl = promotion.defaultLoginLink;

    //? for debug: console.log('🔥 Loading actions', loading.actions, hasMounted);

    //! TODO: need to DEBUG sometimes getPlayerData never returns
    if (!hasMounted || loading.actions['getPlayerData'] || loading.actions['getConfig'] || loading.actions['login']) {
        return (
            <div className='loading-wrapper'>
                {/*
                    //! Removed because the styling issues
                    children
                */}
                <div className='loading-overlay'>
                    <div className='loading-icon ellipses'></div>
                </div>
            </div>
        );
    } else {
        if (user.loggedIn) {
            //* Registration steps are not finished
            if (user.actions.includes('update-account') && disableRedirect !== 'updated-account') {
                return <Redirect to='/register' />;
            }

            //* Social Security Number verification is not finished
            if (user.actions.includes('verify-account')) {
                return <Redirect to='/register' />;
            }

            //* User email is not confirmed yet
            if (user.actions.includes('account-confirm') && disableRedirect !== 'account-confirm') {
                return <Redirect to='/account-confirm' />;
            }

            //* Password expired
            if (
                user.actions.includes('change-password') &&
                !user.actions.includes('account-confirm') &&
                disableRedirect !== 'change-password' &&
                currentSection !== 'my-profile'
            ) {
                return <Redirect to='/password-expired' />;
            }

            return children;
        } else {
            const loginUrl = config.config?.login_host_uri ? `${config.config.login_host_uri}?PtaUrl=${myLocation}&Redirect=${myLocation}` : defaultLoginUrl;
            window.location.href = loginUrl;
            return null;
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLockout);

import { createSlice } from '@reduxjs/toolkit';
import apiClient from '../Api/api_client';
import { loadingSlice } from './LoadingStore';
import { userSlice } from './UserStore';

export const defaultPointTotalsState = {
    lastRequested: null, // possibly use this to trigger reloading?
    requestError: false,
    totals: [],
};

export const samplePointsTotalState = {
    ...defaultPointTotalsState,
    totals: [
        {
            name: 'winnings',
            account_type: 'unrestricted',
            last_sync_time: '2017-12-27 09:03:39',
            currency: 'cash',
            balance: 5000,
            held_funds: 2000,
            limit: {},
        },
    ],
};

// REDUCERS
// basic actions that affect this object only

const getPointsReducer = (state, action) => {
    return {
        ...state,
        lastRequested: Date.now(),
        totals: action.payload,
    };
};

const setRequestErrorReducer = (state, action) => {
    return {
        ...state,
        lastRequested: Date.now(),
        requestError: true,
    };
};

export const pointSlice = createSlice({
    name: 'point',
    initialState: defaultPointTotalsState,
    reducers: {
        getPoints: getPointsReducer,
        setRequestError: setRequestErrorReducer,
    },
    extraReducers: {
        [userSlice.actions.logout]: (state) => {
            return defaultPointTotalsState;
        },
        // [entryFormSlice.actions.setEntryResponse]: state => {
        //     return defaultAchievementState;
        // },
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const getPoints = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'getEntries' }));
    const result = await apiClient().getPoints();
    console.log(result);
    dispatch(pointSlice.actions.getPoints(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'getEntries' }));
};

//actions that get exported to the views
export const pointActions = (dispatch) => {
    return {
        getPoints: (payload) => getPoints(dispatch, payload),
    };
};

import React from 'react';
import signals from './signals';
import promotion from '../../promotion_config';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from 'react-bootstrap';
import CouponDisplay from './Coupon';
import UserLockout from '../../utils/user_lockout';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import { formatDateTimeWithSeconds as formatDateTime } from '../../utils/formatDates';

class RewardHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            pagesize: 5,
            currency: null,
            currencySelectValue: 'all',
            isLoaded: false,

            // These state values are put on later for filtering,
            // but can also be set here:
            // startdate: null,
            // enddate: null,
            // currency: null,
            // signal: null,
        };

        this.getMoreRewards = this.getMoreRewards.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        this.getMoreRewards();
    }

    handleKeyDown(ev) {
        // user presses enter
        if (ev.keyCode == 13) {
            this.getMoreRewards();
        }
    }

    async getMoreRewards() {
        await this.setState({
            page: this.state.page + 1,
            pagesize: this.state.pagesize,
            isLoaded: false,
        });

        await this.props.actions.rewardActions.getRewards(this.state);
        this.setState({ isLoaded: true });
    }

    async handleStartDateChange(date) {
        if (date) {
            await this.setState({
                startdate: date.getTime() / 1000,
                page: 1,
                isLoaded: false,
            });
        } else {
            await this.setState({
                startdate: null,
            });
        }

        await this.props.actions.rewardActions.clearRewards();
        await this.props.actions.rewardActions.getRewards(this.state);
        this.setState({ isLoaded: true });
    }

    async handleEndDateChange(date) {
        if (date) {
            await this.setState({
                enddate: date.getTime() / 1000,
                page: 1,
                isLoaded: false,
            });
        } else {
            await this.setState({
                enddate: null,
            });
        }

        await this.props.actions.rewardActions.clearRewards();
        await this.props.actions.rewardActions.getRewards(this.state);
        this.setState({ isLoaded: true });
    }

    async handleCurrencyChange(e) {
        let newCurrency = e.target.value === 'all' ? null : e.target.value;

        if (this.state.currency !== newCurrency) {
            await this.setState({
                currency: newCurrency,
                page: 1,
                currencySelectValue: e.target.value,
                isLoaded: false,
            });

            await this.props.actions.rewardActions.clearRewards();
            await this.props.actions.rewardActions.getRewards(this.state);
            this.setState({ isLoaded: true });
        }
    }

    render() {
        const { rewards, total } = this.props.reward;

        //? Format ticket number
        // from: 99920000011852046275230
        // to: 9992-000001-185-204627-5230
        const formatTicketNumber = (ticket) => {

            const ticket1_1_1 = ticket.slice(0, 4);
            const ticket1_1_2 = ticket.slice(4, 4+6);
            const ticket1_1_3 = ticket.slice(4+6, 4+6+3);
            const ticket1_1_4 = ticket.slice(4+6+3, 4+6+3+6);
            const ticket1_1_5 = ticket.slice(4+6+3+6);

            return [ticket1_1_1, ticket1_1_2, ticket1_1_3, ticket1_1_4, ticket1_1_5].join('-');
        };

        // if there are more rewards, show the link for more
        const loadMoreLink =
            rewards.length > 0 &&
            rewards.length < total ? (
                <div className='text-center'>
                    <Button
                        variant='outline-secondary'
                        className='btn theme-btn theme-outline'
                        onClick={this.getMoreRewards}
                    >
                        View More
                    </Button>
                </div>
            ) : null;

        const desktopDrawEntries = (rewards) => {
            if (!rewards || rewards.length === 0) {
                return (
                    <tbody>
                        <tr key="0">
                            <td colSpan="4">
                                <em>
                                    {this.state.isLoaded
                                        ? 'No entries submitted.'
                                        : '. . .'}
                                </em>
                            </td>
                        </tr>
                    </tbody>
                );
            } else {
                // note we are spreading entries into a new array so we don't mutate the
                // entries array in state
                return (
                    <tbody>
                        {[...rewards].map(
                            ({
                                reward_id,
                                prize_name,
                                prize_description,
                                promotion_id,
                                promotion_name,
                                created_at,
                                actual_amount,
                                currency,
                                entry_data,
                            }) => {
                                return (
                                    <tr key={reward_id}>
                                        <td>
                                            <div className="data">
                                                {formatTicketNumber(entry_data)}
                                            </div>
                                        </td>
                                        <td><a href={`/promotions`}>{prize_description}</a></td>
                                        <td>{formatDateTime(created_at)}</td>
                                        <td>
                                            <div key={`${created_at}${actual_amount}`}>
                                                { promotion.resolveCurrency(currency).showQuantity && (
                                                    <div className="amount">
                                                        {actual_amount}
                                                    </div>
                                                )}
                                                <div className="prize">
                                                    {prize_name}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                );
            }
        };

        const desktopEntrylist = (rewards) => {
            return (
                <table
                    id="entryTable"
                    className="table entry-table d-none d-md-table text-center"
                >
                    <thead>
                        <tr>
                            <th>TICKET NUMBER</th>
                            <th>PROMOTION</th>
                            <th>ENTRY DATE</th>
                            <th>AWARD</th>
                        </tr>
                    </thead>
                    {desktopDrawEntries(rewards)}
                </table>
            );
        };

        const mobileEntrylist = (rewards) => {
            if (!rewards || rewards.length === 0) {
                return (
                    <div className="d-md-none stacked-rewards-container">
                        <div className="row">
                            <div className="col">Rewards</div>
                            <div className="col">
                                <em>
                                    {this.state.isLoaded
                                        ? 'No rewards.'
                                        : '. . .'}
                                </em>
                            </div>
                        </div>
                    </div>
                );
            } else {
                // note we are spreading entries into a new array so we don't mutate the
                // entries array in state
                return (
                    <div className="d-md-none stacked-rewards-container">
                        {[...rewards].map(
                            ({
                                reward_id,
                                prize_name,
                                prize_description,
                                promotion_id,
                                promotion_name,
                                created_at,
                                actual_amount,
                                currency,
                                entry_data,
                            }) => {
                                return (
                                    <div className="row" key={reward_id}>
                                        <div className="col">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                Ticket Number
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            <div className="data">
                                                                {formatTicketNumber(entry_data)}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                Promotion
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            <a href={`/promotions`}>{prize_description}</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                Entry Date
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            {formatDateTime(created_at)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>
                                                                Award
                                                            </strong>
                                                        </td>
                                                        <td>
                                                            <div key={`${created_at}${actual_amount}`}>
                                                                { promotion.resolveCurrency(currency).showQuantity && (
                                                                    <div className="amount">
                                                                        {actual_amount}
                                                                    </div>
                                                                )}
                                                                <div className="prize">
                                                                    {prize_name}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                );
                            }
                        )}
                    </div>
                );
            }
        };

        //? for debug: console.log('rewards:', rewards);

        return (
            <UserLockout>
                <div className="row">
                    <div className="col">
                        {desktopEntrylist(rewards)}
                        {mobileEntrylist(rewards)}
                        {loadMoreLink}
                    </div>
                </div>
            </UserLockout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardHistory);
import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';

//! Info: Sandbox helps you to test your new components without touching the CMS

export class Sandbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <div></div>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sandbox);

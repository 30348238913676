import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import { MyAllocatableEntries, getCMSObject, MyRewardsHistory, ErrorBoundary, TeleScript } from 'sg-ui-components';
import MyDrawingEntries from './MyActivity/MyDrawingEntries';
import siteConfig from '../promotion_config';
import upArrow from '../assets/images/arrow_up_solid.svg';
import downArrow from '../assets/images/arrow_down_solid.svg';

import UserLockout from '../utils/user_lockout';
import AllocatableEntriesForm from './AllocatableEntries/AllocatableEntriesForm';

const MyActivity = ({ actions, sweep, loading, reward, user, config }) => {
    const myAllocatableTelescript = getCMSObject('data.components.teleScripts.myAllocatableEntries.jsonBlock');
    const myRewardsHistoryTelescript = getCMSObject('data.components.teleScripts.myTicketHistory.jsonBlock');
    const myDrawingEntriesTelescript = getCMSObject('data.components.teleScripts.myDrawingEntries.jsonBlock');

    return (
        <UserLockout>
            <div className='my-5 ' />
            <ErrorBoundary fallback={<TeleScript line={myAllocatableTelescript?.fallback} />} details={{ user, config, reward }}>
                <MyAllocatableEntries
                    options={{
                        variant: 'theme-blue',
                        hasCollapser: true,
                        showByDefault: true,
                        upArrow: <img src={upArrow} className='svg-icon' />,
                        downArrow: <img src={downArrow} className='svg-icon' />,
                    }}
                    actions={actions}
                    sweep={sweep}
                    loading={loading}
                    AllocatableForm={(entry) => (
                        <AllocatableEntriesForm
                            sweepId={entry.sweeps_id ?? ''}
                            promotionName={entry.sweepstake_name ?? ''}
                            numEntries={entry.quantity ?? ''}
                            entryId={entry.id ?? ''}
                        />
                    )}
                    telescript={myAllocatableTelescript}
                />
            </ErrorBoundary>
            <div className='my-4 drawing-entries-containers'>
                <ErrorBoundary fallback={<TeleScript line={myDrawingEntriesTelescript?.fallback} />} details={{ user, config, reward }}>
                    <MyDrawingEntries />
                </ErrorBoundary>
            </div>
            <div className='my-4' />
            <ErrorBoundary fallback={<TeleScript line={myRewardsHistoryTelescript?.fallback} />} details={{ user, config, reward }}>
                <MyRewardsHistory
                    reward={reward}
                    actions={actions}
                    telescript={myRewardsHistoryTelescript}
                    options={{
                        variant: 'theme-blue',
                        hasCollapser: true,
                        hash: 'ticket-history',
                        pageSize: siteConfig?.components?.rewardsHistory?.defaultPageSize ?? 5,
                        promotionsLink: '/promotions',
                        upArrow: <img src={upArrow} className='svg-icon' />,
                        downArrow: <img src={downArrow} className='svg-icon' />,
                    }}
                    resolveCurrency={siteConfig.resolveCurrency}
                />
            </ErrorBoundary>
        </UserLockout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyActivity);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { TicketForm } from 'ui-kit';
import TicketError from '../Error';
import UserLockout from '../../utils/user_lockout';
import { CheckTicketResponse } from '../Postclaim/EntryResponseMatrix';

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

const DrawFormTemplate = ({
    drawTicket,
    actions,
    group1Label = '',
    group2Label = '',
    submitName = 'Submit',
}) => {
    return (
        <UserLockout>
            <TicketForm
                ticket={drawTicket}
                createfunc={actions.drawTicketActions.enterDrawTicket}
                successfunc={() => {
                    actions.drawTicketActions.reset();
                    window.open('post_claim');
                }}
                fields={[
                    {
                        name: 'ticket1_1_1',
                        initialState: '',
                        size: '6',
                        groupNumber: 1,
                        placeholder: '',
                    },
                    {
                        name: 'ticket1_1_2',
                        initialState: '',
                        size: '3',
                        groupNumber: 1,
                        placeholder: '',
                    },
                    {
                        name: 'ticket1_1_3',
                        initialState: '',
                        size: '3',
                        groupNumber: 1,
                    },
                    {
                        name: 'ticket1_1_4',
                        initialState: '',
                        size: '10',
                        groupNumber: 2,
                    },
                ]}
                config={{
                    submit: {
                        name: submitName,
                    },
                    groups: [
                        {
                            over: () => {
                                return <div>{group1Label}</div>;
                            },
                            under: () => {
                                return <div></div>;
                            },
                        },
                        {
                            over: () => {
                                return <div>{group2Label}</div>;
                            },
                            under: () => {
                                return <div></div>;
                            },
                        },
                    ],
                }}
            />
        </UserLockout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawFormTemplate);

const DrawErrorTemplate = ({ drawTicket, actions }) => (
    <TicketError ticket={drawTicket} actions={actions} />
);
const DrawError = connect(
    mapStateToProps,
    mapDispatchToProps
)(DrawErrorTemplate);

export { DrawError };


//* In CT we need to override some success ticket entry status to error
const calculateColorFromStatus = (ticketDetails) => {
    const status = (ticketDetails && ticketDetails.TicketStatus) ? ticketDetails.TicketStatus.toUpperCase() : '';
    const success = ['NOT_A_WINNER'];

    if (success.includes(status)) {
        return 'alert-success';
    }

    return 'alert-danger';
}


const DrawSuccessTemplate = ({ drawTicket, actions }) => {

    return (
        ( drawTicket.success && drawTicket.entry && drawTicket.entry.TicketDetails && 'TicketDetails' in drawTicket.entry ) ?
        <div className={`col ${ calculateColorFromStatus(drawTicket.entry.TicketDetails) } alert text-center entry-alert`}>
            <CheckTicketResponse
                ticketCheckInfo={drawTicket.entry.TicketDetails}
            />
        </div> :
        null
    )
}
const DrawSuccess = connect(
    mapStateToProps,
    mapDispatchToProps
)(DrawSuccessTemplate);

export { DrawSuccess };

import React, { useEffect, useState } from 'react';

const Img = ({ src, alt, className, id = '' }) => {
    const [res, setRes] = useState(window.localStorage.getItem(src));

    useEffect(() => {
        let db = window.localStorage;
        setRes(db.getItem(src));
    }, [src]);
    return <img src={'' + res} alt={alt} className={className} id={id} />;
};

export { Img };

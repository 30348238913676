import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from '../ContentBox';


export class ResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <h1>Reset Password</h1>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

import React, { useState, useEffect } from 'react';
import eye from './eye-solid.svg';
import eyeSlash from './eye-slash-solid.svg';

export const PasswordField = ({ name, label, value, newPassword = false, className = 'form-control', maxLength, handleChange, handleInput }) => {
    const [type, setType] = useState('password');

    const handleClick = () => setType(type === 'text' ? 'password' : 'text');

    return (
        <div className='password-field'>
            <input
                type={type}
                className={className}
                id={name}
                value={value}
                name={name}
                label={label}
                aria-label={label}
                onChange={handleChange}
                onInput={handleInput}
                maxLength={maxLength}
                aria-describedby={`${name}Help`}
                placeholder=''
                autoComplete={newPassword ? 'new-password' : ''}
            />
            <span className='password-show' onClick={handleClick}>
                {type === 'text' ? (
                    <span className='oi oi-eye'>
                        <img alt="hide password" src={eyeSlash} />
                    </span>
                ) : (
                    <span className='oi oi-eye'>
                        <img alt="show password" src={eye} />
                    </span>
                )}
            </span>
        </div>
    );
};

import React, { useState, useEffect } from 'react';
import Cleave from 'cleave.js/react';
import * as yup from 'yup';
import states from './states.json';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from '../ContentBox';

const StepThreeTemplate = ({ step, setStep, verifyFields, setVerifyFields, user, actions }) => {
    const initialStepFields = {
        state: user.player.address && user.player.address.state !== null ? user.player.address.state : '',
        address1: user.player.address && user.player.address.address1 !== null ? user.player.address.address1 : '',
        address2: user.player.address && user.player.address.address2 !== null ? user.player.address.address2 : '',
        city: user.player.address && user.player.address.city !== null ? user.player.address.city : '',
        zip: user.player.address && user.player.address.zip !== null ? user.player.address.zip : '',
        phone: user.player.phone && user.player.phone !== null ? user.player.phone : '',
    };

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const phoneRegExpSize = /^(?=(?:\D*\d){10,10})[0-9_-]{10,12}$/;
    const schema = yup.object().shape({
        address1: yup.string().required('Address Line 1 is required').max(200, 'Address Line 1 cannot exceed 200 characters'),
        address2: yup.string().max(200, 'Address Line 2 cannot exceed 200 characters'),
        city: yup.string().required('City is required').max(30, 'City cannot exceed 30 characters'),
        state: yup
            .string()
            .required('State is required')
            .min(2, 'State must be 2 characters')
            .max(2, 'State must be 2 characters')
            .oneOf(
                states.map((st) => {
                    return st.abbr;
                }),
                'Must be a valid state'
            ),

        zip: yup.string().min(5).max(5).required('Zip is required'),

        phone: yup
            .string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .matches(phoneRegExpSize, 'Phone number is not valid')
            .required('Phone is required'),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState('');
    const [localErrors, setLocalErrors] = useState({});

    // if the form field changes, we will change the value in the store and
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trimStart();
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });
    };

    const updateRegister = async (fieldsToPush) => {
        await actions.userActions.update(fieldsToPush);
    };

    const stepSubmit = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(stepFields);

        if (valid) {
            setError('');
            let fieldsToPush = {
                ...verifyFields,
                address: {
                    state: stepFields.state,
                    address1: stepFields.address1,
                    address2: stepFields.address2,
                    city: stepFields.city,
                    zip: stepFields.zip,
                },
                phone: stepFields.phone,
            };

            await setVerifyFields(fieldsToPush);
            await updateRegister(fieldsToPush);
        } else {
            await schema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                setLocalErrors(getValidationErrors(err));
                setError(err.message);
            });
        }
    };

    const getValidationErrors = (err) => {
        const validationErrors = {};

        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);

        return validationErrors;
    };

    const success = () => {
        if (step.step == 3 && !user.updateSuccess) {
            if (user.errors) setError(user.errors);
        } else {
            if (user.updateSuccess) {
                setError('');
                setLocalErrors({});
                actions.userActions.clearErrors();
                setStep({ step: 4 });
            }
        }
    };

    useEffect(success, [user]);

    if (step.step == 3) {
        return (
            <div className='registration-step'>
                <ContentBox>
                    <ContentBoxHead className='registration-step-title'>Contact Information</ContentBoxHead>
                    <ContentBoxBody>
                        <div className='inner-step'>
                            {error ? (
                                <div className='alert alert-danger text-center' role='alert'>
                                    {Object.values(localErrors).map((err) => {
                                        return <p>{err}</p>;
                                    })}
                                </div>
                            ) : null}
                            <div className='form-row'>
                                <div className='form-group col'>
                                    <label for='address1'>Home Address*</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id='address1'
                                        value={stepFields.address1}
                                        name='address1'
                                        onChange={handleChange}
                                        placeholder=''
                                    />
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='form-group col'>
                                    <label for='address2'>Home Address Line 2</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id='address2'
                                        value={stepFields.address2}
                                        name='address2'
                                        onChange={handleChange}
                                        placeholder=''
                                    />
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='form-group col'>
                                    <label for='city'>City*</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id='city'
                                        value={stepFields.city}
                                        name='city'
                                        onChange={handleChange}
                                        placeholder=''
                                    />
                                </div>
                                <div className='form-group col'>
                                    <label for='state'>State*</label>
                                    <Cleave
                                        type='text'
                                        className='form-control'
                                        id='state'
                                        value={stepFields.state}
                                        name='state'
                                        options={{
                                            blocks: [2],
                                            uppercase: true,
                                        }}
                                        onChange={handleChange}
                                        placeholder=''
                                    />
                                </div>
                                <div className='form-group col'>
                                    <label for='zip'>Zip*</label>
                                    <Cleave
                                        type='text'
                                        className='form-control'
                                        id='zip'
                                        value={stepFields.zip}
                                        name='zip'
                                        options={{
                                            blocks: [5],
                                            numericOnly: true,
                                        }}
                                        onChange={handleChange}
                                        placeholder=''
                                    />
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='form-group col'>
                                    <label for='phone'>Phone Number*</label>
                                    <Cleave
                                        className='form-control col-md-3'
                                        id='phone'
                                        placeholder='000-000-0000'
                                        value={stepFields.phone}
                                        name='phone'
                                        options={{
                                            blocks: [3, 3, 4],
                                            delimiter: '-',
                                            numericOnly: true,
                                        }}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </ContentBoxBody>
                </ContentBox>
                <div className='bottom-section'>
                    <div>
                        <button type='button' className='btn theme-btn continue' onClick={stepSubmit}>
                            Continue to Verification
                        </button>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const StepThree = connect(mapStateToProps, mapDispatchToProps)(StepThreeTemplate);

export { StepThree };

import { createSlice } from '@reduxjs/toolkit';
import apiClient from '../Api/api_client';
import { entrySlice } from './EntryStore';
import { loadingSlice } from './LoadingStore';
import isBrowser from '../utils/isBrowser';

export const defaultCheckTicketState = {
    success: false,
    message: '',
};

// REDUCERS
// basic actions that affect this object only

const checkTicketReducer = (state, action) => {
    return {
        ...state,
        success: action.payload.success,
        message: action.payload.message,
    };
};

const resetReducer = (state, action) => {
    return {
        ...defaultCheckTicketState,
    };
};

export const checkTicketSlice = createSlice({
    name: 'CheckTicket',
    initialState: defaultCheckTicketState,
    reducers: {
        checkTicket: checkTicketReducer,
        reset: resetReducer,
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const checkTicket = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'checkTicket' }));

    if (isBrowser() && typeof window.MdiNativeShell !== 'undefined') {
        window.MdiNativeShell.getDeviceUdid(async (infoObject) => {
            let info = {
                device_id: infoObject,
                salt: 'hp]lZCxufNwp<e-EU)Y$HUo2Hj#90%a-1&--Q[M0FkLfx1lGzPBxCj>8wl*:h<wa',
            };
            let sending = {
                ...payload,
                ...info,
            };

            const result = await apiClient().checkTicket(sending);
            if (result.success) {
                dispatch(entrySlice.actions.setLastEntry(result.entry));
            }
            dispatch(checkTicketSlice.actions.checkTicket(result));
            dispatch(loadingSlice.actions.unsetLoading({ action: 'checkTicket' }));
        });
    } else {
        const result = await apiClient().checkTicket(payload);
        if (result.success) {
            dispatch(entrySlice.actions.setLastEntry(result.entry));
        }
        dispatch(checkTicketSlice.actions.checkTicket(result));
        dispatch(loadingSlice.actions.unsetLoading({ action: 'checkTicket' }));
    }
};

//actions that get exported to the views
export const checkTicketActions = (dispatch) => {
    return {
        checkTicket: (payload) => checkTicket(dispatch, payload),
        reset: (payload) => dispatch(checkTicketSlice.actions.reset()),
    };
};

import { createSlice } from '@reduxjs/toolkit';

export const defaultLoadingState = {
    loading: false,
    actions: {
        login: false,
        logout: false,
        update: false,
        register: false,
        verify: false,
        confirm: false,
        confirmResend: false,
        forgotPassword: false,
        recoverPassword: false,
        getPlayerData: false,
        clearErrors: false,
        getGame: false,
        getDrawings: false,
        postFeedback: false,
        getEntries: false,
        enterDrawTicket: false,
        enterScratchTicket: false,
        enterFastPlayTicket: false,
        setGameOver: false,
        getSweeps: false,
        getCurrentSweeps: false,
        getPastSweeps: false,
        getReward: false,
        getConfig: false,
        getRevealToken: false,
        getOptins: false,
        optinSubscribe: false,
        optinUnsubscribe: false,
    },
};

// REDUCERS

const setLoadingReducer = (state, action = { payload: {} }) => {
    const { payload } = action;

    if (payload && payload['action']) {
        var loadingActions = {};

        loadingActions[payload.action] = true;

        return {
            ...state,
            actions: {
                ...state.actions,
                ...loadingActions,
            },
        };
    }
    return {
        ...state,
    };
};

const unsetLoadingReducer = (state, action = { payload: {} }) => {
    const { payload } = action;

    if (payload && payload['action']) {
        var loadingActions = {};

        loadingActions[payload.action] = false;

        return {
            ...state,
            actions: {
                ...state.actions,
                ...loadingActions,
            },
        };
    }
    return {
        ...state,
    };
};

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: defaultLoadingState,
    reducers: {
        // add an action to chceck localstorage
        // for existing session
        setLoading: setLoadingReducer,
        unsetLoading: unsetLoadingReducer,
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const setLoading = (dispatch, payload) => {
    const result = dispatch(loadingSlice.actions.setLoading(payload));
    return result;
};

const unsetLoading = (dispatch, payload) => {
    const result = dispatch(loadingSlice.actions.unsetLoading(payload));
    return result;
};

export const loadingActions = (dispatch) => {
    return {
        // dispatching plain actions
        setLoading: (payload) => setLoading(dispatch, payload),
        unsetLoading: (payload) => unsetLoading(dispatch, payload),
    };
};

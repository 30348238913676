import React, { useState } from 'react';
import Cleave from 'cleave.js/react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import * as yup from 'yup';
import states from '../RegisterForm/states.json';

import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from '../ContentBox';

const UpdateContact = ({ verifyFields, setVerifyFields, user, actions }) => {
    const initialStepFields = {
        state: user.player.address && user.player.address.state ? user.player.address.state : '',
        address1: user.player.address && user.player.address.address1 ? user.player.address.address1 : '',
        address2: user.player.address && user.player.address.address2 ? user.player.address.address2 : '',
        city: user.player.address && user.player.address.city ? user.player.address.city : '',
        zip: user.player.address && user.player.address.zip ? user.player.address.zip : '',
        phone: user.player.phone ?? '',
    };

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const phoneRegExpSize = /^(?=(?:\D*\d){10,10})[0-9_-]{10,12}$/;

    const schema = yup.object().shape({
        address1: yup.string().required('Address Line 1 is required').max(200, 'Address Line 1 cannot exceed 200 characters'),

        address2: yup.string().max(200, 'Address Line 2 cannot exceed 200 characters'),

        city: yup.string().required('City is required').max(30, 'City cannot exceed 30 characters'),

        state: yup
            .string()
            .required('State is required')
            .min(2, 'State must be 2 characters')
            .max(2, 'State must be 2 characters')
            .oneOf(
                states.map((st) => {
                    return st.abbr;
                }),
                'Must be a valid state'
            ),

        zip: yup
            .string()
            .min(5)
            .max(5)
            .required('Zip is required'),

        phone: yup
            .string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .matches(phoneRegExpSize, 'Phone number is not valid')
            .required('Phone is required'),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState('');
    const [localErrors, setLocalErrors] = useState({});

    // if the form field changes, we will change the value in the store and
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trimStart();
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(stepFields);

        if (valid) {
            setError('');
            setLocalErrors({});

            await actions.userActions.updateSection({ section: 'contact', status: 'initial' });

            const payloadData = {
                firstname: user.player.firstname,
                lastname: user.player.lastname,
                address: user.player.address ?? {},

                // enabled fields:
                phone: stepFields.phone ?? '',

                //? disabled: needed for later
                /*
                address: {
                    state: stepFields.state ?? '',
                    address1: stepFields.address1 ?? '',
                    address2: stepFields.address2 ?? '',
                    city: stepFields.city ?? '',
                    zip: stepFields.zip ?? '',
                },
                */
            };

            await actions.userActions.update(payloadData);
        } else {
            await schema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                setLocalErrors(getValidationErrors(err));
                setError(err.message);
            });
        }
    };

    const getValidationErrors = (err) => {
        const validationErrors = {};

        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);

        return validationErrors;
    };

    return (
        <ContentBox variant='theme-blue'>
            <ContentBoxHead>CONTACT INFORMATION</ContentBoxHead>
            <ContentBoxBody>
                <div className='registration-step'>
                    <div className='inner-step'>
                        {error ? (
                            <div className='alert alert-danger text-center' role='alert'>
                                {Object.values(localErrors).map((err, index) => {
                                    return <p key={index}>{err}</p>;
                                })}
                            </div>
                        ) : null}

                        {user.updateSection.section === 'contact' && user.updateSection.status === 'success' && (
                            <div className='alert alert-success text-center' role='alert'>
                                Contact Information updated.
                            </div>
                        )}

                        {user.updateSection.section === 'contact' && user.updateSection.status === 'error' && (
                            <div className='alert alert-danger text-center' role='alert'>
                                Server error, please try again later.
                            </div>
                        )}

                        <div className='form-row'>
                            <div className='form-group col'>
                                <label for='address1'>Home Address*</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='address1'
                                    value={stepFields.address1}
                                    name='address1'
                                    placeholder=''
                                    //? currently disabled field: onChange={handleChange}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='form-group col'>
                                <label for='address2'>Home Address Line 2</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='address2'
                                    value={stepFields.address2}
                                    name='address2'
                                    placeholder=''
                                    //? currently disabled field: onChange={handleChange}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='form-group col'>
                                <label for='confirmEmail'>City*</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='city'
                                    value={stepFields.city}
                                    name='city'
                                    placeholder=''
                                    //? currently disabled field: onChange={handleChange}
                                    disabled
                                />
                            </div>
                            <div className='form-group col'>
                                <label for='state'>State*</label>
                                <Cleave
                                    type='text'
                                    className='form-control'
                                    id='state'
                                    value={stepFields.state}
                                    name='state'
                                    options={{
                                        blocks: [2],
                                        uppercase: true,
                                    }}
                                    placeholder=''
                                    //? currently disabled field: onChange={handleChange}
                                    disabled
                                />
                            </div>
                            <div className='form-group col'>
                                <label for='confirmEmail'>Zip*</label>
                                <Cleave
                                    type='text'
                                    className='form-control'
                                    id='zip'
                                    value={stepFields.zip}
                                    name='zip'
                                    options={{
                                        blocks: [5],
                                        numericOnly: true,
                                    }}
                                    placeholder=''
                                    //? currently disabled field: onChange={handleChange}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className='form-row'>
                            <div className='form-group col'>
                                <label for='confirmEmail'>Phone Number*</label>
                                <Cleave
                                    className='form-control col-md-3'
                                    id='phone'
                                    placeholder='000-000-0000'
                                    value={stepFields.phone}
                                    name='phone'
                                    options={{
                                        blocks: [3, 3, 4],
                                        delimiter: '-',
                                        numericOnly: true,
                                    }}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <p className='text-center my-2'>
                            Be sure to click the 'Update Information' button when you are done or your changes will not be saved.
                        </p>

                        <button type='button' className='btn theme-btn btn-block theme-gray' onClick={handleSubmit}>
                            Update Information
                        </button>
                    </div>
                </div>
            </ContentBoxBody>
        </ContentBox>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateContact);

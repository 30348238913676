import React from 'react';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from '../ContentBox';

import ticketImageFront from '../../assets/images/enter_ticket_image_front.png';
import ticketImageBack from '../../assets/images/enter_ticket_image_back.png';

const TicketSamples = () => {
    return (
        <div className='row justify-content-center'>
            <div className='col-12'>
                <ContentBox variant='theme-green' id='enter-help'>
                    <ContentBoxHead>
                        WHERE ARE MY NUMBERS?
                        <ContentCollapser />
                    </ContentBoxHead>
                    <ContentBoxBody>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div>
                                    <h3 className='text-center'>Ticket Front</h3>
                                    <p>
                                        The Front Number is a 10-digit number located in the game play area under the scratch-off coating on the front of the
                                        ticket.
                                    </p>
                                </div>
                                <img className='img-fluid mx-auto d-block' src={ticketImageFront} alt='ticket sample front' />
                            </div>
                            <div className='col-md-6'>
                                <div>
                                    <h3 className='text-center'>Ticket Back</h3>
                                    <p>The Back Number is a 13-digit number that is on the back of the ticket below the barcode.</p>
                                </div>
                                <img className='img-fluid mx-auto d-block' src={ticketImageBack} alt='ticket sample back' />
                            </div>
                        </div>
                    </ContentBoxBody>
                </ContentBox>
            </div>
        </div>
    );
};

export default TicketSamples;

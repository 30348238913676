import React from 'react';
import { Collapsable } from 'ui-kit';
import DrawingGroup from './DrawingGroup';

export default class SweepsInner extends React.Component {
    componentDidMount() {
        this.props.actions.sweepActions.getSweeps();
    }

    render() {
        const sweeps = this.props.sweep.sweeps || [];
        const usedCopy = this.props.copy;

        const drawGroupRender = (drawing_groups, drawGroupId) => {
            if (drawing_groups.length) {
                return drawing_groups.map((drawingGroup, index) => {
                    return (
                        <DrawingGroup
                            key={drawingGroup.quantum_start}
                            drawingGroup={drawingGroup}
                            index={index}
                            selection={drawGroupId == drawingGroup.id}
                            usedCopy={usedCopy}
                        />
                    );
                });
            } else {
                return <div>{usedCopy.sweeps.nodrawinggroups}</div>;
            }
        };

        if (sweeps && sweeps.length) {
            return (
                <div className="container-fluid sweep-list">
                    <div className="col-lg-10 offset-lg-1">
                        {sweeps.map((sweep) => {
                            return (
                                <Collapsable
                                    key={sweep.name}
                                    title={sweep.name}
                                    collapsed={
                                        sweep.id ==
                                        this.props.preselection.sweepId
                                    }
                                >
                                    {drawGroupRender(
                                        sweep.drawing_groups,
                                        this.props.preselection.drawGroupId
                                    )}
                                </Collapsable>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="container-fluid sweep-list empty">
                    {usedCopy.sweeps.nosweepstakes}
                </div>
            );
        }
    }
}

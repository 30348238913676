import { createSlice } from '@reduxjs/toolkit';
import apiClient from '../Api/api_client';
import { entrySlice } from './EntryStore';
import { loadingSlice } from './LoadingStore';
import { drawTicketSlice } from './DrawTicketStore';

import isJSON from '../utils/isJSON';

export const defaultUserState = {
    player: {
        name: '',
        email: '',
        player_id: '',
        firstname: '',
        lastname: '',
        emailconfirm: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone1: '',
        area_phone1: '',
        prefix_phone1: '',
        suffix_phone1: '',
        phone2: '',
        area_phone2: '',
        prefix_phone2: '',
        suffix_phone2: '',
        dob_year: '',
        dob_month: '',
        dob_day: '',
        password: '',
        verify_password: '',
        optins: [],
        optin1: '',
        optin2: '',
        registrar_id: '',
    },
    spat: null,
    actions: [],
    loggedIn: false,
    errors: '',
    errorData: {},
    message: '',
    success: false,
    registerSuccess: false,
    updateSection: {
        section: null,
        status: null,
        code: null,
    },
    lastServerResponse: {
        type: null,
        message: null,
        code: null,
    },
    updateSuccess: false,
    verifySuccess: false,
    stepsLeft: [],
    expiration_days: localStorage.getItem('password_expiration_days') ? localStorage.getItem('password_expiration_days') : 365,
    optins: [],
};

// REDUCERS FOR THE USER
// basic actions that affect this object only

const loginReducer = (state, action) => {
    const { payload } = action;

    let actions = payload.player && payload.player.actions && Array.isArray(payload.player.actions) ? payload.player.actions : [];

    //? For testing:
    /*
    actions = [
        'update-account',
        'verify-account',
        'confirm-account',
    ];
    */

    //? For testing: console.log('🚩 loginReducer', payload, actions);
    localStorage.setItem('password_expiration_days', payload.password_expiration_days ?? 365);

    return {
        ...state,
        loggedIn: payload.loggedIn,
        player: {
            ...state.player,
            ...payload.player,

            //? Note: Login player data is different from getPlayerData
            firstname: payload.first_name ?? '',
            lastname: payload.last_name ?? '',
        },
        errors: payload.errors,
        errorData: payload.data,
        message: payload.message,
        actions: actions,
        expiration_days: payload.password_expiration_days,
    };
};

const logoutReducer = (state, action) => {
    return defaultUserState;
};

const updateReducer = (state, action) => {
    const { payload } = action;
    //? For debug: console.log('updateReturn: ', payload);
    return {
        ...state,
        errors: payload.errors,
        updateSuccess: payload.success,
        updateSection: {
            ...state.updateSection,
            status: payload.success ? 'success' : 'error',
            code: payload.errors ?? null,
        },
        player: {
            ...state.player,
            ...payload.payload?.player,
        },
    };
};

const updateSectionReducer = (state, action) => {
    const { payload } = action;
    return {
        ...state,
        updateSection: payload,
    };
};

const registerReducer = (state, action) => {
    const { payload } = action;
    console.log(payload);
    return {
        ...state,
        errors: payload.errors,
        registerSuccess: payload.success,
        player: {
            ...state.player,
            ...payload.player,
        },
    };
};

const verifyReducer = (state, action) => {
    const { payload } = action;
    console.log(payload);
    return {
        ...state,
        errors: payload.errors,
        verifySuccess: payload.success,
        player: {
            ...state.player,
            ...payload.player,
        },
    };
};

const confirmReducer = (state, action) => {
    const { payload } = action;
    return {
        ...state,
        errors: payload.errors,
        verifySuccess: payload.success,
        player: {
            ...state.player,
            ...payload.player,
        },
        lastServerResponse: {
            type: 'confirm',
            message: payload,
        },
    };
};

const confirmResendReducer = (state, action) => {
    const { payload } = action;
    //? For debug: console.log('🎁 confirmResendReducer: ', payload);

    return {
        ...state,
        lastServerResponse: {
            type: 'confirmResend',
            message: payload,
        },
        updateSection: {
            ...state.updateSection,
            status: payload.player ? 'success' : 'error',
        },
    };
};

const forgotPasswordReducer = (state, action) => {
    let { payload } = action;
    //? For debug: console.log('🎁 forgotPasswordReturn: ', payload);

    if (payload.code === '_NOT_FOUND_MODEL') {
        payload.success = false;
    }

    if (payload.status === 'ok') {
        payload.success = true;
    }

    return {
        ...state,
        updateSection: {
            ...state.updateSection,
            status: payload.success ? 'success' : 'error',
        },
        errors: payload.errors,
        success: payload.success,
    };
};

const recoverPasswordReducer = (state, action) => {
    let { payload } = action;
    //? For debug: console.log('🎁 recoverPasswordReturn: ', payload);

    if (payload.code === '_NOT_FOUND') {
        payload.success = false;
        payload.code = '_PASSCODE_EXPIRED';
    }

    if (payload.code === '_NOT_FOUND_MODEL') {
        payload.success = false;
        payload.code = '_PASSCODE_EXPIRED';
    }

    if (payload.status === 'ok') {
        payload.success = true;
    }

    return {
        ...state,
        updateSection: {
            ...state.updateSection,
            status: payload.success ? 'success' : 'error',
            code: payload.code ?? null,
        },
        errors: payload.errors,
        success: payload.success,
    };
};

const getPlayerDataReducer = (state, action) => {
    const { payload } = action;

    const spat = isJSON(payload.spat) ? JSON.parse(payload.spat) : null;
    const actions = spat && spat.player && spat.player.actions && Array.isArray(spat.player.actions) ? spat.player.actions : [];

    //? For testing: console.log('getPlayerDataReducer', payload, spat, actions);

    return {
        ...state,
        loggedIn: payload.loggedIn,
        player: {
            ...state.player,
            ...payload.player,
        },
        spat: spat,
        actions: actions,
    };
};

const getAllOptinsReducer = (state, action) => {
    const { payload } = action;
    //? For testing: console.log('getOptinsAllReducer', payload);
    return {
        ...state,
        optins: payload,
    };
};

const getOptinsReducer = (state, action) => {
    const { payload } = action;
    const optins = Array.isArray(payload) ? payload : [];
    //? For testing: console.log('getOptinsReducer', payload, optins);
    return {
        ...state,
        player: {
            ...state.player,
            optins: optins,
        },
    };
};

const optinSubscribeReducer = (state, action) => {
    const { payload } = action;
    //? For testing: console.log('optinSubscribeReducer', payload);
    return {
        ...state,
        updateSection: {
            ...state.updateSection,
            status: payload.registrar_id ? 'success' : 'error',
            code: payload.code ?? null,
        },
        errors: payload.errors,
        success: payload.success,
    };
};

const optinUnsubscribeReducer = (state, action) => {
    const { payload } = action;
    //? For testing: console.log('optinUnsubscribeReducer', payload);
    return {
        ...state,
        updateSection: {
            ...state.updateSection,
            status: payload.status ? 'success' : 'error',
            code: payload.code ?? null,
        },
        errors: payload.errors,
        success: payload.success,
    };
};

const setErrorsReducer = (state, action) => {
    const { payload } = action;
    return {
        ...state,
        errors: payload.errors,
        errorData: payload.data,
        message: payload.message,
    };
};

const clearErrorsReducer = (state, action) => {
    return {
        ...state,
        errors: '',
        success: false,
        updateSuccess: false,
        registerSuccess: false,
        verifySuccess: false,
    };
};

export const userSlice = createSlice({
    name: 'user',
    initialState: defaultUserState,
    reducers: {
        login: loginReducer,
        logout: logoutReducer,
        update: updateReducer,
        updateSection: updateSectionReducer,
        register: registerReducer,
        verify: verifyReducer,
        confirm: confirmReducer,
        confirmResend: confirmResendReducer,
        getPlayerData: getPlayerDataReducer,
        getAllOptins: getAllOptinsReducer,
        getOptins: getOptinsReducer,
        optinSubscribe: optinSubscribeReducer,
        optinUnsubscribe: optinUnsubscribeReducer,
        clearErrors: clearErrorsReducer,
        forgotPassword: forgotPasswordReducer,
        recoverPassword: recoverPasswordReducer,
        setErrors: setErrorsReducer,
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const login = async (dispatch, payload) => {
    if (!userSlice.loggedIn) {
        dispatch(loadingSlice.actions.setLoading({ action: 'login' }));
        const result = await apiClient().login(payload);
        await dispatch(userSlice.actions.login(result));
        await dispatch(entrySlice.actions.reset());
        await dispatch(drawTicketSlice.actions.reset());
        dispatch(loadingSlice.actions.unsetLoading({ action: 'login' }));
    }
};

const logout = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'logout' }));
    const result = await apiClient().logout(payload);
    dispatch(userSlice.actions.logout(result));
    dispatch(entrySlice.actions.reset());
    dispatch(drawTicketSlice.actions.reset());
    dispatch(loadingSlice.actions.unsetLoading({ action: 'logout' }));
};

const update = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'update' }));
    const result = await apiClient().update(payload);
    dispatch(userSlice.actions.update(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'update' }));
};

const updateSection = async (dispatch, payload) => {
    dispatch(userSlice.actions.updateSection(payload));
};

const register = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'register' }));
    const result = await apiClient().register(payload);
    dispatch(userSlice.actions.register(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'register' }));
};

const verify = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'verify' }));
    const result = await apiClient().verify(payload);
    dispatch(userSlice.actions.verify(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'verify' }));
};

const confirm = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'confirm' }));
    const result = await apiClient().confirm(payload);
    dispatch(userSlice.actions.confirm(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'confirm' }));
};

const confirmResend = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'confirmResend' }));
    const result = await apiClient().confirmResend(payload);
    dispatch(userSlice.actions.confirmResend(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'confirmResend' }));
};

const forgotPassword = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'forgotPassword' }));
    const result = await apiClient().forgotPassword(payload);
    dispatch(userSlice.actions.forgotPassword(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'forgotPassword' }));
};

const recoverPassword = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'recoverPassword' }));
    const result = await apiClient().recoverPassword(payload);
    dispatch(userSlice.actions.recoverPassword(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'recoverPassword' }));
};

const getPlayerData = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'getPlayerData' }));
    const result = await apiClient().getPlayerData();
    dispatch(userSlice.actions.getPlayerData(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'getPlayerData' }));
};

const getOptins = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'getOptins' }));
    const result = await apiClient().getAllOptins();
    dispatch(userSlice.actions.getAllOptins(result));
    const optins = await apiClient().getOptins();
    dispatch(userSlice.actions.getOptins(optins));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'getOptins' }));
};

const optinSubscribe = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'optinSubscribe' }));
    const subscribe = await apiClient().optinSubscribe(payload);
    dispatch(userSlice.actions.optinSubscribe(subscribe));
    const result = await apiClient().getOptins();
    dispatch(userSlice.actions.getOptins(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'optinSubscribe' }));
};

const optinUnsubscribe = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'optinUnsubscribe' }));
    const unsubscribe = await apiClient().optinUnsubscribe(payload);
    dispatch(userSlice.actions.optinUnsubscribe(unsubscribe));
    const result = await apiClient().getOptins();
    dispatch(userSlice.actions.getOptins(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'optinUnsubscribe' }));
};

// actions that get exported to the views
export const userActions = (dispatch) => {
    return {
        login: (payload) => login(dispatch, payload),
        logout: (payload) => logout(dispatch, payload),
        update: (payload) => update(dispatch, payload),
        updateSection: (payload) => updateSection(dispatch, payload),
        register: (payload) => register(dispatch, payload),
        verify: (payload) => verify(dispatch, payload),
        confirm: (payload) => confirm(dispatch, payload),
        confirmResend: (payload) => confirmResend(dispatch, payload),
        forgotPassword: (payload) => forgotPassword(dispatch, payload),
        recoverPassword: (payload) => recoverPassword(dispatch, payload),
        getPlayerData: (payload) => getPlayerData(dispatch, payload),
        clearErrors: (payload) => dispatch(userSlice.actions.clearErrors()),
        setErrors: (payload) => dispatch(userSlice.actions.setErrors(payload)),
        getOptins: (payload) => getOptins(dispatch, payload),
        optinSubscribe: (payload) => optinSubscribe(dispatch, payload),
        optinUnsubscribe: (payload) => optinUnsubscribe(dispatch, payload),
    };
};

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import { BoxedContent, hasCMSContent, CMSContent } from 'sg-ui-components';

const LegalPage = ({ cmsSourceFirebase }) => {
    return (
        <div class='enter-container container-fluid'>
            <div class='row justify-content-center'>
                <div class='entry-box col-10'>
                    <h2 className='text-center my-4'>
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.legalAndPolicyItems.legalNotices.contentHeaderText'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />
                    </h2>
                    <div>
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.legalAndPolicyItems.legalNotices.contentHTML'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalPage);

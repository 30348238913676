import React from 'react';
import { getCMSObject, WinnerLink } from 'sg-ui-components';

const telescriptWinnerLink = getCMSObject('data.components.teleScripts.WinnerLink.jsonBlock');

const WinnerLinkWrapper = ({ selected }) => {
    return (
        <WinnerLink
            selected={selected}
            telescript={telescriptWinnerLink}
        />
    );
};

export default WinnerLinkWrapper;

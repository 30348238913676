import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import { MarkdownArea } from 'ui-kit';

// Supported Components in Markdown
import { ContentPageManifest } from '../Components/ContentRequireManifest';

import './layout.scss';

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

const pageStyle = {};

// import SEO from '../components/seo';\

const ContentPageTemplate = ({ user, config, actions, contentData, layoutData, pageData, cmsSource }) => {
    const [body, setBody] = useState(null);

    const getContentPage = async (pageName) => {
        let db = window.localStorage;
        const res = db.getItem(pageName);

        const pages = JSON.parse(res).body;

        setBody(pages);
    };

    useEffect(() => {
        if (cmsSource.ready) {
            getContentPage(contentData);
        }
    }, [contentData, cmsSource.ready]);

    return (
        <Layout user={user} config={config} actions={actions} mainMenu={layoutData} pageData={pageData}>
            <div css={css(pageStyle)} className=''>
                {body ? <MarkdownArea components={ContentPageManifest}>{body}</MarkdownArea> : ''}
            </div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentPageTemplate);

const Img = ({ src, alt, className }) => {
    let db = window.localStorage;
    console.log(src);
    const res = db.getItem(src);
    return <img src={res} alt={alt} className={className} />;
};

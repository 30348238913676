import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import { UserMenu } from './UserMenu';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../Store';
import CustomerContent from '../../CustomerContent';

const Header = () => {
    return (
        <div class='header'>
            <CustomerContent />
            <div class='d-flex align-items-center justify-content-center hero'>2ND CHANCE</div>{' '}
            <PrimaryNav>
                <UserMenu />
            </PrimaryNav>
            <MobilePrimaryNav />
        </div>
    );
};

export default Header;

const PrimaryNavTemplate = ({ pageData, children }) => {
    const mainMenu = pageData.pageInfo[0].layout.primary_nav;

    return (
        <Navbar variant className='d-none d-md-flex sg-nav'>
            <Nav className='mr-auto' as='ul'>
                {mainMenu.map((link, key) => {
                    if (link.subMenu === undefined) {
                        return (
                            <li key={`main-menu--${key}`}>
                                <NavLink to={link.uri} activeClassName='active' className='nav-link' role='button'>
                                    {link.title}
                                </NavLink>
                            </li>
                        );
                    } else if (link.subMenu.length) {
                        return (
                            <li className='has-dropdown' key={`main-menu--${key}`}>
                                <NavDropdown alignRight key={`dropdown-${key}`} title={link.title} className='nav-submenu'>
                                    {link.subMenu.map((subLink, key) => {
                                        return (
                                            <NavDropdown.Item
                                                key={`sub-menu--${key}`}
                                                as={NavHashLink}
                                                to={subLink.uri + '#' + subLink.anchor}
                                                activeClassName='active'>
                                                {subLink.title}
                                            </NavDropdown.Item>
                                        );
                                    })}
                                </NavDropdown>
                            </li>
                        );
                    }
                })}
            </Nav>
            <UserMenu />
        </Navbar>
    );
};

const PrimaryNav = connect(mapStateToProps, mapDispatchToProps)(PrimaryNavTemplate);

const MobilePrimaryNavTemplate = ({ pageData, children }) => {
    const [toggleText, setToggleText] = useState('MENU');
    const mobileMainMenu = pageData.pageInfo[0].layout.mobile_nav;

    return (
        <div className='d-flex d-md-none mobile-nav-row'>
            <Dropdown drop='down' id='mobile-nav-dropdown' onSelect={(ek, ev) => setToggleText(ev.target.innerText)} className='flex-grow-1'>
                <Dropdown.Toggle id='mobile-nav-toggle' className='w-100' variant>
                    {toggleText}
                </Dropdown.Toggle>
                <Dropdown.Menu className='mobile-dropdown-menu'>
                    {mobileMainMenu.map((link, key) => {
                        return (
                            <Dropdown.Item key={`mobile-menu--${key}`} as={NavLink} to={link.uri} activeClassName='active' className='nav-link'>
                                {link.title}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
            <UserMenu />
        </div>
    );
};

const MobilePrimaryNav = connect(mapStateToProps, mapDispatchToProps)(MobilePrimaryNavTemplate);

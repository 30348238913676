import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import RewardHistory from '../Components/Rewards/RewardHistoryTemplate';
import Layout from '../Components/Layout';

const Rewards = ({ user, config, actions, contentData, layoutData, pageData }) => {
    return (
        <Layout user={user} config={config} actions={actions} mainMenu={layoutData} pageData={pageData}>
            <div className='rewards'>
                <div className='row'>
                    <div className='col-lg-10 offset-lg-1'>
                        <RewardHistory />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);

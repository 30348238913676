import React from 'react';
import { getCMSObject, RecentDrawings, formatDateTBD, TeleScript, NextDrawings } from 'sg-ui-components';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import WinnerLinkWrapper from '../Components/WinnerLinkWrapper';
import promotionConfig from '../promotion_config';
import { Button } from 'react-bootstrap';

const WinnersPage = ({ user, winner, config, cmsSourceFirebase, actions, match }) => {
    const telescriptRecentDrawings = getCMSObject('data.components.teleScripts.RecentDrawings.jsonBlock');
    const telescriptNextDrawing = getCMSObject('data.components.teleScripts.nextDrawing.jsonBlock');

    const renderCarouselItem = (drawing) => (
        <div className='next-drawing-card mt-2 mb-5'>
            <h3>{drawing.promotion}</h3>
            <div>{drawing.drawing_date}</div>
            <hr class='next-drawing-divider' />

            <div>
                <TeleScript line={telescriptNextDrawing?.promotionText}>Want More Chances to Win? </TeleScript>
            </div>

            <Button className='next-drawing-button' href='/enter'>
                Enter Now
            </Button>
        </div>
    );

    return (
        <div class='winners-container'>
            <NextDrawings
                {...{ user, winner, cmsSourceFirebase, actions }}
                formatDate={formatDateTBD?.dateWithDayOfTheWeek}
                telescript={telescriptNextDrawing ?? ''}
                options={{
                    variant: 'theme-blue',
                    limitOnePerSweepstake: promotionConfig?.nextDrawingsLimitOnePerSweepstake ?? true,
                    maxElement: promotionConfig?.nextDrawingsMaxElement ?? 5,
                    promotionDetailsLink: '/promotion',
                }}
                renderCarouselItem={renderCarouselItem}
            />
            <RecentDrawings
                winner={winner}
                actions={actions}
                createDetailsLink={(sweepsId) => `/promotion?id=${sweepsId}`}
                telescript={telescriptRecentDrawings}
                WinnerLink={WinnerLinkWrapper}
                options={{
                    variant: 'theme-blue',
                    hasCollapser: true,
                    promotionNameKey: 'drawing_group',
                    hash: 'recent-drawings',
                    pageSize: 10,
                    promotionDetailsLink: '/promotion',
                }}
            />
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(WinnersPage);

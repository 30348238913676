import { Img } from './Img';
import { Navbar, Nav, Jumbotron, Row, Container, Dropdown, NavDropdown } from 'react-bootstrap';

import EnterTickets from './Enter/EnterTickets';
import PostClaim from './Enter/PostClaim';
import DrawForm, { DrawError, DrawSuccess } from './Enter/DrawFormTemplate';
import IfLoggedIn from './IfLoggedIn';
import IfLoggedOut from './IfLoggedOut';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from './ContentBox';
import Lottie from './Lottie';
import CustomerContent from './CustomerContent';
import ScratchForm from './Enter/ScratchFormTemplate';
import Sweeps from './Sweeps';
import Sweepstakes from './Sweepstakes';
import ActiveSweepstakes from './Sweepstakes/ActiveSweepstakes';
import SweepstakeDetails from './SweepstakeDetails';
import PromotionsMobile from './PromotionsMobile';
import Rewards from './Rewards/RewardHistoryTemplate';
import { TicketForm } from './Enter/TicketForm';
import { LoginForm, LoginEmail, LoginPassword, LoginSubmit } from './Login/login_form';
import { LoginPage } from './Login';
import ForgotPassword from './Login/ForgotPassword';
import RecoverPassword from './Login/RecoverPassword';
import PasswordExpiring from './Login/PasswordExpiring';
import PasswordExpired from './Login/PasswordExpired';
import ResetPassword from './Login/ResetPassword';
import { RegistrationSteps } from './RegisterForm/registration_steps';
import MyProfile from './MyProfile';
import MyActivity from './MyActivity';
import AccountConfirm from './AccountConfirm';
import BannerCarousel from './BannerCarousel';
import { Welcome, LogoutButton, LoginButton, RegisterButton, AccountButton } from './Layouts/Header/welcome';
import ContentSection from './ContentSection';
import ExpirationBanner from './Login/ExpirationBanner';
import { GTable, THead, TBody, TRData, TH, TData, ShowData } from './Table/GTable';
import { CurrentDrawingData } from './DataProviders/CurrentDrawingData';
import { ExpiredDrawingData } from './DataProviders/ExpiredDrawingData';
import { FormatExpiredDrawingsTable } from './Drawings/FormatExpiredDrawingsTable';
import { SweepsData } from './DataProviders/SweepsData';
import { IfMobile } from './IfMobile';
import { IfNotMobile } from './IfNotMobile';
import Scanner from './Enter/Scanner';
import { DrawingCarousel, CarouselItem } from './Drawings/DrawingCarousel';
import { FormatNextDrawings } from './Drawings/FormatNextDrawings';
import { HomeDrawingCard } from './Drawings/HomeDrawingCard';
import RecentDrawings from './Drawings/RecentDrawings';
import Sandbox from './Sandbox';
import UserLockout from '../utils/user_lockout';
import HomeLoginBox from './HomeLoginBox';
import FeedbackSubHeader from './Layouts/Feedback/FeedbackSubHeader';
import TicketSamples from './Enter/TicketSamples';
import FeedbackPage from '../pages/feedbackPage';
import HomePage from '../pages/homePage';
import LegalPage from '../pages/legalPage';
import PrivacyPage from '../pages/privacyPage';
import TermPage from '../pages/termsPage';
import WinnerPage from '../pages/winnerPage';
import EnterPage from '../pages/enterPage';

const ContentPageManifest = {
    Jumbotron,
    Row,
    Container,
    CustomerContent,
    Lottie,
    DrawForm,
    DrawError,
    ScratchForm,
    Sweeps,
    Sweepstakes,
    FeedbackPage,
    WinnerPage,
    TermPage,
    PrivacyPage,
    LegalPage,
    ActiveSweepstakes,
    SweepstakeDetails,
    PromotionsMobile,
    Rewards,
    LoginForm,
    LoginPage,
    TicketForm,
    Img,
    RegistrationSteps,
    MyProfile,
    MyActivity,
    AccountConfirm,
    BannerCarousel,
    Welcome,
    LogoutButton,
    LoginButton,
    RegisterButton,
    AccountButton,
    LoginEmail,
    LoginPassword,
    LoginSubmit,
    ForgotPassword,
    RecoverPassword,
    PasswordExpiring,
    PasswordExpired,
    ResetPassword,
    IfLoggedIn,
    IfLoggedOut,
    ContentSection,
    ExpirationBanner,
    GTable,
    THead,
    TBody,
    TRData,
    TH,
    TData,
    CurrentDrawingData,
    IfMobile,
    IfNotMobile,
    EnterTickets,
    PostClaim,
    Scanner,
    ShowData,
    ExpiredDrawingData,
    FormatExpiredDrawingsTable,
    SweepsData,
    ContentBox,
    ContentBoxHead,
    ContentBoxBody,
    ContentCollapser,
    DrawingCarousel,
    CarouselItem,
    FormatNextDrawings,
    HomeDrawingCard,
    RecentDrawings,
    DrawSuccess,
    Sandbox,
    UserLockout,
    HomeLoginBox,
    FeedbackSubHeader,
    TicketSamples,
    HomePage,
    EnterPage,
};

const ContentSectionManifest = {
    Img,
    DrawForm,
    DrawError,
    IfLoggedIn,
    IfLoggedOut,
    Row,
    Container,
    ContentBox,
    ContentBoxHead,
    ContentBoxBody,
    ContentCollapser,
    ExpirationBanner,
    TicketForm,
    DrawSuccess,
    Scanner,
    UserLockout,
    EnterTickets,
};

const HeaderManifest = {
    Welcome,
    LogoutButton,
    LoginButton,
    RegisterButton,
    AccountButton,
    Row,
    Container,
    CustomerContent,
    Img,
    Navbar,
    Nav,
    BannerCarousel,
    Dropdown,
    Jumbotron,
    NavDropdown,
    IfLoggedIn,
    ContentSection,
    ExpirationBanner,
    IfMobile,
    IfNotMobile,
    EnterTickets,
};

const FooterManifest = {
    Welcome,
    LogoutButton,
    LoginButton,
    RegisterButton,
    AccountButton,
    Row,
    Container,
    CustomerContent,
    Img,
    Navbar,
    Nav,
    BannerCarousel,
    Dropdown,
    Jumbotron,
    NavDropdown,
    IfLoggedIn,
    ContentSection,
    ExpirationBanner,
    IfMobile,
    IfNotMobile,
    EnterTickets,
};

const CustomerContentManifest = {
    Img,
};

export { ContentPageManifest, ContentSectionManifest, CustomerContentManifest, HeaderManifest, FooterManifest };

import { createSlice } from '@reduxjs/toolkit';
import apiClient from '../Api/api_client';
import { loadingSlice } from './LoadingStore';

export const revealToken = {
    revealToken: {},
};

// REDUCERS
// basic actions that affect this object only

const getRevealTokenReducer = (state, action) => {
    return {
        revealToken: action.payload,
    };
};

export const revealTokenSlice = createSlice({
    name: 'revealToken',
    initialState: revealToken,
    reducers: {
        getRevealToken: getRevealTokenReducer,
    },
});

//ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const getRevealToken = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'getRevealToken' }));
    const result = await apiClient().getRevealToken(payload);
    dispatch(revealTokenSlice.actions.getRevealToken(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'getRevealToken' }));
};

//actions that get exported to the views
export const revealTokenActions = (dispatch) => {
    return {
        getRevealToken: (payload) => getRevealToken(dispatch, payload),
    };
};

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { TicketForm } from 'ui-kit';
import TicketError from '../Error';
import UserLockout from '../../utils/user_lockout';

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

const ScratchFormTemplate = ({
    user,
    config,
    scratchTicket,
    actions,
    contentData,
    layoutData,
    copyData,
    group1Label = '',
    group2Label = '',
    submitName = 'Submit',
}) => {
    return (
        <UserLockout>
            <TicketError ticket={scratchTicket} actions={actions} />
            <TicketForm
                ticket={scratchTicket}
                createfunc={actions.scratchTicketActions.enterScratchTicket}
                successfunc={() => {
                    actions.scratchTicketActions.reset();
                    window.open('post_claim');
                }}
                fields={[
                    {
                        name: 'ticket1_1_1',
                        initialState: '',
                        size: '6',
                        groupNumber: 1,
                        placeholder: '',
                    },
                    {
                        name: 'ticket1_1_2',
                        initialState: '',
                        size: '3',
                        groupNumber: 1,
                        placeholder: '',
                    },
                    {
                        name: 'ticket1_1_3',
                        initialState: '',
                        size: '3',
                        groupNumber: 1,
                    },
                    {
                        name: 'ticket1_1_4',
                        initialState: '',
                        size: '10',
                        groupNumber: 2,
                    },
                ]}
                config={{
                    submit: {
                        name: submitName,
                    },
                    groups: [
                        {
                            over: () => {
                                return <div>{group1Label}</div>;
                            },
                            under: () => {
                                return <div></div>;
                            },
                        },
                        {
                            over: () => {
                                return <div>{group2Label}</div>;
                            },
                            under: () => {
                                return <div></div>;
                            },
                        },
                    ],
                }}
            />
        </UserLockout>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScratchFormTemplate);

import React from 'react';
import { DataProvider, useData } from '../DataProviders/DataContext';

import CarouselMultiple from '../CarouselMultiple/CarouselMultiple';

import './draw_carousel.scss';

// EXAMPLE OF USE IN CMS
/*
<DrawingCarousel>
    <CurrentDrawingData />
    <FormatNextDrawings />
    <CarouselItem />
</DrawingCarousel>
*/

// Can use data from CurrentDrawingData, ExpiredDrawingData, SweepsData
const DrawingCarousel = ({ children }) => {
    return (
        <DataProvider>
            <div className="drawCarousel">{children}</div>
        </DataProvider>
    );
};

const CarouselItem = () => {
    const { state, dispatch } = useData();
    if (state.data.length > 0) {
        return (
            <CarouselMultiple show={3} infiniteLoop={true}>
                {state.data.map((val, index) => {
                    return (
                        <div className="next-drawing-card" key={index}>
                            <h3>{val.promotion}</h3>
                            <div>{val.drawing_date}</div>
                            <div className="more-chances">
                                <p>Want more chances to win?</p>
                                <a className="btn theme-btn btn-block" href="enter">
                                    Enter Now
                                </a>
                            </div>
                        </div>
                    );
                })}
            </CarouselMultiple>
        );
    } else {
        return <></>;
    }
};

export { DrawingCarousel, CarouselItem };

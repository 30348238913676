import { createSlice } from '@reduxjs/toolkit';
import apiClient from '../Api/api_client';
import promotion from '../promotion_config';
import { loadingSlice } from './LoadingStore';

export const defaultPageState = {
    currentSite: '',
    pageInfo: [],
    copyInfo: [],
    styleInfo: {},
    headerInfo: null,
    footerInfo: null,
    jurisdictionInfo: {},
};

// REDUCERS
// basic actions that affect this object only

const getPageDataReducer = (state, action) => {
    return state;
};

const setPageDataReducer = (state, action) => {
    return {
        ...state,
        pageInfo: action.payload,
    };
};

const setCurrentSiteReducer = (state, action) => {
    return {
        ...state,
        currentSite: action.payload,
    };
};

const setCopyDataReducer = (state, action) => {
    return {
        ...state,
        copyInfo: action.payload,
    };
};

const setStyleDataReducer = (state, action) => {
    return {
        ...state,
        styleInfo: action.payload,
    };
};

const setHeaderDataReducer = (state, action) => {
    return {
        ...state,
        headerInfo: action.payload,
    };
};

const setFooterDataReducer = (state, action) => {
    return {
        ...state,
        footerInfo: action.payload,
    };
};

const setJurisdictionDataReducer = (state, action) => {
    return {
        ...state,
        jurisdictionInfo: action.payload,
    };
};

export const pageDataSlice = createSlice({
    name: 'pageData',
    initialState: defaultPageState,
    reducers: {
        getPageData: getPageDataReducer,
        setPageData: setPageDataReducer,
        setCopyData: setCopyDataReducer,
        setStyleData: setStyleDataReducer,
        setHeaderData: setHeaderDataReducer,
        setFooterData: setFooterDataReducer,
        setJurisdictionData: setJurisdictionDataReducer,
        setCurrentSite: setCurrentSiteReducer,
    },
});

//ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const getPageData = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'setPageData' }));

    //? for debug: console.log('Getting page data');
    let db = window.localStorage;
    const res = db.getItem(promotion.promotion + '_pages');
    // const res = await fetch(`/${payload.microsite_name}_pages.json`, {});

    // if (!res.ok) {
    //     const message = `An error has occured: ${res.status}`;
    //     throw new Error(message);
    // }
    const data = JSON.parse(res);
    const pages = JSON.parse(data.body);

    // Set Pages
    //? for debug: console.log(pages);
    dispatch(pageDataSlice.actions.setPageData(pages));
    dispatch(pageDataSlice.actions.setCurrentSite(payload.microsite_name));

    dispatch(loadingSlice.actions.unsetLoading({ action: 'setPageData' }));
};

const getCopyData = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'setPageData' }));

    //? for debug: console.log('Getting page data');
    //? for debug: // const res = await fetch(`/${payload.microsite_name}_copy.json`, {});
    let db = window.localStorage;
    const res = db.getItem(promotion.promotion + '_copy');

    // if (!res.ok) {
    //     const message = `An error has occured: ${res.status}`;
    //     throw new Error(message);
    // }
    const response = JSON.parse(res);

    // Set Pages
    const pages = response;
    dispatch(pageDataSlice.actions.setCopyData(pages));

    dispatch(loadingSlice.actions.unsetLoading({ action: 'setPageData' }));
};

const getStyleData = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'setPageData' }));

    //? for debug: console.log('Getting style data');
    //? for debug: // const res = await fetch(`/${payload.microsite_name}_style.json`, {});
    let db = window.localStorage;
    const res = db.getItem(promotion.promotion + '_style');
    if (res) {
        const data = JSON.parse(res);
        const pages = JSON.parse(data.body);
        dispatch(pageDataSlice.actions.setStyleData(pages));
    }

    dispatch(loadingSlice.actions.unsetLoading({ action: 'setPageData' }));
};

const getHeaderData = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'setPageData' }));

    //? for debug: console.log('Getting header data');
    //? for debug: // const res = await fetch(`/${payload.microsite_name}_header.md`, {});
    let db = window.localStorage;
    const res = db.getItem(promotion.promotion + '_header');
    if (res) {
        const data = JSON.parse(res);
        const pages = data.body;
        dispatch(pageDataSlice.actions.setHeaderData(pages));
    }

    dispatch(loadingSlice.actions.unsetLoading({ action: 'setPageData' }));
};

const getFooterData = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'setPageData' }));

    //? for debug: console.log('Getting footer data');
    //? for debug: // const res = await fetch(`/${payload.microsite_name}_footer.md`, {});
    let db = window.localStorage;
    const res = db.getItem(promotion.promotion + '_footer');
    if (res) {
        const data = JSON.parse(res);
        const pages = data.body;
        dispatch(pageDataSlice.actions.setFooterData(pages));
    }

    dispatch(loadingSlice.actions.unsetLoading({ action: 'setPageData' }));
};

const getJurisdictionData = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'setPageData' }));

    //? for debug: console.log('Getting jurisdiction data');

    let db = window.localStorage;
    const res = db.getItem('jurisdiction_data');

    const data = JSON.parse(res);
    const pages = JSON.parse(data.body);

    dispatch(pageDataSlice.actions.setJurisdictionData(pages));

    dispatch(loadingSlice.actions.unsetLoading({ action: 'setPageData' }));
};

//actions that get exported to the views
export const pageDataActions = (dispatch) => {
    return {
        getPageData: (payload) => getPageData(dispatch, payload),
        getCopyData: (payload) => getCopyData(dispatch, payload),
        getStyleData: (payload) => getStyleData(dispatch, payload),
        getHeaderData: (payload) => getHeaderData(dispatch, payload),
        getFooterData: (payload) => getFooterData(dispatch, payload),
        getJurisdictionData: (payload) => getJurisdictionData(dispatch, payload),
    };
};

import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import Error from '../Components/Error';
// import SEO from '../components/seo';
import UserLockout from '../utils/user_lockout';
import { TicketForm } from 'ui-kit';

const EnterFastplayTemplate = ({ entry, fastPlayTicket, user, game, config, actions, contentData, layoutData, pageData }) => {
    return (
        <Layout user={user} config={config} actions={actions} mainMenu={layoutData} pageData={pageData}>
            <div className='enter primary-page-content'>
                <UserLockout>
                    <Error ticket={fastPlayTicket} actions={actions} />
                    <TicketForm
                        ticket={fastPlayTicket}
                        createfunc={actions.fastPlayTicketActions.enterFastPlayTicket}
                        successfunc={() => {
                            actions.fastPlayTicketActions.reset();
                            window.open('post_claim');
                        }}
                        fields={[
                            {
                                name: 'ticket1_1_1',
                                initialState: '',
                                size: '12',
                                groupNumber: 1,
                                placeholder: '',
                            },
                            {
                                name: 'ticket1_1_2',
                                initialState: '',
                                size: '14',
                                groupNumber: 2,
                                placeholder: '',
                            },
                        ]}
                    />
                </UserLockout>
            </div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterFastplayTemplate);

import React, { useState, useEffect } from 'react';
import apiClient from '../Api/api_client';
import { Carousel } from 'react-bootstrap';

import './banner_carousel_syles.scss';

// EXPECTED SUCCESSFUL API RETURN
// const car = {
//     success: true,
//     payload: [
//         {
//             name: 'carousel_one',
//             onclick: null,
//             new_window: '0',
//             alt_text: 'Carousel One',
//             size: 'large',
//             url:
//                 'https://dlxq6hk38h98r.cloudfront.net/ct/banner_5_image_3_url.jpg',
//             height: '1200',
//             width: '1600',
//         },
//         {
//             name: 'carousel_two',
//             onclick: null,
//             new_window: '0',
//             alt_text: 'Carousel Two',
//             size: 'large',
//             url:
//                 'https://dlxq6hk38h98r.cloudfront.net/ct/banner_6_image_4_url.jpg',
//             height: '1600',
//             width: '1200',
//         },
//         {
//             name: 'carousel_three',
//             onclick: null,
//             new_window: '0',
//             alt_text: 'Carousel Three',
//             size: 'large',
//             url:
//                 'https://dlxq6hk38h98r.cloudfront.net/ct/banner_7_image_5_url.jpg',
//             height: '1600',
//             width: '1200',
//         },
//     ],
// };

const BannerCarousel = ({ name }) => {
    const [carousel, setCarousel] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const getCarousel = async (myName) => {
        const car = await apiClient().getBanner({ name: myName });

        if (car && car.success) setCarousel(car);
    };

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
            getCarousel(name);
        }
    }, [name]);

    return (
        <Carousel className='overflow-hidden'>
            {carousel && carousel.payload
                ? carousel.payload.map((car, index) => {
                      return (
                          <Carousel.Item key={index} className='text-center'>
                              <a href={car.onclick} target={car.new_window ? 'blank' : ''}>
                                  <img className='img-fluid' src={car.url} alt='thing' />
                              </a>
                          </Carousel.Item>
                      );
                  })
                : ''}
        </Carousel>
    );
};

export default BannerCarousel;

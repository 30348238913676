import React, { useState } from 'react';
import upArrow from '../assets/images/arrow_up_solid.svg';
import downArrow from '../assets/images/arrow_down_solid.svg';
import './content_box_style.scss';

const CollapseId = React.createContext(0);
const CollapseShow = React.createContext(true);

const ContentBox = ({ children, show = true, variant = '', className = '', id = '' }) => {
    // the default state is open, so we look for intentionally set 'false'
    const [showing, setShowing] = useState(show);

    // if we are using a hash link, that will set focus on the box
    // if it is collapsed, we want to uncollapse it
    const handleFocus = () => {
        setShowing(true);
    };

    // we need to know if the content is hidden on the parent element (.content-box)
    // so that we can style the header with correct colors / border radii
    return (
        <div className={`content-box ${variant} ${className} ${showing ? '' : 'content-is-hidden'}`} id={id} onFocus={handleFocus}>
            <CollapseId.Provider value={Math.floor(Math.random() * 100)}>
                <CollapseShow.Provider
                    value={{
                        show: showing,
                        toggle: () => {
                            setShowing(!showing);
                        },
                    }}>
                    {children}
                </CollapseShow.Provider>
            </CollapseId.Provider>
        </div>
    );
};

const ContentCollapser = ({ children }) => {
    const id = React.useContext(CollapseId);
    const { show, toggle } = React.useContext(CollapseShow);

    // if no children are set as the link element child, use an svg arrow down/up
    return (
        <a className={`float-right collapser ${show ? 'showing' : 'hiding'}`} role='button' onClick={toggle}>
            {children && children.length ? (
                <span>{children}</span>
            ) : show ? (
                <span className='toggle-control'>
                    <img src={upArrow} className='svg-icon' />
                </span>
            ) : (
                <span className='toggle-control'>
                    <img src={downArrow} className='svg-icon' />
                </span>
            )}
        </a>
    );
};

const ContentBoxHead = ({ children }) => {
    return <div className='content-box-head'>{children}</div>;
};

const ContentBoxBody = ({ children }) => {
    const id = React.useContext(CollapseId);
    const { show } = React.useContext(CollapseShow);
    return (
        <div className={`content-box-body collapse ${show ? 'show' : ''}`} id={`collapse${id}`}>
            {children}
        </div>
    );
};

export { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser };

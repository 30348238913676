import React, { useContext, useState, useEffect } from 'react';
import { DataProvider, useData } from './DataContext';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

// This component can be used inside another component that uses a DataProvider
// This component provides the data for the children to use
const CurrentDrawingDataTemplate = ({ winner, actions }) => {
    const { state, dispatch } = useData();
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        async function fetchData() {
            if (!pageLoaded) {
                setPageLoaded(true);

                await actions.winnerActions.getCurrentDrawings();
            } else {
                dispatch({
                    type: 'assign',
                    payload: winner.currentDrawings,
                });
            }
        }
        fetchData();
    }, [winner]);

    return <></>;
};

const CurrentDrawingData = connect(mapStateToProps, mapDispatchToProps)(CurrentDrawingDataTemplate);

export { CurrentDrawingData };

////////// REDUX CURRENT DRAWINGS //////////
// {
//     type: 'winner/getCurrentDrawings',
//     payload: [{
//         "id": 5,
//         "quantum_start": 1633924800,
//         "quantum_end": 1637038799,
//         "draw_date": 1637038800,
//         "certified": false,
//         "state": "active",
//         "summarized": true,
//         "extra": null,
//         "drawing_group_id": "5",
//         "sweepstake": "$10,000 50th Anniversary Sweeps",
//         "drawing_group": "Drawing 5"
//     }, {
//         "id": 11,
//         "quantum_start": 1633924800,
//         "quantum_end": 1637038799,
//         "draw_date": 1637038800,
//         "certified": false,
//         "state": "active",
//         "summarized": true,
//         "extra": null,
//         "drawing_group_id": "11",
//         "sweepstake": "$20,000 50th Anniversary Sweeps",
//         "drawing_group": "Drawing 5"
//     }, {
//         "id": 17,
//         "quantum_start": 1633924800,
//         "quantum_end": 1637038799,
//         "draw_date": 1637038800,
//         "certified": false,
//         "state": "active",
//         "summarized": true,
//         "extra": null,
//         "drawing_group_id": "17",
//         "sweepstake": "$50,000 50th Anniversary Sweeps",
//         "drawing_group": "Drawing 5"
//     }, {
//         "id": 23,
//         "quantum_start": 1633924800,
//         "quantum_end": 1637038799,
//         "draw_date": 1637038800,
//         "certified": false,
//         "state": "active",
//         "summarized": true,
//         "extra": null,
//         "drawing_group_id": "23",
//         "sweepstake": "$100,000 50th Anniversary Sweeps",
//         "drawing_group": "Drawing 5"
//     }, {
//         "id": 29,
//         "quantum_start": 1633924800,
//         "quantum_end": 1637038799,
//         "draw_date": 1637038800,
//         "certified": false,
//         "state": "active",
//         "summarized": true,
//         "extra": null,
//         "drawing_group_id": "29",
//         "sweepstake": "Event Day Sweepstakes",
//         "drawing_group": "Drawing 5"
//     }]
// }

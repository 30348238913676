import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { Button } from 'react-bootstrap';

import { formatDate, formatDateTimeWithDayOfTheWeek as formatDateTime } from '../../utils/formatDates';
import WinnerLink from '../Sweeps/WinnerLink';

import {
    ContentBox,
    ContentBoxHead,
    ContentBoxBody,
    ContentCollapser,
} from '../ContentBox';


const RecentDrawings = ({
    winner,
    actions,
}) => {

    const [pageLoaded, setPageLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        async function fetchData() {
            if (!pageLoaded) {
                setPageLoaded(true);
                if (winner.expiredDrawings.length === 0) {
                    await actions.winnerActions.getExpiredDrawings({'page': page, 'pagesize': pageSize});
                }
            }
        }
        fetchData();
    }, []);

    const getMoreExpiredDrawings = async () => {
        setPageLoaded(true);
        await actions.winnerActions.getExpiredDrawings({'page': page+1, 'pagesize': pageSize});
        await setPage(page+1);
        setPageLoaded(false);
    }

    const dataFormatted = winner.expiredDrawings.drawings ? winner.expiredDrawings.drawings.map((val) => {
        return {
            ...val,
            promotion: val.sweepstake,
            entry_period: `${new Date(
                val.quantum_start * 1000
            ).toDateString()} - ${new Date(
                val.quantum_end * 1000
            ).toDateString()}`,
            drawing_date: (
                <>
                    <div>
                        {new Date(
                            val.draw_date * 1000
                        ).toDateString()}
                    </div>
                    <div>
                        <WinnerLink selected={val} />
                    </div>
                </>
            ),
            has_winners: val.certified,
        };
    }) : [];


    const desktopDrawEntries = (drawings) => {
        if (!drawings || drawings.length === 0) {
            return (
                <tbody>
                    <tr key="0">
                        <td colSpan="4">
                            <em>
                                { !pageLoaded ? 'No drawings' : '. . .' }
                            </em>
                        </td>
                    </tr>
                </tbody>
            );
        } else {
            // note we are spreading entries into a new array so we don't mutate the
            // entries array in state
            return (
                <tbody>
                    {[...drawings].map(
                        ({
                            id,
                            sweepstake,
                            sweepstake_id,
                            quantum_start,
                            quantum_end,
                            entry_count,
                            draw_date,
                            certified,
                        }) => {
                            return (
                                <tr key={id}>
                                    <td><a href={`/promotion?id=${sweepstake_id}`}>{sweepstake}</a></td>
                                    <td>{ formatDateTime(quantum_start) } - { formatDateTime(quantum_end) }</td>
                                    <td>
                                        <div>{ formatDate(draw_date) }</div>
                                        { certified && <div>
                                            <WinnerLink selected={{id}} />
                                        </div> }
                                    </td>
                                </tr>
                            );
                        }
                    )}
                </tbody>
            );
        }
    };

    const desktopEntrylist = (drawings) => {
        return (
            <table
                id="entryTable"
                className="table entry-table d-none d-md-table text-center"
            >
                <thead>
                    <tr>
                        <th>PROMOTION</th>
                        <th>ENTRY PERIOD</th>
                        <th>DRAWING DATE</th>
                    </tr>
                </thead>
                {desktopDrawEntries(drawings)}
            </table>
        );
    };

    const mobileEntrylist = (drawings) => {
        if (!drawings || drawings.length === 0) {
            return (
                <div className="d-md-none stacked-rewards-container">
                    <div className="row">
                        <div className="col">Drawings</div>
                        <div className="col">
                            <em>
                                { !pageLoaded ? 'No drawings.' : '. . .' }
                            </em>
                        </div>
                    </div>
                </div>
            );
        } else {
            // note we are spreading entries into a new array so we don't mutate the
            // entries array in state
            return (
                <div className="d-md-none stacked-rewards-container">
                    {[...drawings].map(
                        ({
                            id,
                            sweepstake,
                            sweepstake_id,
                            quantum_start,
                            quantum_end,
                            draw_date,
                            certified,
                        }) => {
                            return (
                                <div className="row" key={id}>
                                    <div className="col">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Promotion
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        <a href={`/promotion?id=${sweepstake_id}`}>{sweepstake}</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Entry Period
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        { formatDateTime(quantum_start) } - { formatDateTime(quantum_end) }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            Drawing Date
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        <div>{ formatDate(draw_date) }</div>
                                                        { certified && <div>
                                                            <WinnerLink selected={{id}} />
                                                        </div> }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            );
                        }
                    )}
                </div>
            );
        }
    };

    // if there are more rewards, show the link for more
    const loadMoreLink = (drawings) => {
        return drawings.drawings && Array.isArray(drawings.drawings) && drawings.drawings.length > 0 && drawings.drawings.length < drawings.total ? (
            <div className='text-center my-4'>
                <Button variant='outline-secondary' className='btn theme-btn theme-outline' onClick={() => getMoreExpiredDrawings()}>
                    View More
                </Button>
            </div>
        ) : null;
    };

    //? For debug: console.log('📃 Expired Drawings: ', winner.expiredDrawings)
    //? For debug: console.log('🎁 Recent Drawing:', dataFormatted);

    if (dataFormatted) {
        return (
            <ContentBox variant='theme-blue' className='my-4'>
                <ContentBoxHead>
                    Pending and Recent Drawings
                    <ContentCollapser />
                </ContentBoxHead>
                <ContentBoxBody>
                    <div className='row'>
                        <div className='col'>
                            {desktopEntrylist(dataFormatted)}
                            {mobileEntrylist(dataFormatted)}
                            {loadMoreLink(winner.expiredDrawings)}
                        </div>
                    </div>
                </ContentBoxBody>
            </ContentBox>
        );
    } else return <></>;
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentDrawings);

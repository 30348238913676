import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const ticketCheckInfoShape = {
    ExtendedErrors: PropTypes.any, // need more info for what this can be
    Message: PropTypes.string,
    ResultCode: PropTypes.any, // not sure if this is confined to integers
    TicketDetails: PropTypes.shape({
        CashAmount: PropTypes.number,
        GameId: PropTypes.any, // not sure if this is confined to integers
        GameName: PropTypes.string,
        GameType: PropTypes.oneOf(['Draw', 'Instant']).isRequired,
        IsPromotionEligible: PropTypes.string,
        Prizes: PropTypes.arrayOf(
            PropTypes.shape({
                Type: PropTypes.string.isRequired,
                ValueAmount: PropTypes.number.isRequired,
            })
        ),
        TicketData: PropTypes.string,

        // TicketStatus should be changed to oneOf for the
        // known values but i don't know them yet
        TicketStatus: PropTypes.string,
    }),
};

const SuccessHeading = () => {
    return <h2>Ticket Successfully Entered</h2>;
};

const NotSuccessHeading = () => {
    return <h2>Ticket Not Entered</h2>;
};

export const SuccessfulEntry = () => {
    return (
        <>
            {SuccessHeading()}
            <div>Congratulations! Your ticket entry qualified for the following promotions.</div>
            <Redirect to='/post-claim' />
        </>
    );
};

SuccessfulEntry.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const NoCurrentPromotions = () => {
    return (
        <>
            {NotSuccessHeading()}
            <div>This ticket does not qualify for this promotion.</div>
        </>
    );
};

NoCurrentPromotions.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const NoCurrentPromotionsOrOverLimit = () => {
    return (
        <>
            {NotSuccessHeading()}
            <div>The ticket entered either does not qualify for this promotion or you have exceeded your daily limit.</div>
        </>
    );
};

NoCurrentPromotionsOrOverLimit.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const ServiceDisabled = () => {
    return (
        <>
            {NotSuccessHeading()}
            <div>Ticket Entry not available at this time.</div>
        </>
    );
};

ServiceDisabled.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const ErrorSubmitting = () => {
    return (
        <>
            {NotSuccessHeading()}
            <div>The entry you submitted is not recognized as a qualified entry. Please check the numbers and try again.</div>
        </>
    );
};

ErrorSubmitting.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const ErrorSubmittingContactLottery = () => {
    return (
        <>
            {NotSuccessHeading()}
            <div>There was an error submitting this ticket. Please contact the lottery.</div>
        </>
    );
};

ErrorSubmittingContactLottery.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const OthersNotQualified = () => {
    return (
        <>
            {NotSuccessHeading()}
            <div>The entry you submitted is not recognized as a qualified entry.</div>
        </>
    );
};

OthersNotQualified.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const DailyEntryExceeded = () => {
    return (
        <>
            {NotSuccessHeading()}
            <div>You have reached your daily limit for entering tickets. Please come back tomorrow to enter more tickets.</div>
        </>
    );
};

DailyEntryExceeded.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const TicketAlreadyEntered = () => {
    return (
        <>
            {NotSuccessHeading()}
            <div>This ticket has already been submitted.</div>
        </>
    );
};

TicketAlreadyEntered.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const TicketSubmittedNotValid = () => {
    return (
        <>
            {NotSuccessHeading()}
            <div>The ticket you submitted is not valid. Please check the numbers and try again.</div>
        </>
    );
};

TicketSubmittedNotValid.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const MaxUnsuccessfulReached = () => {
    return (
        <>
            {NotSuccessHeading()}
            <div>You have exceeded the maximum number of unsuccessful entry attempts. You may try again in one (1) hour.</div>
        </>
    );
};

MaxUnsuccessfulReached.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

// this component is used for passing through a message
export const MessagePassThrough = ({ ticketCheckInfo = {} }) => {
    const { TicketStatus } = ticketCheckInfo;
    return <div>{TicketStatus}</div>;
};

MessagePassThrough.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

// this component is used as a fallback when
// some value comes over that we haven't defined
export const FallbackComponent = () => {
    return (
        <>
            {NotSuccessHeading()}
            <div>The entry you submitted is not recognized as a qualified entry. Please check the numbers and try again.</div>
        </>
    );
};

// feels weird defining it but this will be passed
// to FallbackComponent _and ignored_
FallbackComponent.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

// CheckTicketResponse will be passed the result of a
// check ticket call.  It will map the TicketStatus
// property to one of the above response components
// or fall back to FallbackComponent if we don't have a response

// NoCurrentPromotions: This ticket does not qualify for this promotion.
// ErrorSubmitting: There was an error submitting this ticket. Please try again.
// TicketSubmittedNotValid: The ticket you submitted is not valid. Please check the numbers and try again.
// ErrorSubmittingContactLottery: There was an error submitting this ticket. Please contact the lottery.
// TicketAlreadyEntered: This ticket has already been submitted.

export const CheckTicketResponse = ({ ticketCheckInfo = {} }) => {
    const components = {
        WINNER: NoCurrentPromotions,
        NOT_A_WINNER: SuccessfulEntry,
        DAILY_ENTRY_EXCEEDED: DailyEntryExceeded,
        // RESULTS_NOT_IN: NoCurrentPromotions,
        // PAID: NoCurrentPromotions,
        // EXPIRED: NoCurrentPromotions,
        // ON_HOLD: NoCurrentPromotions,
        // INSTANT_WINNER_DRAWS_REMAINING: NoCurrentPromotions,
        // CLAIMED: NoCurrentPromotions,
        // NOT_WINNER_DRAWS_REMAINING: NoCurrentPromotions,
        CLAIM_AT_LOTTERY: NoCurrentPromotions,
        // CANCELED: NoCurrentPromotions,
        ALREADY_PAID: NoCurrentPromotions,
        // TICKET_COMPLETELY_EXPIRED: NoCurrentPromotions,
        // UNABLE_TO_CASH_CLAIM_AMT_TOO_BIG_TOO_LOW: NoCurrentPromotions,
        // NOT_ABLE_TO_PAY: NoCurrentPromotions,
        ALREADY_CLAIMED: NoCurrentPromotions,
        // CANNOT_PAY_YET: NoCurrentPromotions,
        // CALL_HOTLINE: NoCurrentPromotions,
        // NOT_A_WINNER_YET: NoCurrentPromotions,
        // PAYMENT_DEFERRED: NoCurrentPromotions,
        // PAID_BY_YOU: NoCurrentPromotions,
        // PAID_BY_OTHER: NoCurrentPromotions,
        // CLAIMED_BY_YOU: NoCurrentPromotions,
        // CLAIMED_BY_OTHER: NoCurrentPromotions,
        // ERROR_TERMINAL_IN_TRAINING_MODE: ErrorSubmitting,
        // EXCEEDS_CASHING_LIMIT: NoCurrentPromotions,
        // TICKET_TOO_OLD: NoCurrentPromotions,
        // TICKET_EXPIRED: NoCurrentPromotions,
        // TICKET_NOT_ON_FILE: TicketSubmittedNotValid,
        // TICKET_PREVIOUSLY_CANCELLED: NoCurrentPromotions,
        // DRAW_NOT_CLOSED_YET: NoCurrentPromotions,
        // VALIDATE_MANUALLY: NoCurrentPromotions,
        // PAID_BY_EFT: NoCurrentPromotions,
        // NOT_CLAIMED_YET: NoCurrentPromotions,
        // VALIDATION_REJECTED_CANCELLED: TicketSubmittedNotValid,
        // HIGH_WINNER: NoCurrentPromotions,
        // WINNER_AGENT_CANNOT_PAY: NoCurrentPromotions,
        // CONTACT_LOTTERY: NoCurrentPromotions,
        // MUST_GET_FREE_TICKET_FIRST: NoCurrentPromotions,
        // TOO_MANY_TRANSACTIONS_IN_SESSION: NoCurrentPromotions,
        // INCORRECT_CLAIM_CARD_SESSION: NoCurrentPromotions,
        // INVALID_TICKET_SECURITY_INFO: TicketSubmittedNotValid,
        // INVALID_NOT_A_SELLING_AGENT: ErrorSubmitting,
        // NOT_A_SELLING_RETAILER: ErrorSubmitting,
        SERVICE_DISABLED: ServiceDisabled,
        // FUNCTION_SUPPRESSED: ErrorSubmitting,
        // SCANNING_ERROR: ErrorSubmitting,
        UNKNOWN: ServiceDisabled,
        DUPLICATE: TicketAlreadyEntered,
        NOT_ELIGIBLE_FOR_PROMOTION: NoCurrentPromotions,
        NO_PROMOTION_MATCH: NoCurrentPromotionsOrOverLimit,
        // VALID_TICKET: SuccessfulEntry,
        // INVALID_TICKET: NoCurrentPromotions,
        REQUEST_REJECTED_SEE_RETAILER: NoCurrentPromotions,
        GAME_CLOSED: NoCurrentPromotions,
        OTHERS: OthersNotQualified,
        'INVALID PIN': OthersNotQualified,
    };

    const getMessageComponent = (checkInfo) => {
        return checkInfo.TicketStatus && components[checkInfo.TicketStatus.toUpperCase()]
            ? components[checkInfo.TicketStatus.toUpperCase()]
            : FallbackComponent;
    };

    const Message = getMessageComponent(ticketCheckInfo);

    return (
        <>
            <Message ticketCheckInfo={ticketCheckInfo} />
        </>
    );
};

CheckTicketResponse.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

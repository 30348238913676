import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
// import SEO from '../components/seo';

const HomeTemplate = ({ user, config, actions, layoutData, pageData }) => {
    return (
        <Layout user={user} config={config} actions={actions} mainMenu={layoutData} pageData={pageData}>
            <div className='home primary-page-content'>...</div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeTemplate);

import React, { useContext, useState, useEffect } from 'react';
import { DataProvider, useData } from '../DataProviders/DataContext';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import { formatDateWithDayOfTheWeek as formatDate } from '../../utils/formatDates';

const FormatNextDrawingsTemplate = ({ winner, actions }) => {
    const { state, dispatch } = useData();
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(async () => {
        if (state.data.length > 0 && !pageLoaded) {
            setPageLoaded(true);
            dispatch({
                type: 'assign',
                payload:
                    state.data.map((val) => {
                        return {
                            ...val,
                            promotion: val.sweepstake,
                            drawing_date: (
                                <>
                                    The Next Drawing is on{' '}
                                    {formatDate(val.draw_date)}
                                </>
                            ),
                            has_winners: val.certified,
                        };
                    }) || [],
            });
        }
    }, [state]);

    return <></>;
};

const FormatNextDrawings = connect(
    mapStateToProps,
    mapDispatchToProps
)(FormatNextDrawingsTemplate);

export { FormatNextDrawings };

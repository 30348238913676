import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

const TicketFormTemplate = ({
    ticket = { success: false },
    createfunc = () => {},
    successfunc = () => {},
    children,
    fields,
    actions,
}) => {
    let initialState = {};

    fields = fields || [
        {
            name: 'ticket1_1_1',
            initialState: '',
            size: '6',
            placeholder: '',
        },
        {
            name: 'ticket1_1_2',
            initialState: '',
            size: '3',
            placeholder: '',
        },
        {
            name: 'ticket1_1_3',
            initialState: '',
            size: '3',
        },
        {
            name: 'ticket1_1_4',
            initialState: '',
            size: '10',
        },
        {
            name: 'ticket1_1_5',
            initialState: '',
            size: '20',
        },
    ];

    fields.forEach((item) => {
        initialState[item.name] = item.initialState;
    });

    const [formState, setFormState] = useState(initialState);

    const [disabled, setDisabled] = useState(false);

    const [entryMethod, setEntryMethod] = useState('manual');

    useEffect(() => {
        clearIfSuccess();
    }, [ticket]);

    // if the form submit was a success, we clear the form
    const clearIfSuccess = async () => {
        if (ticket.success) {
            setFormState(initialState);
            actions.drawTicketActions.reset();
            window.open('post_claim');
        }
    };

    // fire the submission
    const handleSubmit = async (event) => {
        setDisabled(true);

        event.preventDefault();
        // await createfunc(formState);

        const bonusFields = { entry_method: entryMethod };

        const payload = { ...formState, ...bonusFields };

        //? for debug:
        // console.log('normal form state', formState);
        // console.log('bonus fields', bonusFields);
        // console.log('🔥 payload', payload);
        await actions.drawTicketActions.enterDrawTicket(payload);

        setDisabled(false);
    };

    // if the form field changes, we will change the value in the store and
    // also check if the next field must be focused
    const handleChange = function (event) {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const thisField = fields.filter((field) => {
            return field.name == name;
        })[0];

        value =
            thisField && thisField.onChange ? thisField.onChange(value) : value;
        target.value = value;

        setFormState({ ...formState, [name]: value });

        autotab(target, target.dataset.next);
    };

    // rehash of old autotab functionality from PA
    const autotab = (current, nex) => {
        var next = document.getElementById(nex);

        if (
            !!nex &&
            !!next &&
            !!current &&
            current.getAttribute &&
            current.value.length === parseInt(current.getAttribute('maxlength'))
        ) {
            next.focus();
        }
    };

    const renderContainer = (children, child, i, found) => {
        if (child.type == 'input') {
            found.count++;
            // console.log(fields[found.count]?.name);
            if (fields[found.count - 1]) {
                return React.cloneElement(child, {
                    onChange: handleChange.bind(this),
                    type: fields[found.count - 1].type || 'text',
                    name: child.props.name || fields[found.count - 1].name,
                    id: child.props.name || fields[found.count - 1].name,
                    'data-next': child.props.next || fields[found.count]?.name, // counts 1 up because its the next one
                    value: formState[
                        child.props.name || fields[found.count - 1].name
                    ],
                    placeholder:
                        child.props.placeholder ||
                        fields[found.count - 1].placeholder,
                    size: child.props.size || fields[found.count - 1].size,
                    maxLength: child.props.size || fields[found.count - 1].size,
                });
            } else return null;
        } else if (child.type == 'div') {
            const children2 = child.props.children;
            // console.log(children2);
            const wrappedChildren = React.Children.map(
                children2,
                (child2, i2) => {
                    return renderContainer(children2, child2, i2, found);
                }
            );
            return <div {...child.props}>{wrappedChildren}</div>;
        } else {
            return child;
        }
    };

    const renderChildren = () => {
        //gotta keep track of all of the inputs we want to name
        let found = { count: 0 };
        // console.log(children);

        return React.Children.map(children, (child, i) => {
            if (i < 1 || i >= children.length - 1) return;
            return renderContainer(children, child, i, found);
        });
    };

    return <form onSubmit={handleSubmit}>{renderChildren()}</form>;
};

const TicketForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(TicketFormTemplate);

export { TicketForm };

import React, { Component } from 'react';
import { ErrorMessage } from 'sg-ui-components';

class Error extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success: props.ticket.success,
            message: props.ticket.message,
        };
    }

    componentDidMount() {
        // this.props.actions.igt24TicketActions.reset();
        this.props.actions.drawTicketActions.reset();
        this.props.actions.fastPlayTicketActions.reset();
        this.props.actions.scratchTicketActions.reset();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.ticket.message !== this.props.ticket.message) {
            this.setState({
                success: this.props.ticket.success,
                message: this.props.ticket.message,
            });
        }
    }

    render() {
        if (this.state.message && this.state.message != 'success') {
            const isSuccess = this.state.success ? 'alert-success' : 'alert-danger';

            return (
                <div className={'col ' + isSuccess + ' alert text-center entry-alert'} role='alert'>
                    <ErrorMessage mode='return' code={this.state.message} collection='data.messages.ticketErrorMessages.jsonBlock' />
                </div>
            );
        } else {
            return null;
        }
    }
}

export default Error;

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { configSlice, configActions } from './Stores/ConfigStore';
import { pageDataSlice, pageDataActions } from './Stores/PageDataStore';
import { checkTicketActions, checkTicketSlice } from './Stores/CheckTicketStore';
import { scratchTicketActions, scratchTicketSlice } from './Stores/ScratchTicketStore';
import { fastPlayTicketActions, fastPlayTicketSlice } from './Stores/FastPlayTicketStore';
import { entrySlice, entryActions } from './Stores/EntryStore';
import { loadingSlice, loadingActions } from './Stores/LoadingStore';
import { feedbackSlice, feedbackActions } from './Stores/FeedbackStore';
import { feedbackCategoriesSlice, feedbackCategoriesActions } from './Stores/FeedbackCategoriesStore';
import { revealTokenSlice, revealTokenActions } from './Stores/RevealTokenStore';
import { notificationSlice, notificationActions } from './Stores/NotificationStore';
import { achievementSlice, achievementActions } from './Stores/AchievementStore';
import { pointSlice, pointActions } from './Stores/PointsStore';
import { activitySlice, activityActions } from './Stores/ActivityStore';
import { rewardSlice, rewardActions } from './Stores/RewardStore';
import { promotionSlice, promotionActions } from './Stores/PromotionStore';
import { cmsSourceActions, cmsSourceSlice } from './Stores/CMS_source';
import { cmsSourceFirebaseActions, cmsSourceFirebaseSlice } from 'sg-ui-components';
import { bonusingSlice, bonusingActions } from 'sg-ui-components';
import { drawTicketSlice,drawTicketActions } from 'sg-ui-components';
import { userSlice, userActions } from 'sg-ui-components';
import { sweepSlice, sweepActions } from 'sg-ui-components';
import { winnerSlice, winnerActions } from 'sg-ui-components';
import { optinsSlice, optinActions } from 'sg-ui-components';

import apiClient from './apiClient';
import validateTicketResponse from './validateTicketResponse';

export const createStore = () => {
    return configureStore({
        reducer: combineReducers({
            config: configSlice.reducer,
            user: userSlice.reducer,
            pageData: pageDataSlice.reducer,
            checkTicket: checkTicketSlice.reducer,
            drawTicket: drawTicketSlice.reducer,
            scratchTicket: scratchTicketSlice.reducer,
            fastPlayTicket: fastPlayTicketSlice.reducer,
            entry: entrySlice.reducer,
            loading: loadingSlice.reducer,
            winner: winnerSlice.reducer,
            feedback: feedbackSlice.reducer,
            feedbackCategories: feedbackCategoriesSlice.reducer,
            revealToken: revealTokenSlice.reducer,
            notification: notificationSlice.reducer,
            achievement: achievementSlice.reducer,
            points: pointSlice.reducer,
            bonusing: bonusingSlice.reducer,
            activity: activitySlice.reducer,
            reward: rewardSlice.reducer,
            promotion: promotionSlice.reducer,
            optins: optinsSlice.reducer,
            sweep: sweepSlice.reducer,
            cmsSource: cmsSourceSlice.reducer,
            cmsSourceFirebase: cmsSourceFirebaseSlice.reducer,
        }),
    });
};

export const actionsStore = (dispatch) => {
    return {
        configActions: configActions(dispatch),
        userActions: userActions(dispatch, apiClient),
        pageDataActions: pageDataActions(dispatch),
        checkTicketActions: checkTicketActions(dispatch),
        drawTicketActions: drawTicketActions(dispatch, apiClient, validateTicketResponse),
        optinsActions: optinActions(dispatch, apiClient),
        scratchTicketActions: scratchTicketActions(dispatch),
        fastPlayTicketActions: fastPlayTicketActions(dispatch),
        entryActions: entryActions(dispatch),
        loadingActions: loadingActions(dispatch),
        winnerActions: winnerActions(dispatch,apiClient),
        feedbackActions: feedbackActions(dispatch),
        feedbackCategoriesActions: feedbackCategoriesActions(dispatch),
        revealTokenActions: revealTokenActions(dispatch),
        notificationActions: notificationActions(dispatch),
        achievementActions: achievementActions(dispatch),
        pointActions: pointActions(dispatch),
        activityActions: activityActions(dispatch),
        rewardActions: rewardActions(dispatch),
        promotionActions: promotionActions(dispatch),
        sweepActions: sweepActions(dispatch, apiClient),
        bonusingActions: bonusingActions(dispatch, apiClient),
        cmsSourceActions: cmsSourceActions(dispatch),
        cmsSourceFirebaseActions: cmsSourceFirebaseActions(dispatch),
    };
};

export const mapStateToProps = ({
    config,
    user,
    pageData,
    checkTicket,
    bonusing,
    drawTicket,
    scratchTicket,
    fastPlayTicket,
    entry,
    loading,
    winner,
    feedback,
    feedbackCategories,
    revealToken,
    notification,
    achievement,
    points,
    activity,
    optins,
    reward,
    promotion,
    sweep,
    cmsSource,
    cmsSourceFirebase,
}) => ({
    config,
    user,
    pageData,
    checkTicket,
    drawTicket,
    scratchTicket,
    fastPlayTicket,
    entry,
    optins,
    loading,
    winner,
    feedback,
    feedbackCategories,
    revealToken,
    notification,
    achievement,
    points,
    bonusing,
    activity,
    reward,
    promotion,
    sweep,
    cmsSource,
    cmsSourceFirebase,
});
export const mapDispatchToProps = (dispatch) => {
    return {
        actions: actionsStore(dispatch),
    };
};

module.exports = {
    title: 'Whitelabel Ensemble',
    promotion_id: '',
    jurisdiction: 'CT',
    promotion: 'ensemble_site',
    appUrl: '',
    lottoUrl: '',
    prodhost: '',
    uathost: '',
    feedbackIssueZone: 'SCBZ',
    defaultLoginLink: `/login`,
    drawTicketLength: '19',
    drawFields: [
        { name: 'field1', placeholder: 'xxxxxxxxxxxxxxxxxxx', size: '19', value: '' },
    ],
    badTicketInputReplacement: /[o,O]/g, // Regex to remove bad input from ticket fields.
    /*
    Entry Number Format: GGG-BBBBB-X-TTT-XX
    Entry Input: 3 digit game number + 5 digit pack number + 1 digit check digit + 3 digit ticket number + 2 digit check digit
    */
    scratchTicketLengthRegex: '^[0-9]{26}$',
    scratchTicketLength: 26,
    scratchTicketFields: {
        gameNumberFields: [
            {
                name: 'gameNum',
                placeholder: 'GGG',
                size: '3',
                value: '',
                dataindex: '0',
                side: 'back',
            },
        ],
        fieldGroups: [
            {
                validRanges: [['000', '999']],
                frontNumberFields: [
                    {
                        name: 'validation',
                        placeholder: 'VVVVVVVVVVVV',
                        size: '12',
                        value: '',
                        dataindex: '5',
                        side: 'front',
                    },
                ],
                backNumberFields: [
                    {
                        name: 'packNum',
                        placeholder: 'BBBBB',
                        size: '5',
                        value: '',
                        dataindex: '1',
                        side: 'back',
                    },
                    {
                        name: 'checkDigit',
                        placeholder: 'X',
                        size: '1',
                        value: '',
                        dataindex: '2',
                        side: 'back',
                    },
                    {
                        name: 'ticketNum',
                        placeholder: 'TTT',
                        size: '3',
                        value: '',
                        dataindex: '3',
                        side: 'back',
                    },
                    {
                        name: 'endCheckDigits',
                        placeholder: 'XX',
                        size: '2',
                        value: '',
                        dataindex: '4',
                        side: 'back',
                    },
                ],
            },
        ],
    },

    getLoginLink: (app, redirect) => (app && redirect ? `` : '/login'),
    defaultRegisterLink: '/register',
    getRegisterLink: (app, redirect) => (app && redirect ? `` : this.defaultRegisterLink),
    getLogoutDestination: (hostURL, ptaLogout) => (ptaLogout != undefined ? ptaLogout : hostURL + '/home'),
    forgot: '/users/forgot',
    cms_url: 'https://ctensembledev.cms-dev-web1.scientificgames.com',
    cms_cusotomer_url: 'https://ctensemblecustomer.cms-dev-web1.scientificgames.com',
    nyxUrlsUrl: 'https://lottery182205:palottery@www-u69.nyxop.net/api/navigation/level/top',
    resolveCurrency: (name) => {
        const currencyExchange = [
            {
                name: 'sweepstake',
                displayName: 'sweepstake',
                showQuantity: true,
                isPence: false,
                resolve: (amount) => {
                    return amount;
                },
            },
            {
                name: 'lotteryproducts',
                displayName: 'lotteryproducts',
                showQuantity: false,
                isPence: false,
                resolve: (amount) => {
                    return '';
                },
            },
            {
                name: 'promocash',
                displayName: 'promocash',
                showQuantity: true,
                isPence: true,
                resolve: (amount) => {
                    const amt = amount / 100;
                    return new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }).format(amt);
                },
            },
            {
                name: 'default',
                displayName: 'default',
                showQuantity: true,
                isPence: false,
                resolve: (amount) => {
                    return amount;
                },
            },
        ];

        return currencyExchange.filter((item) => {
            return item.name === name || item.name === 'default';
        })[0];
    },
    apiClientConfig: {
        appUrl: '',
        routes: {
            getConfig: 'ctlEnsemble.getConfig',
            login: 'ctlEnsemble.login',
            logout: 'ctlEnsemble.logout',
            checkTicket: 'ctlEnsemble.checkTicket',
            enterDrawTicket: 'ctlEnsemble.enterTicket',
            enterScratchTicket: 'ctlEnsemble.enterTicket',
            enterFastPlayTicket: 'ctlEnsemble.enterTicket',
            getEntries: 'scbzApi.getEntries',
            getPlayerData: 'ctlEnsemble.getPlayerData',
            setEntryFlashGameFlag: 'scbzApi.setEntryFlashGameFlag',
            update: 'ctlEnsemble.update',
            register: 'ctlEnsemble.register',
            verify: 'ctlEnsemble.verify',
            confirm: 'ctlEnsemble.confirm',
            confirmResend: 'ctlEnsemble.confirmResend',
            winners: 'ctlEnsemble.winners',
            postFeedback: 'ctlEnsemble.postFeedback',
            getFeedbackCategories: 'ctlEnsemble.getFeedbackCategories',
            forgotPassword: 'ctlEnsemble.forgotPassword',
            recoverPassword: 'ctlEnsemble.recoverPassword',
            getGame: 'fauxScbzApi.getGame',
            getGameOver: 'fauxScbzApi.getGameOver',
            getAchievements: 'ctlEnsemble.getAchievements',
            getPoints: 'ctlEnsemble.getPoints',
            getActivity: 'ctlEnsemble.getActivity',
            getReward: 'ctlEnsemble.getReward',
            getPromotions: 'ctlEnsemble.getPromotions',
            getPlayerPromotions: 'ctlEnsemble.getPlayerPromotions',
            getSweeps: 'ctlEnsemble.getSweeps',
            getSweepDetails: 'mrbEnsemble.getSweepDetails',
            allocateEntries: 'mrbEnsemble.allocateEntries',
            getActiveSweeps: 'ctlEnsemble.getActiveSweeps',
            getPlayerAllocatableEntries: 'mrbEnsemble.getPlayerAllocatableEntries',
            getCurrentSweeps: 'ctlEnsemble.getCurrentSweeps',
            getExpiredSweeps: 'ctlEnsemble.getExpiredSweeps',
            getDashboard: 'mrbEnsemble.getDashboard',
            getRevealToken: 'ctlEnsemble.getRevealToken',
            getBanner: 'ctlEnsemble.getBanner',
            expiredDrawings: 'ctlEnsemble.recentDrawings',
            currentDrawings: 'ctlEnsemble.currentDrawings',
            nextDrawings: 'ctlEnsemble.nextDrawings',
            getAllOptins: 'mrbEnsemble.getAllOptins',
            getOptins: 'mrbEnsemble.getOptins',
            getOptinsByCategory: 'mrbEnsemble.getOptinsByCategory',
            optinSubscribe: 'mrbEnsemble.optinSubscribe',
            optinUnsubscribe: 'mrbEnsemble.optinUnsubscribe',
        },
    },
};

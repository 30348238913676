import { createSlice } from '@reduxjs/toolkit';
import apiClient from '../Api/api_client';
import { loadingSlice } from './LoadingStore';
import { userSlice } from './UserStore';

export const defaultActivityState = {
    lastRequested: null, // possibly use this to trigger reloading?
    requestError: false,
    pageSize: 5,
    page: 0,
    total: 0,
    activities: [],
};

// REDUCERS
// basic actions that affect this object only

const getActivityReducer = (state, action) => {
    const { activities: newActivities, page, total } = action.payload;

    if (newActivities) {
        // TODO: There's a bug in the API that causes more results than expected to be returned, truncate it for now
        if (newActivities && newActivities.length > state.pageSize) {
            newActivities.length = state.pageSize;
        }

        // Merge newActivities in existing activities making sure that they're slotted in the
        // right place (potentially overwriting existing records if the API messed up)
        const activities = [...state.activities];
        activities.length = state.pageSize * (page - 1);
        newActivities && activities.push(...newActivities);

        return {
            ...state,
            lastRequested: Date.now(),
            activities,
            page,
            total,
        };
    } else {
        return state;
    }
};

const clearActivityReducer = (state, action) => {
    return defaultActivityState;
};

const setRequestErrorReducer = (state, action) => {
    return {
        ...state,
        lastRequested: Date.now(),
        requestError: true,
    };
};

export const activitySlice = createSlice({
    name: 'activity',
    initialState: defaultActivityState,
    reducers: {
        getActivities: getActivityReducer,
        clearActivity: clearActivityReducer,
        setRequestError: setRequestErrorReducer,
    },
    extraReducers: {
        [userSlice.actions.logout]: (state) => {
            return defaultActivityState;
        },
        // [entryFormSlice.actions.setEntryResponse]: state => {
        //     return defaultActivityState;
        // },
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const getActivities = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'getEntries' }));
    const result = await apiClient().getActivity(payload);
    dispatch(activitySlice.actions.getActivities(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'getEntries' }));
};

//actions that get exported to the views
export const activityActions = (dispatch) => {
    return {
        getActivities: (payload) => getActivities(dispatch, payload),
    };
};

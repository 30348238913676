import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { PasswordField } from '../RegisterForm/password_field';
import isBrowser from '../../utils/isBrowser';
import { validatePassword, validatePasswordDetailed } from '../../validationRules';
import { ErrorMessage,DisplayContent } from 'sg-ui-components';

import {
    ContentBox,
    ContentBoxHead,
    ContentBoxBody,
    ContentCollapser,
} from '../ContentBox';


const RecoverPassword = ({
    loading,
    user,
    actions,
}) => {

    //* Retrieve ID from url params
    const queryString = isBrowser() ? window.location.search : '';
    const urlParams = new URLSearchParams(queryString);
    const queryParams = {
        passcode: urlParams.get('passcode'),
        mode: urlParams.get('mode'),
    };

    const initialStepFields = {
        password: '',
        confirm: '',
    };

    const schema = yup.object().shape({
        password: validatePassword,

        confirm: yup
            .string()
            .required('Password Confirmation is required')
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState('');
    const [passwordStatus, setPasswordStatus] = useState('initial');
    const [localErrors, setLocalErrors] = useState({});

    // if the form field changes, we will change the value in the store and
    const handleChange = (event) => {
        const target = event.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value.trimStart();
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });

        //* Password inner change
        if (name === 'password') {
            checkPassword(value);
        }
    };


    //  Inline validation for Password to make sure that it matches the password Schema
    const checkPassword = async (password) => {
        const passwordSchema = validatePasswordDetailed;

        const valid = await passwordSchema.isValid(password);

        if (valid) {
            setPasswordStatus('valid');
        } else {
            await passwordSchema.validate(password, {abortEarly: true}).catch(function (err) {
                setPasswordStatus(err.message);
            });
        }
    };

    const passwordStatusForm = (status) => {
        if (status === 'initial') return null;

        return <>
            { status === 'valid' ?
                <div className='alert alert-success p-2 my-2' role='alert'>
                    Your password matches the criteria.
                </div>
                :
                <div className='alert alert-danger p-2 my-2' role='alert'>
                    {status}
                </div>
            }
        </>
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(stepFields);

        if (valid) {
            await actions.loadingActions.setLoading({ action: 'recoverPassword' });

            setError('');
            setLocalErrors({});

            await actions.userActions.updateSection({ section: 'recover-password', status: 'initial' });

            const payloadData = {
                password: stepFields.password,
                confirm: stepFields.confirm,
                passcode: queryParams.passcode,
            }

            await actions.userActions.recoverPassword(payloadData);

        } else {
            await schema
                .validate(stepFields, { abortEarly: false })
                .catch(function (err) {
                    setLocalErrors(getValidationErrors(err));
                    setError(err.message);
                });
        }
    };

    const getValidationErrors = (err) => {
        const validationErrors = {};

        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);

        return validationErrors;
    };

    //* Passcode validation
    if (!queryParams.passcode) {
        window.location.href = '/forgot-password';
        return null;
    }

    //* reset success
    if ((user.updateSection.section === 'recover-password') && (user.updateSection.status === 'success')) {
        return (
            <div className='login-container'>
                <div className="my-5"/>
                <ContentBox variant="theme-purple">
                    <ContentBoxHead>
                        RECOVER MY PASSWORD
                    </ContentBoxHead>
                    <ContentBoxBody>
                        <div className="registration-step">
                            <div className="inner-step text-center">
                                Congratulations, your password has been updated.

                                <a href="/login" className="btn theme-btn btn-block theme-gray my-4">
                                    Continue to Login
                                </a>
                            </div>
                        </div>
                    </ContentBoxBody>
                </ContentBox>
            </div>
        );
    }

    return (
            <div className='login-container'>
                <div className="my-5"/>
                <ContentBox variant="theme-purple">
                    <ContentBoxHead>
                        RESET MY PASSWORD
                    </ContentBoxHead>
                    <ContentBoxBody>
                        <div className="registration-step">
                            <div className="inner-step">

                                {error ? (
                                    <div
                                        className="alert alert-danger text-center"
                                        role="alert"
                                    >
                                        {Object.values(localErrors).map((err, index) => {
                                            return <p key={index}>{err}</p>;
                                        })}
                                    </div>
                                ) : null}

                            <DisplayContent isVisible={user?.updateSection?.section === 'recover-password' && user?.errors}>
                                <div className='alert alert-danger text-center' role='alert'>
                                    <ErrorMessage mode='return' code={user?.errors} collection='data.messages.ticketErrorMessages.jsonBlock' />
                                </div>
                            </DisplayContent>

                                <div className='form-group'>
                                    <label for='password'>Password</label>
                                    <PasswordField
                                        name='password'
                                        value={stepFields.password}
                                        handleChange={handleChange}
                                        newPassword={true}
                                    />
                                    <small id='passwordHelp' className='form-text text-muted'>
                                        <ul className='mx-0'>
                                            <li>Minimum password length: 10</li>
                                            <li>All characters are accepted</li>
                                            <li>At least one lower case</li>
                                            <li>At least one uppercase</li>
                                            <li>At least one number</li>
                                            <li>At least one special character</li>
                                            <li>Password must not be the same as previous 10 passwords.</li>
                                        </ul>
                                    </small>
                                    <span toggle='#password-field' className='fa fa-fw fa-eye field-icon toggle-password' />
                                    { passwordStatusForm(passwordStatus) }
                                </div>

                                <div className='form-group'>
                                    <label for='confirmPassword'>Confirm Password</label>
                                    <PasswordField name='confirm' value={stepFields.confirm} autoComplete='off' handleChange={handleChange} />
                                    <small id='confirmHelp' className='form-text text-muted'>
                                        Please retype your password.
                                    </small>
                                </div>

                                <button
                                    type="button"
                                    className="btn theme-btn btn-block theme-gray"
                                    onClick={handleSubmit}
                                    disabled={loading.actions['recoverPassword']}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </ContentBoxBody>
                </ContentBox>
            </div>
        );
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);
import React, { useState, useEffect } from 'react';

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import HomeTemplate from '../../PageTemplates/HomeTemplate';
import ContentPageTemplate from '../../PageTemplates/ContentPageTemplate';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import PostClaimTemplate from '../../PageTemplates/PostClaimTemplate.js';
import EnterPageTemplate from '../../PageTemplates/EnterPageTemplate.js';
import EnterFastplayTemplate from '../../PageTemplates/EnterFastplayTemplate.js';
import RewardsTemplate from '../../PageTemplates/RewardsTemplate.js';
import { promotion } from '../../promotion_config';

const templates = {
    home: HomeTemplate,
    content: ContentPageTemplate,
    enter: EnterPageTemplate,
    enterFastPlay: EnterFastplayTemplate,
    postclaim: PostClaimTemplate,
    rewards: RewardsTemplate,
    default: ContentPageTemplate,
};

const Home = ({ match, pageData, actions }) => {
    const {
        params: { page },
    } = match;

    const microsite = promotion;
    const pageName = `${microsite}${page ? '/' + page : ''}`;

    const [isLoading, setIsLoading] = useState(true);

    useEffect(async () => {
        await actions.cmsSourceActions.storeAllData();
        if (!pageData.pageInfo.length || pageData.currentSite != microsite) {
            actions.pageDataActions.getPageData({
                microsite_name: microsite,
            });
            actions.pageDataActions.getCopyData({
                microsite_name: microsite,
            });
            actions.pageDataActions.getStyleData({
                microsite_name: microsite,
            });
            actions.pageDataActions.getHeaderData({
                microsite_name: microsite,
            });
            actions.pageDataActions.getFooterData({
                microsite_name: microsite,
            });
            actions.pageDataActions.getJurisdictionData();
            actions.drawTicketActions.reset();
            actions.scratchTicketActions.reset();
            actions.fastPlayTicketActions.reset();
            // actions.igt24TicketActions.reset();
        }
    }, [pageName]);

    if (pageData.pageInfo) {
        const selectedMicrosite = pageData.pageInfo.filter((site) => {
            return site.microsite == microsite;
        })[0];

        if (selectedMicrosite) {
            const selectedPageData = selectedMicrosite.pages.filter(
                (dataPage) => {
                    return dataPage.url == page;
                }
            )[0] || { url: 'default' };

            const selectedPage =
                selectedPageData && templates[selectedPageData.template]
                    ? selectedPageData.template
                    : 'default';

            return (
                <div css={css(pageData.styleInfo.global)}>
                    <div className={`content-root ${selectedPageData.url}`}>
                        {React.createElement(templates[selectedPage], {
                            contentData: selectedPageData
                                ? selectedMicrosite.microsite +
                                  '_' +
                                  selectedPageData.url
                                : 'default',
                            layoutData: selectedMicrosite.layout,
                        })}
                    </div>
                </div>
            );
        } else {
            return <div className="home"></div>;
        }
    } else {
        return <div className="home"></div>;
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

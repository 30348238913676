import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import { mergeCopy } from '../utils/cms_copy_provider';
import SweepsInner from './Sweeps/SweepsInner';
import UserLockout from '../utils/user_lockout';

import isBrowser from '../utils/isBrowser';

export const Sweeps = ({ sweep, actions, pageData }) => {
    const defaultCopy = {
        sweeps: {
            title: 'Drawing and Winners',
            description: '',
            nosweepstakes: 'There are no sweepstakes to show',
            nodrawinggroups: 'There are no drawing groups to show',
            column: {
                prizes: 'Prizes',
                entryperiod: 'Entry Period',
                drawingdate: 'Drawing Date',
                numberofentries: 'Number of Entries',
            },
        },
    };

    const usedCopy = mergeCopy(defaultCopy, pageData.copyInfo);

    const queryString = isBrowser() ? window.location.search : '';
    const urlParams = new URLSearchParams(queryString);
    const preselection = {
        sweepId: urlParams.get('sweep_id'),
        drawGroupId: urlParams.get('draw_group_id'),
    };

    return (
        <div className='container-fluid sweeps primary-page-content'>
            <UserLockout>
                <SweepsInner preselection={preselection} sweep={sweep} actions={actions} copy={usedCopy} />
            </UserLockout>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Sweeps);

import React, { useState } from 'react';
import Cleave from 'cleave.js/react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import * as yup from 'yup';
import moment from 'moment';

import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from '../ContentBox';

const UpdateIdentification = ({ verifyFields, setVerifyFields, user, actions }) => {
    const userDOB = user.player.birthdate ? moment(user.player.birthdate).format('MM-DD-YYYY') : '';

    const userGender = user.player.extra && user.player.extra.gender ? user.player.extra.gender : '';

    const initialStepFields = {
        firstname: user.player.firstname ?? '',
        lastname: user.player.lastname ?? '',
        birthdate: userDOB ?? '',
        gender: userGender ?? '',
    };

    const schema = yup.object().shape({
        //? Disabled fields - needed for later
        /*
        firstname: yup
            .string()
            .required('First Name is required')
            .matches(/^[a-z A-Z]*$/, 'Invalid First Name')
            .max(25, 'First name cannot exceed 25 characters'),

        lastname: yup
            .string()
            .required('Last Name required')
            .matches(/^[a-z A-Z]*$/, 'Invalid Last Name')
            .max(25, 'Last name cannot exceed 25 characters'),

        birthdate: yup
            .string()
            .test('birthdate', 'Must be 18 or older to register', (value) => {
                let birthdate_YMD = value.split('-');
                birthdate_YMD = `${birthdate_YMD[2]}-${birthdate_YMD[0]}-${birthdate_YMD[1]}`;
                return moment().diff(moment(birthdate_YMD).format('YYYY-MM-DD'), 'years') >= 18;
            })
            .test('birthdate', 'Invalid Date of Birth', (value) => {
                let birthdate_YMD = value.split('-');
                birthdate_YMD = `${birthdate_YMD[2]}-${birthdate_YMD[0]}-${birthdate_YMD[1]}`;
                return moment().diff(moment(birthdate_YMD).format('YYYY-MM-DD'), 'years') <= 150;
            })
            .required('Date of Birth is required'),
        */
        gender: yup.string().required('Gender is required'),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState('');
    const [localErrors, setLocalErrors] = useState({});

    // if the form field changes, we will change the value in the store and
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trimStart();
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(stepFields);

        if (valid) {
            setError('');
            setLocalErrors({});

            await actions.userActions.updateSection({ section: 'identification', status: 'initial' });

            //? disabled: needed for later
            // const birthDateYMD = moment(stepFields.birthdate).format('YYYY-MM-DD');

            const payloadData = {
                firstname: user.player.firstname,
                lastname: user.player.lastname,
                address: user.player.address ?? {},

                // enabled fields:
                gender: stepFields.gender ?? '',

                //? disabled: needed for later
                // firstname: stepFields.firstname ?? '',
                // lastname: stepFields.lastname ?? '',
                // birthdate: birthDateYMD ?? '',
                // phone: user.player.phone ?? '',
            };

            await actions.userActions.update(payloadData);
        } else {
            await schema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                setLocalErrors(getValidationErrors(err));
                setError(err.message);
            });
        }
    };

    const getValidationErrors = (err) => {
        const validationErrors = {};

        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);

        return validationErrors;
    };

    return (
        <ContentBox variant='theme-blue'>
            <ContentBoxHead>IDENTIFICATION</ContentBoxHead>
            <ContentBoxBody>
                <div className='registration-step'>
                    <div className='inner-step'>

                        {error ? (
                            <div className='alert alert-danger text-center' role='alert'>
                                {Object.values(localErrors).map((err) => {
                                    return <p>{err}</p>;
                                })}
                            </div>
                        ) : null}

                        {user.updateSection.section === 'identification' && user.updateSection.status === 'success' && (
                            <div className='alert alert-success text-center' role='alert'>
                                Identification Information updated.
                            </div>
                        )}

                        {user.updateSection.section === 'identification' && user.updateSection.status === 'error' && (
                            <div className='alert alert-danger text-center' role='alert'>
                                Server error, please try again later.
                            </div>
                        )}

                        <div className='form-group'>
                            <label for='firstname'>First Name *</label>
                            <input
                                type='text'
                                className='form-control'
                                id='firstname'
                                value={stepFields.firstname}
                                name='firstname'
                                placeholder=''
                                //? currently disabled field: onChange={handleChange}
                                disabled
                            />
                        </div>
                        <div className='form-group'>
                            <label for='confirmEmail'>Last Name *</label>
                            <input
                                type='text'
                                className='form-control'
                                id='lastname'
                                value={stepFields.lastname}
                                name='lastname'
                                placeholder=''
                                //? currently disabled field: onChange={handleChange}
                                disabled
                            />
                        </div>
                        <div className='form-group'>
                            <label for='confirmEmail'>DOB*</label>
                            <Cleave
                                className='form-control col-md-3'
                                placeholder='MM-DD-YYYY'
                                id='birthdate'
                                value={stepFields.birthdate}
                                name='birthdate'
                                options={{
                                    date: true,
                                    delimiter: '-',
                                    datePattern: ['m', 'd', 'Y'],
                                }}
                                //? currently disabled field: onChange={handleChange}
                                disabled
                            />
                        </div>
                        <div className='form-group'>
                            <label for='gender'>Gender*</label>
                            <select name='gender' value={stepFields.gender} onChange={handleChange} className='required form-control col gender'>
                                <option value=''>Select One</option>
                                <option value='M'>Male</option>
                                <option value='F'>Female</option>
                                <option value='O'>Other</option>
                                <option value='H'>Prefer not to share</option>
                            </select>
                        </div>

                        <p className='text-center my-2'>
                            Be sure to click the 'Update Information' button when you are done or your changes will not be saved.
                        </p>

                        <button type='button' className='btn theme-btn btn-block theme-gray' onClick={handleSubmit}>
                            Update Information
                        </button>
                    </div>
                </div>
            </ContentBoxBody>
        </ContentBox>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateIdentification);

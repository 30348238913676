import React, { useEffect, useState } from 'react';
import lottie from 'lottie-web';

const Lottie = ({ pageName, height = '100%', width = '100%' }) => {
    const [body, setBody] = useState(null);

    const getContentPage = async (pageName) => {
        let db = window.localStorage;
        const res = db.getItem(pageName);

        const response = JSON.parse(res).body;

        setBody(JSON.parse(response));
    };

    useEffect(() => {
        getContentPage(pageName);
    }, [pageName]);

    const lottieElement = document.getElementById(pageName);
    if (lottieElement) lottieElement.innerHTML = '';
    lottie.loadAnimation({
        container: document.getElementById(pageName), // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: body,
        // path: `/${pageName}.json`, // the path to the animation json
    });

    return <div style={{ width: width, height: height }} className='lottie' id={pageName} />;
};

export default Lottie;

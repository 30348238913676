import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import { formatDate, formatDateTimeWithDayOfTheWeek as formatDateTime } from '../../utils/formatDates';
import WinnerLinkWrapper from '../WinnerLinkWrapper';
class DrawingTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
        };
    }

    transformDrawingData(data) {
        return data.reduce((acc, line) => {
            const info = line && line.drawings && line.drawings[0] ? line.drawings[0] : {};
            return [...acc, { ...info, ...line }];
        }, []);
    }

    render() {
        const mainDrawings = this.transformDrawingData(this.props.drawings);
        //? for testing: console.log('🔥 Promotion Drawings', mainDrawings);

        const desktopDrawEntries = (rewards) => {
            if (!rewards || mainDrawings.length === 0) {
                return (
                    <table id='entryTable' className='table entry-table d-none d-md-table text-center'>
                        <tbody>
                            <tr key='0'>
                                <td colSpan='3'>
                                    <em>{this.state.isLoaded ? 'No drawings' : '. . .'}</em>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                );
            } else {
                // note we are spreading entries into a new array so we don't mutate the
                // entries array in state
                return (
                    <>
                        {[...mainDrawings].map(({ id, name, quantum_start, quantum_end, draw_date, drawing_summaries, drawings }) => {
                            return (
                                <>
                                    <div class='header-container  d-none d-md-block'>
                                        <h2 class='weekly-drawing-header'>{name}</h2>
                                    </div>
                                    <table id='entryTable' className='table entry-table d-none d-md-table text-center'>
                                        <thead>
                                            <tr>
                                                <th>ENTRY PERIOD</th>
                                                <th>MY ENTRIES</th>
                                                <th>DRAWING DATE</th>
                                            </tr>
                                        </thead>

                                        {drawings.map((obj) => (
                                            <tbody>
                                                <tr key={id}>
                                                    <td>
                                                        {formatDateTime(quantum_start)} - {formatDateTime(quantum_end)}
                                                    </td>
                                                    <td>{drawing_summaries?.length > 0 ? drawing_summaries[0]?.entry_count : 0}</td>
                                                    <td>
                                                        <div>{formatDate(draw_date)}</div>
                                                        {obj.certified && (
                                                            <div>
                                                                <WinnerLinkWrapper selected={obj} />
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </>
                            );
                        })}
                    </>
                );
            }
        };

        const desktopEntrylist = (drawings) => {
            return <>{desktopDrawEntries(drawings)}</>;
        };

        const mobileEntrylist = (mainDrawings) => {
            if (!mainDrawings || mainDrawings.length === 0) {
                return (
                    <div className='d-md-none stacked-rewards-container'>
                        <div className='row'>
                            <div className='col'>Drawings</div>
                            <div className='col'>
                                <em>{this.state.isLoaded ? 'No drawings.' : '. . .'}</em>
                            </div>
                        </div>
                    </div>
                );
            } else {
                // note we are spreading entries into a new array so we don't mutate the
                // entries array in state
                return (
                    <div className='d-md-none'>
                        {[...mainDrawings].map(({ id, name, drawing_summaries, quantum_start, quantum_end, draw_date, drawings }) => {
                            return (
                                <>
                                    <div class='mobile-header-container'>
                                        <h2 class='weekly-drawing-header'>{name}</h2>
                                    </div>
                                    <div className='table-responsive'>
                                        <table id={id} className={'table theme-table mobile entry-table d-lg-none text-center'}>
                                            {drawings.map((obj) => (
                                                <tbody>
                                                    <tr>
                                                        <td className='position-sticky'>
                                                            <strong>Entry Period</strong>
                                                        </td>
                                                        <td>
                                                            {formatDateTime(quantum_start)} - {formatDateTime(quantum_end)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='position-sticky'>
                                                            <strong>My Entries</strong>
                                                        </td>
                                                        <td className='position-sticky'>
                                                            {drawing_summaries?.length > 0 ? drawing_summaries[0]?.entry_count : 0}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='position-sticky'>
                                                            <strong>Drawing Date</strong>
                                                        </td>
                                                        <td className='position-sticky'>
                                                            <div>{formatDate(draw_date)}</div>
                                                            {obj.certified && (
                                                                <div>
                                                                    <WinnerLinkWrapper selected={obj} />
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ))}
                                        </table>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                );
            }
        };

        return (
            <div className='row'>
                <div className='col'>
                    {desktopEntrylist(mainDrawings)}
                    {mobileEntrylist(mainDrawings)}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawingTable);

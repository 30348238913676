import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import rightArrow from '../../assets/images/arrow_right_solid.svg';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from '../ContentBox';

export class ActiveSweepstakes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.actions.sweepActions.getCurrentSweeps();
    }

    render() {
        //* Component setup
        const title = this.props.title ?? 'Active Promotions';
        const button_vap = this.props.buttons === 'view-all-promotions';
        const displayCount = this.props.displayCount; 
        
        //* Validation check
        const valid = this.props.sweep && this.props.sweep.current && Array.isArray(this.props.sweep.current) && this.props.sweep.current.length;
        if (!valid) return null;

        //? For debug: console.log('🎁 Active sweeps:', this.props.sweep.current);

        const activeSweeps =
            this.props.sweep.current && Array.isArray(this.props.sweep.current) && this.props.sweep.current.length > 0
                ? [...this.props.sweep.current].sort((a, b) => {
                      return a.rank - b.rank;
                  })
                : this.props.sweep.current;

        const sweepsToShow = typeof displayCount === 'number' ? activeSweeps.slice(0, displayCount) : activeSweeps;

        return (
            <>
                <ContentBox variant='theme-purple'>
                    <ContentBoxHead>
                        {title} {button_vap ? null : <ContentCollapser />}
                    </ContentBoxHead>
                    <ContentBoxBody>
                        <Row>
                            {sweepsToShow.map(({ id, name, extra }) => {
                                let trimDesc = extra?.description;
                                if (trimDesc) {
                                    const maxLength = 500;
                                    const originalString = trimDesc;
                                    const newRegex = /(<([^>]+)>)/gi;
                                    let tags = originalString.match(newRegex);
                                    let truncatedString = originalString.replace(/(<([^>]+)>)/gi, '<>').trimEnd();

                                    if (truncatedString.length >= maxLength) {
                                        truncatedString = truncatedString.substring(0, maxLength);
                                        const lastSpace = truncatedString.lastIndexOf(' ');
                                        truncatedString = truncatedString.substring(0, lastSpace).trim() + ' ...';
                                    }

                                    if (tags) {
                                        tags.forEach((element) => {
                                            truncatedString = truncatedString.replace('<>', element);
                                        });
                                    }

                                    trimDesc = truncatedString;
                                }
                                return id && name && extra && extra.image && extra.description ? (
                                    <Col lg={4} className='my-3' key={id}>
                                        <Card className='sweepstake-item'>
                                            <Card.Img alt={`${name} promotional image`} variant='top' src={extra.image} className='cover-image' />
                                            <Card.Body>
                                                <Card.Title>{name}</Card.Title>
                                                <Card.Text>
                                                    <div dangerouslySetInnerHTML={{ __html: trimDesc }}></div>
                                                </Card.Text>
                                                <div className='d-flex justify-content-end'>
                                                    <a href={`promotion?id=${id}`} className='more-info-link'>
                                                        More Info <img alt=""  src={rightArrow} className='svg-icon' />
                                                    </a>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ) : null;
                            })}
                        </Row>

                        {button_vap ? (
                            <div className='text-center my-4'>
                                <Link to='/promotions' className='btn theme-btn theme-outline'>
                                    View all Promotions
                                </Link>
                            </div>
                        ) : null}
                    </ContentBoxBody>
                </ContentBox>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveSweepstakes);

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { Redirect } from 'react-router-dom';
import { autoTab, CMSContent, ErrorMessage, getCMSObject, TeleScript, FindScratchNumbers, ContentBox, ContentBoxHead, ContentBoxBody } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { validateScratcherEntry } from '../../validationRules';

import { Alert, Button, Modal } from 'react-bootstrap';
import Scanner from './Scanner';
import promotionConfig from '../../promotion_config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo, faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import ScratchFieldInput from './ScratchFieldInput';
import { replaceBadTicketInput } from '../../utils/replaceBadTicketInput';
import siteConfig from '../../promotion_config';
import isBrowser from '../../utils/isBrowser';

library.add(faCircleInfo, faCircleXmark);

/**********************************************************************
 * Component:  DrawTicketForm
 * Purpose:    Allows for the entry of Draw Tickets
 *
 * Props:       user - the user data store
 *              actions - store actions (apis)
 *              drawTicket - the draw ticket data store
 *
 * APIs used:   drawTicketActions.enterDrawTicket
 *
 *  Notes:  For MO, does not check Winning Status, only for
 *          bonusing rewards.
 */
const DrawTicketForm = ({ user, actions, drawTicket, cmsSourceFirebase, scratchTicket }) => {
    let initialState = {};
    const queryString = isBrowser() ? window.location.search : '';

    const urlParams = new URLSearchParams(queryString);

    const removeSpecialChars = (input) => {
        if (input) {
            return input.replace(/[^a-zA-Z0-9]/g, '');
        }
        return null;
    };

    const preselection = {
        ticket_number: removeSpecialChars(urlParams.get('ticket_number')),
        entry_method: removeSpecialChars(urlParams.get('entry_method')),
    };

    const getEntryMethodFromParam = () => {
        if (preselection.entry_method === 'mobile') {
            return 'mobile';
        }

        return 'manual';
    };

    // Format of the Draw Tickets
    const initialDrawFields = promotionConfig.drawFields;
    const ticketEntryTeleScript = getCMSObject('data.ticketEntry');

    const [drawFields, setDrawFields] = useState(initialDrawFields);
    const [ticketError, setTicketError] = useState('');
    const [validationError, setValidationError] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [ticketSuccess, setTicketSuccess] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const scratchTicketFields = siteConfig.scratchTicketFields;
    const ticketEntryLink = getCMSObject('data.ticketEntry.ticketHistoryLink.contentHTML');
    const [formState, setFormState] = useState(initialState);
    const [entryMethod, setEntryMethod] = useState(getEntryMethodFromParam());
    const [gameNumberFields, setGameNumberFields] = useState(scratchTicketFields?.gameNumberFields ?? []);
    const [scratchFrontFields, setScratchFrontFields] = useState(scratchTicketFields?.fieldGroups?.[0]?.frontNumberFields ?? []);
    const [scratchBackFields, setScratchBackFields] = useState(scratchTicketFields?.fieldGroups?.[0]?.backNumberFields ?? []);
    const elemRefs = [{}]; // Defaulted to one element to avoid problem with first tab
    const ticketNumberSecondInputRef = useRef();
    const prepopTicket = new URLSearchParams(document.location.search).get('ticketnumber');

    //***********************************************************************************
    // Processes the ticket status and if the ticket was a success, redirect to
    // Post Claim.
    // If Ticket fails, report the error.  If failure was due to Player Lockout, prevent
    // further submitting of tickets.
    //
    useEffect(() => {
        if (disabled) {
            if (scratchTicket.success) {
                updateDashboard();
                setTicketSuccess(true);
            } else {
                setTicketError(scratchTicket.message);
            }
            setDisabled(false);
        }
        if (scratchTicket.message === '_PLAYER_LOCKOUT') {
            setDisabled(true);
        }
    }, [scratchTicket]);

    // Cleanup
    useEffect(() => {
        return () => {
            // clean up and make sure fields are clear
            gameNumberFields.forEach((field) => {
                field.value = '';
            });
            scratchFrontFields.forEach((field) => {
                field.value = '';
            });
            scratchBackFields.forEach((field) => {
                field.value = '';
            });
            setGameNumberFields([]);
            setScratchFrontFields([]);
            setScratchBackFields([]);
            setTicketError('');
            setValidationError('');
            setDisabled(false);
            setTicketSuccess(false);
            setShowModel(false);
        };
    }, []);
    //***********************************************************************************
    // Change the Scratch ticket format based on the game number field.
    //
    useEffect(() => {
        const gameNum = gameNumberFields[0].value;
        scratchTicketFields.fieldGroups.forEach((fieldGroup) => {
            if (inRange(gameNum, fieldGroup)) {
                setScratchFrontFields(fieldGroup.frontNumberFields ?? []);
                setScratchBackFields(fieldGroup.backNumberFields ?? []);
                return;
            }
        });
    }, [gameNumberFields]);
    //***********************************************************************************
    // Updates the dashboard on a ticket entry, see if points have been updated
    //
    const updateDashboard = async () => {
        if (user.loggedIn && user.player.actions.length === 0) {
            await actions.userActions.getDashboard();
        }
    };
    //* Convert to alpha numeric, skip special characters
    const convertAlphaNumeric = (str) => {
        return str.replace(/[^a-z0-9]/gi, '');
    };

    // if the form field changes, we will change the value in the store and
    // also check if the next field must be focused
    const handleChange = function (event) {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : convertAlphaNumeric(target.value);
        const name = target.name;

        const thisField = drawFields.filter((field) => {
            return field.name == name;
        })[0];

        value = thisField && thisField.onChange ? thisField.onChange(value) : value;
        target.value = value;

        setFormState({ ...formState, [name]: value });

        autotab(target, target.dataset.next);
    };

    // Find if the given number is in this range grouping
    const inRange = (number, rangeGroup) => {
        let numberInRange = false;
        rangeGroup.validRanges.forEach((range) => {
            if (parseInt(number) >= parseInt(range[0]) && parseInt(number) <= parseInt(range[1])) {
                numberInRange = true;
                return;
            }
        });
        return numberInRange;
    }; // end inRange

    //***********************************************************************************
    // onChange callback for the input fields.  Sets the state of the front and back
    // fields
    //
    const handleFieldChange = async (event, field) => {
        setTicketError('');
        setValidationError('');
        const userInput = replaceBadTicketInput(event.target);
        if (field.side === 'front') {
            const frontFields = scratchFrontFields;
            const fieldIndex = frontFields.findIndex((obj) => obj.name == field.name);
            frontFields[fieldIndex].value = userInput.value;
            setScratchFrontFields(frontFields);
        } else {
            const backFields = scratchBackFields;
            const fieldIndex = backFields.findIndex((obj) => obj.name == field.name);
            backFields[fieldIndex].value = userInput.value;
            setScratchBackFields(backFields);
        }
    }; // end handleFieldChange

    //***********************************************************************************
    // Handle the submit of the scratch ticket.  Combine all the tickets fields into
    // one string object that is passed to the ScratchTicket API and disable submit
    // until we get a response.
    //

    // fire the submission
    const handleSubmit = async (event) => {
        setDisabled(true);

        event.preventDefault();
        // await createfunc(formState);

        const bonusFields = { entry_method: entryMethod };

        const payload = { ...formState, ...bonusFields };

        //? for debug:
        // console.log('normal form state', formState);
        // console.log('bonus fields', bonusFields);
        // console.log('🔥 payload', payload);
        await actions.drawTicketActions.enterDrawTicket(payload);

        setDisabled(false);
    };

    const handleModalClose = () => {
        setShowModel(false);
    };
    //***********************************************************************************
    // Processes the ticket status and if the ticket was a success, redirect to
    // Post Claim.
    // If Ticket fails, report the error.  If failue was due to Player Lockout, prevent
    // further submitting of tickets.
    //
    useEffect(() => {
        if (disabled) {
            if (drawTicket.success) {
                updateDashboard();
                setTicketSuccess(true);
            } else {
                setTicketError(drawTicket.message);
            }
            setDisabled(false);
        }
        if (drawTicket.message === '_PLAYER_LOCKOUT') {
            setDisabled(true);
        }
    }, [drawTicket]);

    useEffect(() => {
        return () => {
            drawFields.forEach((field) => {
                field.value = '';
            });

            setDrawFields([]);
            setTicketError('');
            setValidationError('');
            setDisabled(false);
            setTicketSuccess(false);
            setShowModel(false);
        };
    }, []);

    // rehash of old autotab functionality from PA
    const autotab = (current, nex) => {
        var next = document.getElementById(nex);

        if (!!nex && !!next && !!current && current.getAttribute && current.value.length === parseInt(current.getAttribute('maxlength'))) {
            next.focus();
        }
    };


    if (prepopTicket) {
        actions.drawTicketActions.enterDrawTicket({
            ticket1_1_1: prepopTicket,
            entry_method: 'scan',
        });
        return (
            <Redirect
                to={{
                    pathname: '/post-claim',
                    state: { motive: 'ScanTicket' },
                    motive: 'ScanTicket',
                }}
            />
        );
    } else {
        return (
            <div>
                {ticketSuccess ? <Redirect to={{ pathname: '/post-claim', state: { motive: 'TicketEntry' }, motive: 'TicketEntry' }} /> : null}

                {validationError ? (
                    <Alert variant='danger'>
                        <FontAwesomeIcon icon='fa-regular fa-circle-xmark' />
                        <div className='alert-text'>{validationError}</div>
                    </Alert>
                ) : null}

                <ErrorMessage code={ticketError} collection='data.messages.ticketErrorMessages.jsonBlock' />
                <div>
                    <ContentBox variant='theme-green'>
                        <ContentBoxHead> ENTER TICKETS </ContentBoxHead>
                        <ContentBoxBody>
                            <form onSubmit={handleSubmit} disabled={disabled}>
                                <div className='row justify-content-center ticket-type-row'>
                                    <div className='col-10 ticket-type'>
                                        <p className='text-center'>INSTANT</p>
                                        <hr />
                                        <Scanner />
                                    </div>
                                </div>
                                <div className='enter-form row'>
                                    <div className='col-auto'>
                                        <div className='form-group'>
                                            <label for='ticket1_1_5'>Front number:</label>
                                            <div className='form-row align-items-center'>
                                                <div className='col-auto'>
                                                    <input
                                                        className='form-control'
                                                        id='ticket1_1_5'
                                                        name='ticket1_1_5'
                                                        data-next='ticket1_1_1'
                                                        size='9'
                                                        maxlength='9'
                                                        value={formState.ticket1_1_5}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className='col-auto'>
                                                    <span className='help-span'>
                                                        <a href='/enter#enter-help'>?</a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label for='ticket1_1_1'>Back number:</label>
                                            <div className='form-row align-items-center'>
                                                <div className='col-auto'>
                                                    <input
                                                        className='form-control'
                                                        id='ticket1_1_1'
                                                        name='ticket1_1_1'
                                                        data-next='ticket1_1_2'
                                                        size='4'
                                                        maxlength='4'
                                                        value={formState.ticket1_1_1}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className='col-auto dash-col'>&mdash;</div>
                                                <div className='col-auto'>
                                                    <input
                                                        className='form-control'
                                                        id='ticket1_1_2'
                                                        name='ticket1_1_2'
                                                        data-next='ticket1_1_3'
                                                        size='6'
                                                        maxlength='6'
                                                        value={formState.ticket1_1_2}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className='col-auto dash-col'>&mdash;</div>
                                                <div className='col-auto'>
                                                    <input
                                                        className='form-control'
                                                        id='ticket1_1_3'
                                                        name='ticket1_1_3'
                                                        size='3'
                                                        maxlength='3'
                                                        value={formState.ticket1_1_3}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className='col-auto'>
                                                    <span className='help-span'>
                                                        <a href='/enter#enter-help'>?</a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-auto align-self-end px-2'>
                                        <div className='form-group'>
                                            <button type='submit' className='btn theme-btn theme-green' disabled={disabled}>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </ContentBoxBody>
                    </ContentBox>
                </div>

                <Modal show={showModel} onHide={handleModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.ticketEntry.findScratchNumbers.contentHeaderText'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <FindScratchNumbers.ScratchNumberExample cmsSourceFirebase={cmsSourceFirebase} />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className='btn theme-btn theme-secondary' onClick={handleModalClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        ); // end return
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawTicketForm);

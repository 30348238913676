import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import { Card, Row, Col, Button } from 'react-bootstrap';

import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from '../ContentBox';

export class ExpiredSweepstakes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: this.props.sweep.past.current_page ?? 1,
            pagesize: 6,
        };
    }

    componentDidMount() {
        this.props.actions.sweepActions.getPastSweeps({ page: this.state.page, pagesize: this.state.pagesize });
    }

    loadMoreData() {
        this.props.actions.sweepActions.getPastSweeps({ page: this.props.sweep.past.current_page + 1, pagesize: this.state.pagesize });
    }

    render() {
        //* Component setup
        const title = this.props.title ?? 'Expired Promotions';
        const button_vm =
            this.props.sweep &&
            this.props.sweep.past &&
            this.props.sweep.past.current_page &&
            this.props.sweep.past.last_page &&
            this.props.sweep.past.current_page !== this.props.sweep.past.last_page;

        //* Validation check
        const valid =
            this.props.sweep &&
            this.props.sweep.past &&
            this.props.sweep.past.data &&
            Array.isArray(this.props.sweep.past.data) &&
            this.props.sweep.past.data.length;
        if (!valid) return null;

        //? For debug: console.log('🎁 Past sweeps:', this.props.sweep.past);

        return (
            <>
                <ContentBox variant='theme-purple'>
                    <ContentBoxHead>{title}<ContentCollapser /></ContentBoxHead>
                    <ContentBoxBody>
                        <Row>
                            {this.props.sweep.past.data.map(({ id, name, extra }) => {
                                return (
                                    (id && name && extra && extra.image && extra.description)
                                    ?
                                    <Col lg={4} className='my-3' key={id}>
                                        <Card className='sweepstake-item'>
                                            <Card.Img variant='top' src={extra.image} className='cover-image' />
                                            <Card.Body>
                                                <Card.Title>{name}</Card.Title>
                                                <Card.Text>
                                                    <div dangerouslySetInnerHTML={{ __html: extra.description }}></div>
                                                </Card.Text>
                                                <a href={`promotion?id=${id}`} className='float-right'>
                                                    More Info &gt;
                                                </a>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    : null
                                );
                            })}
                        </Row>

                        {button_vm ? (
                            <div className='text-center my-4'>
                                <Button variant='outline-secondary' onClick={() => this.loadMoreData()}>
                                    View More
                                </Button>
                            </div>
                        ) : null}
                    </ContentBoxBody>
                </ContentBox>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpiredSweepstakes);

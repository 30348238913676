import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import { CMSContent } from 'sg-ui-components';

const CustomerContent = ({ cmsSourceFirebase }) => {
    const [contentLoaded, setContentLoaded] = useState(false);

    const loadScript = (url) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = url;
            script.async = true;

            script.onload = () => resolve(script);
            script.onerror = () => reject(new Error(`Script load error for ${url}`));

            document.body.appendChild(script);
        });
    };

    useEffect(() => {
        let isCancelled = false;

        const loadScriptsSequentially = async () => {
            if (!contentLoaded) return;

            try {
                await loadScript('https://code.jquery.com/jquery-3.3.1.slim.min.js');
                await loadScript('./js/jquery.headerfooter.js');
            } catch (error) {
                console.error('Script loading failed:', error);
            }
        };

        if (!isCancelled) {
            loadScriptsSequentially();
        }

        return () => {
            isCancelled = true;
        };
    }, [contentLoaded]);

    useEffect(() => {
        const interval = setInterval(() => {
            const targetElement = document.getElementById('sticky-menu');
            if (targetElement) {
                setContentLoaded(true);
                clearInterval(interval);
            }
        }, 100); // Check every 100ms

        return () => clearInterval(interval);
    }, []);

    return (
        <header id='header' className='ctl-nav'>
            <CMSContent localStorageObject='webContent' contentPath='data.siteDisplay.header.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
        </header>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerContent);

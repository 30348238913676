import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: 'AIzaSyDPHJfv68gYb8BiFcGjV6XA-C7e1TYBjVo',
    authDomain: 'adavi-devdatastore.firebaseapp.com',
    projectId: 'adavi-devdatastore',
    storageBucket: 'adavi-devdatastore.appspot.com',
    messagingSenderId: '55242452478',
    appId: '1:55242452478:web:c28784a812e4ebedc124af',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timeStamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timeStamp };

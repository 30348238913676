import { createSlice } from '@reduxjs/toolkit';
import apiClient from '../Api/api_client';
import { entrySlice } from './EntryStore';
import { loadingSlice } from './LoadingStore';

export const defaultDrawTicketState = {
    gameOver: false,
    success: false,
    message: '',
    entry: '',
};

// REDUCERS
// basic actions that affect this object only

const enterDrawTicketReducer = (state, action) => {
    return {
        ...state,
        success: action.payload.success,
        message: action.payload.message,
        entry: action.payload.entry,
    };
};

const resetReducer = (state, action) => {
    return {
        ...defaultDrawTicketState,
    };
};

const setGameOverReducer = (state, action) => {
    return {
        ...state,
        gameOver: action.payload.gameOver,
    };
};

export const drawTicketSlice = createSlice({
    name: 'drawTicket',
    initialState: defaultDrawTicketState,
    reducers: {
        enterDrawTicket: enterDrawTicketReducer,
        reset: resetReducer,
        setGameOver: setGameOverReducer,
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

//* Ovveride ticket status if needed
const validateTicketResponse = (response, promotions) => {
    //? for debug: console.log('🚒 Ticket response:', response, promotions);

    //* Override status: NO_ACTIVE_PROMOTIONS
    if (
        response.success &&
        response.entry &&
        response.entry.TicketDetails &&
        response.entry.TicketDetails.TicketStatus === 'NOT_A_WINNER' &&
        response.entry.Bonus &&
        Array.isArray(response.entry.Bonus) &&
        response.entry.Bonus.length === 0 &&
        promotions &&
        Array.isArray(promotions) &&
        promotions.length === 0
    ) {
        console.log('No Active Promotions', response.message);
        response.success = false;
        response.message = '_NO_ACTIVE_PROMOTIONS';
        return response;
    }

    //* Override status: DAILY_ENTRY_EXCEEDED
    if (
        response.success &&
        response.entry &&
        response.entry.TicketDetails &&
        response.entry.TicketDetails.TicketStatus === 'NOT_A_WINNER' &&
        response.entry.Bonus &&
        Array.isArray(response.entry.Bonus) &&
        response.entry.Bonus.length === 0
    ) {
        response.entry.TicketDetails.TicketStatus = 'DAILY_ENTRY_EXCEEDED';
    }

    //* Override status: _PLAYER_MUST_UPDATE_VERIFY
    if (
        response.success === false &&
        response.message &&
        response.message === '_PLAYER_MUST' &&
        response.details &&
        response.details.search('update-account') !== -1 &&
        response.details.search('verify-account') !== -1
    ) {
        response.message = '_PLAYER_MUST_UPDATE_VERIFY';
    }

    //* Override status: _PLAYER_MUST_UPDATE
    if (
        response.success === false &&
        response.message &&
        response.message === '_PLAYER_MUST' &&
        response.details &&
        response.details.search('update-account') !== -1
    ) {
        response.message = '_PLAYER_MUST_UPDATE';
    }

    //* Override status: _PLAYER_MUST_VERIFY
    if (
        response.success === false &&
        response.message &&
        response.message === '_PLAYER_MUST' &&
        response.details &&
        response.details.search('verify-account') !== -1
    ) {
        response.message = '_PLAYER_MUST_VERIFY';
    }

    //* Override status: _PLAYER_MUST_CONFIRM
    if (
        response.success === false &&
        response.message &&
        response.message === '_PLAYER_MUST' &&
        response.details &&
        response.details.search('account-confirm') !== -1
    ) {
        response.message = '_PLAYER_MUST_CONFIRM';
    }

    //* Override status: _PLAYER_MUST_CHANGE_PASSWORD
    if (
        response.success === false &&
        response.message &&
        response.message === '_PLAYER_MUST' &&
        response.details &&
        response.details.search('change-password') !== -1
    ) {
        response.message = '_PLAYER_MUST_CHANGE_PASSWORD';
    }

    //? for debug: console.log('🚒 Updated ticket response:', response);
    return response;
};

const enterDrawTicket = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'enterDrawTicket' }));
    const promotions = await apiClient().getPromotions();
    //const promotions = [];
    let result = await apiClient().enterDrawTicket(payload);
    result = validateTicketResponse(result, promotions);
    if (result.success) {
        dispatch(entrySlice.actions.setLastEntry(result.entry));
    }
    dispatch(drawTicketSlice.actions.enterDrawTicket(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'enterDrawTicket' }));
};

const setGameOver = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'setGameOver' }));
    const result = await apiClient().getGameOver(payload);
    dispatch(drawTicketSlice.actions.setGameOver(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'setGameOver' }));
};

//actions that get exported to the views
export const drawTicketActions = (dispatch) => {
    return {
        enterDrawTicket: (payload) => enterDrawTicket(dispatch, payload),
        reset: (payload) => dispatch(drawTicketSlice.actions.reset()),
        setGameOver: (payload) => setGameOver(dispatch, payload),
    };
};

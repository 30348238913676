import React, { useState, useEffect } from 'react';
import { PasswordField } from './password_field';
import * as yup from 'yup';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import CustomerContent from '../CustomerContent';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from '../ContentBox';
import { ErrorMessage } from 'sg-ui-components';

const StepFourTemplate = ({ step, setStep, verifyFields, setVerifyFields, user, actions }) => {
    const goBack = (e) => {
        e.preventDefault();
        setStep({ step: 2 });
    };

    const initialStepFields = {
        ssn: '',
    };

    const schema = yup.object().shape({
        ssn: yup
            .string()
            .min(
                4,
                'You must enter SSN4 to proceed with verification process. If you enter your SSN4 and still see the error, please <a href="/feedback#contact">Contact Us</a> for further assistance.'
            )
            .required(
                'You must enter SSN4 to proceed with verification process. If you enter your SSN4 and still see the error, please <a href="/feedback#contact">Contact Us</a> for further assistance.'
            ),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState('');
    const [localErrors, setLocalErrors] = useState({});

    // if the form field changes, we will change the value in the store and
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trimStart();
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });
    };

    const handleInput = (event) => {
        const target = event.target;
        target.value = target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    };

    const finalSubmit = async (fieldsToPush) => {
        await actions.userActions.verify(fieldsToPush);
    };

    const stepSubmit = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(stepFields);

        if (valid) {
            setError('');
            setLocalErrors({});
            let fieldsToPush = {
                ...verifyFields,
                ssn: stepFields.ssn,
                registrar_id: user.player.registrar_id,
            };
            setVerifyFields(fieldsToPush);
            finalSubmit(fieldsToPush);
        } else {
            await schema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                setLocalErrors(getValidationErrors(err));
                setError('');
            });
        }
    };

    const success = () => {
        if (!user.verifySuccess && step.step == 4) {
            if (user.errors) setError(user.errors);
        } else {
            if (user.verifySuccess) {
                setError('');
                actions.userActions.clearErrors();
                setStep({ step: 5 });
            }
        }
    };

    const checkSubmitDisabled = (error) => {
        if (['_MUST_MANUAL_VERIFY', '_NOT_VERIFIABLE'].includes(error)) {
            return true;
        }

        return false;
    };

    const getValidationErrors = (err) => {
        const validationErrors = {};

        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);

        return validationErrors;
    };

    useEffect(success, [user]);

    if (step.step == 4) {
        return (
            <div className='registration-step step4'>
                <ContentBox>
                    <ContentBoxHead className='registration-step-title'>Verification</ContentBoxHead>
                    <ContentBoxBody>
                        <div className='inner-step'>
                            <div className='col'>
                                <CustomerContent content='ensemble_site_registration_step4' />
                            </div>
                            {localErrors && Object.values(localErrors).length ? (
                                <div className='alert alert-danger text-center' role='alert'>
                                    {Object.values(localErrors).map((err, index) => {
                                        return <div key={index} dangerouslySetInnerHTML={{ __html: err }} />;
                                    })}
                                </div>
                            ) : null}
                            {error && (
                                <div>
                                    <ErrorMessage  code={error} collection='data.messages.ticketErrorMessages.jsonBlock' />
                                </div>
                            )}
                            <div className='form-group col'>
                                <h3>Double Check Your Information</h3>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <strong>First Name</strong>
                                            </td>
                                            <td>
                                                <span>{verifyFields.firstname}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Last Name</strong>
                                            </td>
                                            <td>
                                                <span>{verifyFields.lastname}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Date of Birth</strong>
                                            </td>
                                            <td>
                                                <span>{verifyFields.birthdate}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Home Address</strong>
                                            </td>
                                            <td>
                                                <span>
                                                    {verifyFields.address.address1}
                                                    <br />
                                                    {verifyFields.address.address2}
                                                    {verifyFields.address.address2 ? <br /> : null}
                                                    {verifyFields.address.city},{verifyFields.address.state},{verifyFields.address.zip}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Phone Number</strong>
                                            </td>
                                            <td>
                                                <span>{verifyFields.phone}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a href='#' className='edit-info' onClick={goBack}>
                                    Edit Information
                                </a>
                            </div>
                            <div className='form-group'>
                                <label className='col' for='confirmEmail'>
                                    Last 4 Digits of your Social Security Number*
                                </label>
                                <div className='col-3'>
                                    <PasswordField
                                        name='ssn'
                                        value={stepFields.ssn}
                                        maxLength={4}
                                        handleChange={handleChange}
                                        handleInput={handleInput}
                                        newPassword={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </ContentBoxBody>
                </ContentBox>
                <div className='bottom-section'>
                    <div>
                        <button type='submit' className='btn theme-btn continue' onClick={stepSubmit} disabled={checkSubmitDisabled(error)}>
                            Continue to Verification
                        </button>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const StepFour = connect(mapStateToProps, mapDispatchToProps)(StepFourTemplate);

export { StepFour };

import { createSlice } from '@reduxjs/toolkit';
import apiClient from '../Api/api_client';
import { loadingSlice } from './LoadingStore';
import { userSlice } from './UserStore';

export const defaultRewardState = {
    lastRequested: null, // possibly use this to trigger reloading?
    requestError: false,
    pageSize: 5,
    page: 1,
    total: 0,
    rewards: [],
};

// REDUCERS
// basic actions that affect this object only

const getRewardReducer = (state, action) => {
    const { rewards: newRewards, page, total } = action.payload;

    if (newRewards) {
        // TODO: There's a bug in the API that causes more results than expected to be returned, truncate it for now
        if (newRewards && newRewards.length > state.pageSize) {
            newRewards.length = state.pageSize;
        }

        // Merge newRewards in existing rewards making sure that they're slotted in the
        // right place (potentially overwriting existing records if the API messed up)
        let rewards = [...state.rewards];
        rewards.length = state.pageSize * (page - 1);
        newRewards && rewards.push(...newRewards);

        rewards = rewards.filter((item) => item.reward_id);
        //? for debug: console.log('🎁 getRewards:', { rewards, page, total });

        return {
            ...state,
            lastRequested: Date.now(),
            rewards,
            page,
            total,
        };
    } else {
        return state;
    }
};

const clearRewardReducer = (state, action) => {
    return defaultRewardState;
};

const setRequestErrorReducer = (state, action) => {
    return {
        ...state,
        lastRequested: Date.now(),
        requestError: true,
    };
};

export const rewardSlice = createSlice({
    name: 'reward',
    initialState: defaultRewardState,
    reducers: {
        getRewards: getRewardReducer,
        clearReward: clearRewardReducer,
        setRequestError: setRequestErrorReducer,
    },
    extraReducers: {
        [userSlice.actions.logout]: (state) => {
            return defaultRewardState;
        },
        // [entryFormSlice.actions.setEntryResponse]: state => {
        //     return defaultRewardState;
        // },
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const getRewards = async (dispatch, payload) => {
    dispatch(loadingSlice.actions.setLoading({ action: 'getReward' }));
    const result = await apiClient().getReward(payload);
    dispatch(rewardSlice.actions.getRewards(result));
    dispatch(loadingSlice.actions.unsetLoading({ action: 'getReward' }));
};

//actions that get exported to the views
export const rewardActions = (dispatch) => {
    return {
        getRewards: (payload) => getRewards(dispatch, payload),
        clearRewards: (payload) => dispatch(rewardSlice.actions.clearReward(payload)),
    };
};

import moment from 'moment';

// check if the date is 35 years in the future
export const isFutureDate = (inputDate) => {
    const futureDate = moment().add(35, 'y');
    return moment.unix(inputDate).isAfter(futureDate) ? true : false;
};

// format date to: Feb 13, 2022
export const formatDate = (inputDate) => {
    return isFutureDate(inputDate) ? 'TBD' : moment.unix(inputDate).format('MMM DD, YYYY');
};

// format date to: 02/24/2022
export const formatDateWithSlashes = (inputDate) => {
    return isFutureDate(inputDate) ? 'TBD' : moment.unix(inputDate).format('MM/DD/YYYY');
};

// format date to: Thu., Feb 24, 2022
export const formatDateWithDayOfTheWeek = (inputDate) => {
    return isFutureDate(inputDate) ? 'TBD' : moment.unix(inputDate).format('ddd., MMM DD, YYYY');
};

// format date to: Feb 13, 2022 23:59:59
export const formatDateTime = (inputDate) => {
    return isFutureDate(inputDate) ? 'TBD' : moment.unix(inputDate).format('MMM DD, YYYY kk:mm A');
};

// format date to: Feb 13, 2022 23:59:59
export const formatDateTimeWithSeconds = (inputDate) => {
    return isFutureDate(inputDate) ? 'TBD' : moment.unix(inputDate).format('MMM DD, YYYY kk:mm:ss');
};

// format date to: Mon. Feb 13, 2022 11:59 PM
export const formatDateTimeWithDayOfTheWeek = (inputDate) => {
    return isFutureDate(inputDate) ? 'TBD' : moment.unix(inputDate).format('ddd., MMM DD, YYYY hh:mm A');
};

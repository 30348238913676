import React, { useContext, useState, useEffect } from 'react';
import { DataProvider, useData } from './DataContext';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

// This component can be used inside another component that uses a DataProvider
// This component provides the data for the children to use
const SweepsDataTemplate = ({ sweep, actions }) => {
    const { state, dispatch } = useData();
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(async () => {
        if (!pageLoaded) {
            setPageLoaded(true);
            if (sweep.sweeps.length == 0) {
                await actions.sweepActions.getSweeps();
            }
        }
        dispatch({
            type: 'assign',
            payload: sweep.sweeps || [],
        });
    }, [sweep.sweeps]);

    return <></>;
};

const SweepsData = connect(
    mapStateToProps,
    mapDispatchToProps
)(SweepsDataTemplate);

export { SweepsData };

// [
//     {
//         id: 3,
//         quantum_start: 1633924800,
//         quantum_end: 1635825599,
//         draw_date: 1635825600,
//         certified: false,
//         state: 'active',
//         summarized: true,
//         extra: null,
//         drawing_group_id: '3',
//         sweepstake: '$10,000 50th Anniversary Sweeps',
//         drawing_group: 'Drawing 3',
//     },
//     {
//         id: 9,
//         quantum_start: 1633924800,
//         quantum_end: 1635825599,
//         draw_date: 1635825600,
//         certified: false,
//         state: 'active',
//         summarized: true,
//         extra: null,
//         drawing_group_id: '9',
//         sweepstake: '$20,000 50th Anniversary Sweeps',
//         drawing_group: 'Drawing 3',
//     },
// ]

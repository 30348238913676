import React, { useState, useEffect } from 'react';
import { DataProvider, useData } from './DataContext';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

// This component can be used inside another component that uses a DataProvider
// This component provides the data for the children to use
const ExpiredDrawingDataTemplate = ({ winner, actions }) => {
    const { state, dispatch } = useData();
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(async () => {
        if (!pageLoaded) {
            setPageLoaded(true);
            if (winner.expiredDrawings.length == 0) {
                await actions.winnerActions.getExpiredDrawings();
            }
        }
        dispatch({
            type: 'assign',
            payload: winner.expiredDrawings?.drawings || [],
        });
    }, [winner.expiredDrawings]);

    return <></>;
};

const ExpiredDrawingData = connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpiredDrawingDataTemplate);

export { ExpiredDrawingData };

/////////////// EXAMPLE DATA ////////////////
// {
//     type: 'winner/getExpiredDrawings',
//     payload: {
//         "total": 10,
//         "page": 1,
//         "drawings": [{
//             "id": 26,
//             "quantum_start": 1633924800,
//             "quantum_end": 1635220799,
//             "draw_date": 1635220800,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "26",
//             "sweepstake": "Event Day Sweepstakes",
//             "drawing_group": "Drawing 2"
//         }, {
//             "id": 19,
//             "quantum_start": 1633924800,
//             "quantum_end": 1635220799,
//             "draw_date": 1635220800,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "19",
//             "sweepstake": "$100,000 50th Anniversary Sweeps",
//             "drawing_group": "Drawing 2"
//         }, {
//             "id": 13,
//             "quantum_start": 1633924800,
//             "quantum_end": 1635220799,
//             "draw_date": 1635220800,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "13",
//             "sweepstake": "$50,000 50th Anniversary Sweeps",
//             "drawing_group": "Drawing 2"
//         }, {
//             "id": 8,
//             "quantum_start": 1633924800,
//             "quantum_end": 1635220799,
//             "draw_date": 1635220800,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "8",
//             "sweepstake": "$20,000 50th Anniversary Sweeps",
//             "drawing_group": "Drawing 2"
//         }, {
//             "id": 2,
//             "quantum_start": 1633924800,
//             "quantum_end": 1635220799,
//             "draw_date": 1635220800,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "2",
//             "sweepstake": "$10,000 50th Anniversary Sweeps",
//             "drawing_group": "Drawing 2"
//         }, {
//             "id": 24,
//             "quantum_start": 1633924800,
//             "quantum_end": 1634615999,
//             "draw_date": 1634616000,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "24",
//             "sweepstake": "Event Day Sweepstakes",
//             "drawing_group": "Drawing 1"
//         }, {
//             "id": 18,
//             "quantum_start": 1633924800,
//             "quantum_end": 1634615999,
//             "draw_date": 1634616000,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "18",
//             "sweepstake": "$100,000 50th Anniversary Sweeps",
//             "drawing_group": "Drawing 1"
//         }, {
//             "id": 12,
//             "quantum_start": 1633924800,
//             "quantum_end": 1634615999,
//             "draw_date": 1634616000,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "12",
//             "sweepstake": "$50,000 50th Anniversary Sweeps",
//             "drawing_group": "Drawing 1"
//         }, {
//             "id": 6,
//             "quantum_start": 1633924800,
//             "quantum_end": 1634615999,
//             "draw_date": 1634616000,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "6",
//             "sweepstake": "$20,000 50th Anniversary Sweeps",
//             "drawing_group": "Drawing 1"
//         }, {
//             "id": 1,
//             "quantum_start": 1633924800,
//             "quantum_end": 1634615999,
//             "draw_date": 1634616000,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "1",
//             "sweepstake": "$10,000 50th Anniversary Sweeps",
//             "drawing_group": "Drawing 1"
//         }]
//     }
// }

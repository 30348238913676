import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { getCMSObject, TeleScript } from 'sg-ui-components';

import { IfMobile } from '../IfMobile';

const ScannerTemplate = ({ ticket = { success: false }, children, actions, buttonText }) => {
    const initialState = { ticket1_1_1: '' };
    const telescript = getCMSObject('data.components.teleScripts.scanner.jsonBlock');

    // const [formState, setFormState] = useState(initialState);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        clearIfSuccess();
    }, [ticket]);

    // if the form submit was a success, we clear the form
    const clearIfSuccess = async () => {
        if (ticket.success) {
            // actions.drawTicketActions.reset();
            window.open('post_claim');
        }
    };

    const scanCallback = async (infoObject) => {
        let sub1 = infoObject.format.toUpperCase();
        let resultLength = infoObject.result.length;
        console.log('finished scan: ', infoObject);

        if (sub1 != 'UNDEFINED' && infoObject.result != null && infoObject.result != '') {
            if (resultLength < 35) {
                console.log('less than 35');
                await actions.drawTicketActions.enterDrawTicket({
                    ticket1_1_1: infoObject.result,
                });
            } else if (resultLength > 35) {
                console.log('more than 35');

                let webCodeBarCodeStringArray = infoObject.result.split('w');
                let webCodeBarCode = webCodeBarCodeStringArray[1];

                console.log(webCodeBarCode);
                if (webCodeBarCode.length === 25) {
                    await actions.drawTicketActions.enterDrawTicket({
                        ticket1_1_1: webCodeBarCode,
                    });
                } else {
                    console.warn('Request Failed. Web code does NOT contain > 35 characters');
                }
            } else {
                console.warn('invalid barcode.');
            }
        }
    };

    // fire the submission
    const handleSubmit = async (event) => {
        setDisabled(true);
        event.preventDefault();

        console.log('scanning');
        // window.MdiNativeShell =
        //     typeof window.MdiNativeShell === 'undefined'
        //         ? (function (barcode) {
        //               return {
        //                   scanBarcode: function (fn) {
        //                       fn({ format: 'DATA_MATRIX', result: barcode });
        //                   },
        //               };
        //           })('441002530000484294186417153002')
        //         : window.MdiNativeShell;

        const mdiIsNativeShell = () => {
            // an array (true) or null (false) is returned and cast to boolean
            return !!window.navigator.userAgent.match(/(Mdi Native Shell)/);
        };

        if (mdiIsNativeShell()) {
            await window.MdiNativeShell.scanBarcode(scanCallback);
        } else {
            console.warn('No scanner');
        }

        setDisabled(false);
    };

    return (
        <IfMobile>
            <button disabled={disabled} onClick={handleSubmit} type='button' className='btn theme-btn btn-primary scan-btn link-back-btn my-2 w-100'>
                {buttonText ?? <TeleScript line={telescript?.scannerButton}>Scan Ticket</TeleScript>}
            </button>
        </IfMobile>
    );
};

const Scanner = connect(mapStateToProps, mapDispatchToProps)(ScannerTemplate);

export default Scanner;

// var mdiIsNativeShell = () => {
//     // an array (true) or null (false) is returned and cast to boolean
//     return !!window.navigator.userAgent.match(/(Mdi Native Shell)/);
// };

// var startScan = () => {
//     var $input1 = $('#ticket1_1_1');
//     var $input2 = $('#ticket1_2_1');
//     var $input3 = $('#ticket1_2_2');
//     var $input4 = $('#ticket1_2_3');

//     var $draw1 = $('#ticket1_1_1');
//     var $draw2 = $('#ticket1_1_2');
//     var $draw3 = $('#ticket1_1_3');
//     var $draw4 = $('#ticket1_1_4');
//     var $draw5 = $('#ticket1_1_5');

//     $('#scanned-invalid-ticket').hide();

//     // these inputs should be cleared
//     var $clearVals = [
//         $input1,
//         $input2,
//         $input3,
//         $input4,
//         $draw1,
//         $draw2,
//         $draw3,
//         $draw4,
//         $draw5,
//     ];
//     // these inputs should have readonly removed
//     var $makeWriteable = [
//         $input1,
//         $input2,
//         $input3,
//         $input4,
//         $draw1,
//         $draw2,
//         $draw3,
//         $draw4,
//         $draw5,
//     ];

//     var scanCallback = (infoObject) => {
//         $clearVals.map(($item) => {
//             $item.val('');
//         });
//         $makeWriteable.map(($item) => {
//             $item.removeAttr('readonly');
//         });

//         var sub1 = infoObject.format.toUpperCase();
//         var resultLength = infoObject.result.length;
//         var eligibleGames = [5045, 5046, 1367, 1368, 1369, 1370, 1371, 1372];

//         if (
//             sub1 != 'UNDEFINED' &&
//             infoObject.result != null &&
//             infoObject.result != ''
//         ) {
//             if (resultLength < 35) {
//                 $.ajax({
//                     url: '/general/app_common/mobileAppEntry.php',
//                     type: 'post',
//                     async: false,
//                     dataType: 'json',
//                     data: { barcode: infoObject.result },
//                     success: function (res) {
//                         if (
//                             eligibleGames.includes(
//                                 window.parseInt(res.gameid, 10)
//                             )
//                         ) {
//                             $('#entry_method').val('SCAN_APP');
//                             $('#ticket1_1_1, #3ticket1_1_1').val(res.virn);
//                             $('[name="ticket1_2_1"]').val(res.gameid);
//                             $('#ticket1_2_2, #3ticket1_2_2').val(res.booknum);
//                             $('#ticket1_2_3, #3ticket1_2_3').val(res.tktnum);
//                             $('#promotion_game_id').val(res.gameid);
//                             $('.form-back-button').hide();
//                             window.showEntryForm(
//                                 window.parseInt(res.gameid, 10)
//                             );
//                         } else {
//                             $('#scanned-invalid-ticket').show();
//                             $('#entry-view, #desktop-landing').hide();
//                             $('#app-landing').show();
//                             $('.form-back-button').show();
//                         }
//                     },
//                     error: (err) => {
//                         console.warn('Request failed: ', err);
//                     },
//                 });
//             } else if (resultLength > 35) {
//                 var webCodeBarCodeStringArray = infoObject.result.split('w');
//                 var webCodeBarCode = webCodeBarCodeStringArray[1];

//                 if (webCodeBarCode.length === 25) {
//                     $('[name="entry_method"]').val('SCAN_APP');
//                     $draw1.val(webCodeBarCode.substr(0, 5));
//                     $draw2.val(webCodeBarCode.substr(5, 5));
//                     $draw3.val(webCodeBarCode.substr(10, 5));
//                     $draw4.val(webCodeBarCode.substr(15, 5));
//                     $draw5.val(webCodeBarCode.substr(20));
//                     $('.form-back-button').hide();
//                     window.showEntryForm('draw');
//                 } else {
//                     console.warn(
//                         'Request Failed. Web code does NOT contain > 35 characters'
//                     );
//                     $('#scanned-invalid-ticket').show();
//                     $('#entry-view, #desktop-landing').hide();
//                     $('#app-landing').show();
//                     $('.form-back-button').show();
//                 }
//             } else {
//                 console.warn('invalid barcode.');
//             }
//         }
//     };

//     window.MdiNativeShell.scanBarcode(scanCallback);
// };

// module.exports = {
//     mdiIsNativeShell,
//     mdiStartScan: startScan,
// };

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import * as yup from 'yup';

import { ErrorMessage } from 'sg-ui-components';

import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from '../ContentBox';

const UpdateNotification = ({ user, actions, optins }) => {
    const canEdit = user.player.actions.includes('verify-account') ? 0 : 1;

    //***********************************************************************************
    // Get all the Optins from the API (with player data)
    const getPlayerOptins = async () => {
        // Get the Optins from the Optin Store.
        await actions.optinsActions.getOptins();
        setLoaded(true);
    }; // end getAllOptins

    // Find option id for a specific type
    const findOptinID = (optins, type) => {
        if (optins) {
            const filteredOptinsByType = optins.filter((val) => {
                return val.type === type;
            })[0];

            return filteredOptinsByType && filteredOptinsByType.id ? filteredOptinsByType.id : null;
        } else return [];
    };

    // Find subcription id for a specific type
    const findSubscriptionID = (optins, type) => {
        const filteredOptinsByType = optins.filter((val) => {
            return val.type === type;
        })[0];

        return filteredOptinsByType && filteredOptinsByType.subscription && filteredOptinsByType.subscription[0] && filteredOptinsByType.subscription[0].id
            ? filteredOptinsByType.subscription[0].id
            : null;
    };

    const initialStepFields = {
        marketing_communication: false,
        optins: optins.playerOptins ?? [],
    };

    const schema = yup.object().shape({
        //? keeping this for later
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState('');
    const [localErrors, setLocalErrors] = useState({});
    //***********************************************************************************
    // Gets all the optins available (without any player data attached).
    // this could be a large list and will need to be filtered down by category
    const getAllOptins = async () => {
        await actions.optinsActions.getAllOptins();
    };

    const checkStatus = () => {
        setStepFields({
            ...stepFields,
            marketing_communication: findSubscriptionID(optins.playerOptins, 'marketing-communication') ? true : false,
        });
    };
    //***********************************************************************************
    // Gets all optins when component loads.
    useEffect(() => {
        if (!loaded) {
            getAllOptins();
            getPlayerOptins();
        }
        if (loaded) {
            checkStatus();
        }
    }, [loaded]); // end useEffect

    // if the form field changes, we will change the value in the store and
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trimStart();
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(stepFields);

        if (valid) {
            setError('');
            setLocalErrors({});

            const marketing_communication_id = findOptinID(optins?.allOptins, 'marketing-communication');

            //* Optin field check and setup
            if (stepFields.marketing_communication) {
                await actions.optinsActions.optinSubscribe({
                    notification_id: marketing_communication_id,
                    connection: 'string',
                    type: 'email',
                });
                await actions.userActions.updateSection({ section: 'notification', status: 'success' });
            } else {
                const subscription_id = findSubscriptionID(optins.playerOptins, 'marketing-communication');
                if (subscription_id) {
                    await actions.optinsActions.optinUnsubscribe({
                        notification_id: marketing_communication_id,
                        subscription_id: subscription_id,
                    });
                    await actions.userActions.updateSection({ section: 'notification', status: 'success' });
                } else {
                    await actions.userActions.updateSection({ section: 'notification', status: 'success' });
                }
            }
        } else {
            await schema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                setLocalErrors(getValidationErrors(err));
                setError(err.message);
            });
        }
    };

    const getValidationErrors = (err) => {
        const validationErrors = {};

        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);

        return validationErrors;
    };

    return (
        <ContentBox variant='theme-blue'>
            <ContentBoxHead>NOTIFICATION OPTIONS</ContentBoxHead>
            <ContentBoxBody>
                <div className='registration-step'>
                    <div className='inner-step'>
                        {error ? (
                            <div className='alert alert-danger text-center' role='alert'>
                                {Object.values(localErrors).map((err, index) => {
                                    return <p key={index}>{err}</p>;
                                })}
                            </div>
                        ) : null}

                        {user.updateSection.section === 'notification' && user.updateSection.status === 'success' && (
                            <div className='alert alert-success text-center' role='alert'>
                                Notification Information updated.
                            </div>
                        )}

                        {user.updateSection.section === 'notification' && user.updateSection.status === 'error' && (
                            <div className='alert alert-danger text-center' role='alert'>
                                <ErrorMessage mode='return' code={user.updateSection.code} collection='data.messages.ticketErrorMessages.jsonBlock' />
                            </div>
                        )}

                        <div className='form-row m-2'>
                            <div className='form-group form-check'>
                                <input
                                    type='checkbox'
                                    id='marketing_communication'
                                    name='marketing_communication'
                                    value={stepFields.marketing_communication}
                                    onChange={handleChange}
                                    checked={stepFields.marketing_communication}
                                    className='form-check-input'
                                    disabled={!canEdit}
                                />
                                <label htmlFor='marketing_communication' className='form-check-label'>
                                    I would like to receive account related updates, news alerts, special offers, &amp; communications from the Connecticut
                                    Lottery.
                                </label>
                            </div>
                        </div>

                        {canEdit ? (
                            <p className='text-center my-2'>
                                Be sure to click the Update Information button when you are done or your changes will not be saved.
                            </p>
                        ) : (
                            <p className='text-center my-2'>You need to confirm your account to update your notification settings.</p>
                        )}

                        <button type='button' className='btn theme-btn btn-block theme-gray' onClick={handleSubmit} disabled={!canEdit}>
                            Update Information
                        </button>
                    </div>
                </div>
            </ContentBoxBody>
        </ContentBox>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateNotification);

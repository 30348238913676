import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from 'react-bootstrap';
import UserLockout from '../../utils/user_lockout';
import WinnerLink from '../Sweeps/WinnerLink';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import { formatDate, formatDateTimeWithDayOfTheWeek as formatDateTime } from '../../utils/formatDates';

class DrawingEntries extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            pagesize: 5,
            isLoaded: props.isLoaded,
        };
    }

    render() {
        const drawingsAll = this.props.winner.currentDrawings;

        const drawings = drawingsAll;

        // if there are more rewards, show the link for more
        const loadMoreLink =
            drawings.length > 0 && drawings.length === this.state.pagesize * this.state.page ? (
                <div className='text-center'>
                    <Button variant='outline-secondary' className='btn theme-btn theme-outline' onClick={this.getMoreRewards}>
                        View More
                    </Button>
                </div>
            ) : null;

        const desktopDrawEntries = (rewards) => {
            if (!rewards || drawings.length === 0) {
                return (
                    <tbody>
                        <tr key='0'>
                            <td colSpan='4'>
                                <em>{this.state.isLoaded ? 'No entries submitted.' : '. . .'}</em>
                            </td>
                        </tr>
                    </tbody>
                );
            } else {
                // note we are spreading entries into a new array so we don't mutate the
                // entries array in state
                return (
                    <tbody>
                        {[...drawings].map(
                            ({ id, sweepstake, sweepstake_id, quantum_start, drawing_group, quantum_end, entry_count, draw_date, certified }) => {
                                return (
                                    <tr key={id}>
                                        <td>
                                            <a href={`/promotion?id=${sweepstake_id}`}>{sweepstake}</a>
                                        </td>
                                        <td>
                                            {formatDateTime(quantum_start)} - {formatDateTime(quantum_end)}
                                        </td>
                                        <td>{entry_count ?? 0}</td>
                                        <td>
                                            <div>{formatDate(draw_date)}</div>
                                            {certified && (
                                                <div>
                                                    <WinnerLink selected={{ id }} />
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                );
            }
        };

        const desktopEntrylist = (drawings) => {
            return (
                <table id='entryTable' className='table entry-table d-none d-md-table text-center'>
                    <thead>
                        <tr>
                            <th>PROMOTION</th>
                            <th>ENTRY PERIOD</th>
                            <th>MY ENTRIES</th>
                            <th>DRAWING DATE</th>
                        </tr>
                    </thead>
                    {desktopDrawEntries(drawings)}
                </table>
            );
        };

        const mobileEntrylist = (drawings) => {
            if (!drawings || drawings.length === 0) {
                return (
                    <div className='d-md-none stacked-rewards-container'>
                        <div className='row'>
                            <div className='col'>Drawings</div>
                            <div className='col'>
                                <em>{this.state.isLoaded ? 'No drawings.' : '. . .'}</em>
                            </div>
                        </div>
                    </div>
                );
            } else {
                // note we are spreading entries into a new array so we don't mutate the
                // entries array in state
                return (
                    <div className='d-md-none stacked-rewards-container'>
                        {[...drawings].map(({ id, sweepstake, sweepstake_id, quantum_start, quantum_end, entry_count, draw_date, certified }) => {
                            return (
                                <div className='row' key={id}>
                                    <div className='col'>
                                        <table className='table'>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>Promotion</strong>
                                                    </td>
                                                    <td>
                                                        <a href={`/promotion?id=${sweepstake_id}`}>{sweepstake}</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Entry Period</strong>
                                                    </td>
                                                    <td>
                                                        {formatDateTime(quantum_start)} - {formatDateTime(quantum_end)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>My Entries</strong>
                                                    </td>
                                                    <td>{entry_count ?? 0}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Drawing Date</strong>
                                                    </td>
                                                    <td>
                                                        <div>{formatDate(draw_date)}</div>
                                                        {certified && (
                                                            <div>
                                                                <WinnerLink selected={{ id }} />
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            }
        };

        //? for debug:
        //console.log('🚩 Current all drawings:', drawingsAll);
        //console.log('🚩 Current drawings:', drawings);

        return (
            <UserLockout>
                <div className='row'>
                    <div className='col'>
                        {desktopEntrylist(drawings)}
                        {mobileEntrylist(drawings)}
                        {loadMoreLink}
                    </div>
                </div>
            </UserLockout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawingEntries);

import { createSlice } from '@reduxjs/toolkit';

export const defaultNotificationState = {
    notification: '',
};

// REDUCERS

const setNotificationReducer = (state, action) => {
    return {
        notification: action.payload.notification,
    };
};

const unsetNotificationReducer = (state, action) => {
    return {
        notification: '',
    };
};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: defaultNotificationState,
    reducers: {
        // add an action to chceck localstorage
        // for existing session
        setNotification: setNotificationReducer,
        unsetNotification: unsetNotificationReducer,
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const setNotification = (dispatch, payload) => {
    dispatch(notificationSlice.actions.setNotification(payload));
    setTimeout(function () {
        dispatch(notificationSlice.actions.unsetNotification(payload));
    }, 5000);
};

const unsetNotification = (dispatch, payload) => {
    dispatch(notificationSlice.actions.unsetNotification(payload));
};

export const notificationActions = (dispatch) => {
    return {
        // dispatching plain actions
        setNotification: (payload) => setNotification(dispatch, payload),
        unsetNotification: (payload) => unsetNotification(dispatch, payload),
    };
};

import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './styling/global.scss';
import { ErrorBoundary, TeleScript, getCMSObject } from 'sg-ui-components';

import { createStore } from './Store';
import Application from './app/application.js';
import Home from './app/pages/home.js';

const app = document.getElementById('root');

const errorBoundaryTelescript = getCMSObject('data.messages.pageErrorMessages.jsonBlock');

render(
    <Provider store={createStore()}>
        <ErrorBoundary fallback={<TeleScript line={errorBoundaryTelescript?.rootFallback} />}>
            <Router basename='/'>
                <Application>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Switch>
                            <Route
                                exact
                                path='/'
                                render={() => {
                                    return <Redirect to='/home' />;
                                }}
                            />
                            <Route path='/:page' component={Home} />
                        </Switch>
                    </Suspense>
                </Application>
            </Router>
        </ErrorBoundary>
    </Provider>,
    app
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

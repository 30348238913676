import React, { useContext, useState, useEffect } from 'react';
import { DataProvider, useData } from '../DataProviders/DataContext';

// EXAMPLE OF USE IN CMS
/*
<GTable>
    <CurrentDrawingData />
    <tbody>
        <TRData>
            <TData name="id">
                <ShowData />
            </TData>
            <TData name="sweepstake">
                <ShowData />
            </TData>
            <TData name="drawing_group">
                <ShowData />
            </TData>
        </TRData>
    </tbody>
</GTable>
*/

// Can use data from CurrentDrawingData, ExpiredDrawingData, SweepsData
const GTable = ({ children }) => {
    return (
        <DataProvider>
            <table className="table entry-table">{children}</table>
        </DataProvider>
    );
};

const THead = ({ children }) => {
    return <thead>{children}</thead>;
};

const TBody = ({ children }) => {
    return <tbody>{children}</tbody>;
};

const TRData = ({ children }) => {
    const { state, dispatch } = useData();

    const renderChildren = (index) => {
        //gotta keep track of all of the tds we want to name
        let childrenList = React.Children.map(children, (child, i) => {
            if (i < 0 || i >= children.length) return null;
            if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                    index: index || 0,
                });
            }
        });

        return childrenList;
    };

    return state.data.map((val, index) => {
        return <tr key={index}>{renderChildren(index)}</tr>;
    });
};

const TH = ({ children }) => {
    return <th>{children}</th>;
};

const TData = ({ index, children, name }) => {
    const { state, dispatch } = useData();

    const renderChildren = (data) => {
        //gotta keep track of all of the tds we want to name
        let childrenList = React.Children.map(children, (child, i) => {
            if (i < 0 || i >= children.length) return null;

            if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                    // name: child.props.name || fields[found.count - 1].name,
                    data: data || null,
                });
            } else {
                return child;
            }
        });

        return childrenList;
    };

    if (state.data[index] && state.data[index][name] != null) {
        return <td>{renderChildren(state.data[index][name])}</td>;
    } else return <td>none</td>;
};

const ShowData = ({ data }) => {
    return <div>{data}</div>;
};

export { GTable, THead, TBody, TRData, TH, TData, ShowData };

import React, { useEffect } from 'react';
import Footer from './Layouts/Footer';
import Header from './Layouts/Header';
import { IfNotMobile } from '../Components/IfNotMobile.js';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import { camelCase } from 'lodash';
import { CMSContent, StyleOverrides, scrollToHashElement, TeleScript, ErrorBoundary, getCMSObject } from 'sg-ui-components';
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import IdleTimeout from './IdleTimeout';
import promotion_config from '../promotion_config';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';

const Layout = ({ page, user, config, cmsSourceFirebase, actions, children, path, pageData, mainMenu }) => {
    const errorBoundaryFallback = getCMSObject('data.messages.pageErrorMessages.jsonBlock')?.[`${camelCase(path) || 'home'}Fallback`];

    //? Note: Frontend config will be requested and stored on first pageload / refresh
    useEffect(() => {
        async function configStateActions() {
            await actions.configActions.getConfig();
        }

        if (!config?.config?.firebase) {
            configStateActions();
        }
    }, []);

    //? Note: Firebase CMS webContent and webMenu will be requested and stored on first pageload / refresh
    useEffect(() => {
        async function pageStateActions() {
            await actions.cmsSourceFirebaseActions.storeAllData({
                config: promotion_config.testing?.firebase ?? config.config.firebase,
                docName: 'webContent',
            });

            await actions.cmsSourceFirebaseActions.storeAllData({
                config: promotion_config.testing?.firebase ?? config.config.firebase,
                docName: 'webMenu',
            });

            await actions.cmsSourceFirebaseActions.setLoaded(true);
        }

        if (config?.config?.firebase && !cmsSourceFirebase?.loaded) {
            pageStateActions();
            scrollToHashElement();
        }
    }, [config]);

    useEffect(() => {
        async function playerStateActions() {
            await actions.userActions.getPlayerData();

            if (user.loggedIn) {
                await actions.revealTokenActions.getRevealToken();
            }
        }
        playerStateActions();
    }, [user?.loggedIn]);

    // Get GTM ID based on the environment from the config api
    if (config?.config?.gtm_id) {
        ReactGA.initialize(config?.config?.gtm_id);
    }

    return (
        <>
            <IfNotMobile>
                <CMSContent
                    cmsSourceFirebase={cmsSourceFirebase}
                    localStorageObject='webContent'
                    contentPath='data.webNotices'
                    className='fixed-top'
                    noticePlacement='web-notice-top-fixed'
                />
                <CMSContent
                    cmsSourceFirebase={cmsSourceFirebase}
                    localStorageObject='webContent'
                    contentPath='data.webNotices'
                    className=''
                    noticePlacement='web-notice-top'
                />
                <Header pageData={pageData} />
                <CMSContent
                    cmsSourceFirebase={cmsSourceFirebase}
                    localStorageObject='webContent'
                    contentPath='data.webNotices'
                    className=''
                    noticePlacement='web-notice-below-header'
                />
            </IfNotMobile>
            <ErrorBoundary fallback={<TeleScript line={errorBoundaryFallback} />} details={{ page, user, config }}>
                <div className='main'>
                    <div className='page-content'>{children}</div>
                </div>
            </ErrorBoundary>
            <IfNotMobile>
                <CMSContent
                    cmsSourceFirebase={cmsSourceFirebase}
                    localStorageObject='webContent'
                    contentPath='data.webNotices'
                    className='fixed-bottom'
                    noticePlacement='web-notice-bottom-fixed'
                />
                <Footer secondaryMenu={mainMenu.secondary_nav} copy={pageData.copy} actions={actions} user={user}></Footer>
            </IfNotMobile>

            <IdleTimeout />
            <StyleOverrides cmsSourceFirebase={cmsSourceFirebase} />
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

import React, { useState, useEffect } from 'react';
import Cleave from 'cleave.js/react';
import * as yup from 'yup';
import moment from 'moment';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from '../ContentBox';

export const StepTwo = ({ user, step, setStep, verifyFields, setVerifyFields }) => {
    let firstName = !user.player.first_name ? user.player.firstname : user.player.first_name;
    let lastName = !user.player.last_name ? user.player.lastname : user.player.last_name;
    let birthDate = !user.player.birthday ? user.player.birthdate : user.player.birthday;

    let formattedBirthday = birthDate ? birthDate.toString().split('-') : '';
    formattedBirthday = formattedBirthday[2] + '-' + formattedBirthday[1] + '-' + formattedBirthday[0];

    const initialStepFields = {
        firstname: firstName ? firstName : '',
        lastname: lastName ? lastName : '',
        birthdate: formattedBirthday,
        gender: user.player.extra && user.player.extra.gender !== null ? user.player.extra.gender : '',
    };



    const schema = yup.object().shape({
        firstname: yup.string().max(25, 'First name cannot exceed 25 characters').required('First Name is required'),

        lastname: yup.string().max(25, 'Last name cannot exceed 25 characters').required('Last Name required'),

        birthdate: yup
            .string()
            .test('birthdate', 'Must be 18 or older to register', (value) => {
                let birthdate_YMD = value.split('-');
                birthdate_YMD = `${birthdate_YMD[2]}-${birthdate_YMD[0]}-${birthdate_YMD[1]}`;
                return moment().diff(moment(birthdate_YMD).format('YYYY-MM-DD'), 'years') >= 18;
            })
            .test('birthdate', 'Date of Birth is Required', (value) => {
                const birthdate_YMD = value.split('-');
                return birthdate_YMD[2] >= 1900;
            })
            .test('birthdate', 'Date of Birth is Required', (value) => {
                let birthdate_YMD = value.split('-');
                birthdate_YMD = `${birthdate_YMD[2]}-${birthdate_YMD[0]}-${birthdate_YMD[1]}`;
                return moment().diff(moment(birthdate_YMD).format('YYYY-MM-DD'), 'years') <= 150;
            })
            .required('Date of Birth is required'),

        gender: yup.string().required('Gender is required'),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState('');
    const [localErrors, setLocalErrors] = useState({});

    // if the form field changes, we will change the value in the store and
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trimStart();
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });
    };

    const secondStepSubmit = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(stepFields);

        if (valid) {
            let birthday = stepFields.birthdate.split('-');
            setError('');
            setLocalErrors({});
            setVerifyFields({
                ...verifyFields,
                firstname: stepFields.firstname,
                lastname: stepFields.lastname,
                birthdate: `${birthday[2]}-${birthday[0]}-${birthday[1]}`,
                gender: stepFields.gender,
            });
            setStep({ step: 3 });
        } else {
            await schema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                setLocalErrors(getValidationErrors(err));
                setError(err.message);
            });
        }
    };

    const getValidationErrors = (err) => {
        const validationErrors = {};

        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);

        return validationErrors;
    };

    if (step.step == 2) {
        return (
            <div className='registration-step'>
                <ContentBox>
                    <ContentBoxHead className='registration-step-title'>Identification</ContentBoxHead>
                    <ContentBoxBody>
                        <div className='inner-step'>
                            {error ? (
                                <div className='alert alert-danger text-center' role='alert'>
                                    {Object.values(localErrors).map((err) => {
                                        return (
                                            <>
                                                <p>{err}</p>
                                                <br />
                                            </>
                                        );
                                    })}
                                </div>
                            ) : null}
                            <div className='form-group'>
                                <label for='firstname'>First Name *</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='enterEmail'
                                    value={stepFields.firstname}
                                    name='firstname'
                                    onChange={handleChange}
                                    aria-describedby='emailHelp'
                                    placeholder=''
                                />
                            </div>
                            <div className='form-group'>
                                <label for='lastname'>Last Name *</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='lastname'
                                    value={stepFields.lastname}
                                    name='lastname'
                                    onChange={handleChange}
                                    placeholder=''
                                />
                            </div>
                            <div className='form-group'>
                                <label for='birthdate'>DOB*</label>
                                <Cleave
                                    className='form-control col-md-3'
                                    placeholder='MM-DD-YYYY'
                                    id='birthdate'
                                    value={stepFields.birthdate}
                                    name='birthdate'
                                    options={{
                                        date: true,
                                        delimiter: '-',
                                        datePattern: ['m', 'd', 'Y'],
                                    }}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='form-group'>
                                <label for='gender'>Gender*</label>
                                <select name='gender' value={stepFields.gender} onChange={handleChange} className='required form-control  col-md-4 gender'>
                                    <option value=''>Select One</option>
                                    <option value='M'>Male</option>
                                    <option value='F'>Female</option>
                                    <option value='O'>Other</option>
                                    <option value='H'>Prefer not to share</option>
                                </select>
                            </div>
                        </div>
                    </ContentBoxBody>
                </ContentBox>
                <div className='bottom-section'>
                    <div>
                        <button type='button' className='btn theme-btn continue' onClick={secondStepSubmit}>
                            Continue to Contact Info.
                        </button>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

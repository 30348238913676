import { createSlice } from '@reduxjs/toolkit';
import promotion from '../promotion_config';
import cms_data from '../cms/cms_data.json';

const { projectFirestore } = require('../firebase/webConfig');

export const defaultStorageState = {
    ready: true,
};

// REDUCERS
// basic actions that affect this object only

const setReadyReducer = (state, action) => {
    return {
        ...state,
        ready: action.payload,
    };
};

export const cmsSourceSlice = createSlice({
    name: 'cms_source',
    initialState: defaultStorageState,
    reducers: {
        setReady: setReadyReducer,
    },
});

// ACTUAL ACTIONS
// a place to make the actions more complex if necessary

const storeAllData = async (dispatch, payload) => {
    const promo = promotion.promotion;

    var db = window.localStorage;
    dispatch(cmsSourceSlice.actions.setReady(false));
    if (!db.getItem('pulling')) {
        db.setItem('pulling', 'true');
    }
    if (db.getItem('pulling') == 'true' && (!db.getItem('timestamp') || Date.now() - 1000 * 10 > parseInt(db.getItem('timestamp')))) {
        await db.setItem('timestamp', Date.now());
        Object.keys(cms_data).forEach((k) => {
            if (cms_data[k]?.type === 'image') {
                db.setItem(k, cms_data[k].body);
            } else {
                db.setItem(k, JSON.stringify(cms_data[k]));
            }
        });

        //? for debug: console.log('stored pages');
    }
    dispatch(cmsSourceSlice.actions.setReady(true));
};

//actions that get exported to the views
export const cmsSourceActions = (dispatch) => {
    return {
        storeAllData: (payload) => storeAllData(dispatch, payload),
    };
};

import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';

import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from './ContentBox';

import isBrowser from '../utils/isBrowser';
import UserLockout from '../utils/user_lockout';

//* Retrieve ID from url params
const queryString = isBrowser() ? window.location.search : '';
const urlParams = new URLSearchParams(queryString);
const queryParams = {
    passcode: urlParams.get('passcode'),
    mode: urlParams.get('mode'),
};

const getPageMode = (mode) => {
    if (mode === 'confirm-email') return mode;
    if (mode === 'help-to-verify') return mode;

    return 'confirm-email';
};

export class AccountConfirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: getPageMode(queryParams.mode),
        };
    }

    async validateUser(params) {
        if (this.props.user.verifySuccess) this.setState({ mode: 'success' });

        if (params.passcode) {
            await this.props.actions.userActions.confirm({ passcode: params.passcode });
        }

        if (this.props.user.lastServerResponse.type === 'confirm') {
            if (this.props.user.lastServerResponse.message.code !== '_NOT_FOUND') {
                this.setState({ mode: 'success' });
            }
        }
    }

    async resendConfirmEmail() {
        await this.props.actions.loadingActions.setLoading({ action: 'confirmResend' });

        await this.props.actions.userActions.updateSection({ section: 'resend-confirm-email', status: 'initial' });

        const payload = {
            registrar_id: this.props.user.player.registrar_id ?? '',
        };

        await this.props.actions.userActions.confirmResend(payload);
    }

    componentDidMount() {
        this.validateUser(queryParams);
    }

    render() {
        return (
            <>
                <div className='my-4' />

                {this.state.mode === 'confirm-email' && (
                    <UserLockout disableRedirect='account-confirm'>
                        <ContentBox variant='theme-blue'>
                            <ContentBoxHead className='registration-step-title'>Confirm Your Email</ContentBoxHead>
                            <ContentBoxBody>
                                <div className='inner-step'>
                                    <div className='text-center'>
                                        {this.props.user.updateSection.section === 'resend-confirm-email' &&
                                            this.props.user.updateSection.status === 'success' && (
                                                <div className='alert alert-success text-center' role='alert'>
                                                    An email has been resent. Please follow the link in your email to confirm your account.
                                                </div>
                                            )}

                                        {this.props.user.updateSection.section === 'resend-confirm-email' &&
                                            this.props.user.updateSection.status === 'error' && (
                                                <div className='alert alert-danger text-center' role='alert'>
                                                    We could not send the email. Please, try again later.
                                                </div>
                                            )}

                                        <p>
                                            We’ve sent an email {this.props.user.email ? ` to ${this.props.user.email} ` : ``} for confirmation.
                                            <br />
                                            If you did not receive the email you can resend or contact us for help.
                                        </p>

                                        <button
                                            type='button'
                                            className='btn theme-btn btn-block theme-gray my-4'
                                            onClick={() => this.resendConfirmEmail()}
                                            disabled={this.props.loading.actions['confirmResend']}>
                                            Resend Confirmation Email
                                        </button>

                                        <a className='btn theme-btn btn-block theme-gray my-4' href='/feedback#contact'>
                                            Contact Us
                                        </a>

                                        <div className='my-2' />

                                        <a href='/feedback#contact'>Wrong email? Click here to edit.</a>
                                    </div>
                                </div>
                            </ContentBoxBody>
                        </ContentBox>
                    </UserLockout>
                )}

                {this.state.mode === 'help-to-verify' && (
                    <ContentBox variant='theme-blue'>
                        <ContentBoxHead className='registration-step-title'>Can't find your email?</ContentBoxHead>
                        <ContentBoxBody>
                            <div className='inner-step'>
                                <div className='text-center'>
                                    <p>
                                        It seems like you can’t find or did not receive the confirmation email we sent to username@domain.com.
                                        <br />
                                        Fill out a help form and we will contact you to verify.
                                    </p>

                                    <a className='btn theme-btn btn-block theme-gray my-4' href='/feedback#contact'>
                                        Contact Us
                                    </a>

                                    <div className='my-2' />

                                    <a href='/feedback#contact'>Wrong email? Click here to edit.</a>
                                </div>
                            </div>
                        </ContentBoxBody>
                    </ContentBox>
                )}

                {this.state.mode === 'success' && (
                    <ContentBox variant='theme-green'>
                        <ContentBoxHead className='registration-step-title'>You're ready to go!</ContentBoxHead>
                        <ContentBoxBody>
                            <div className='inner-step'>
                                <div className='text-center'>
                                    <p>Your account has been confirmed and you now have access to all 2nd Chance features!</p>
                                </div>

                                <a className='btn theme-btn btn-block theme-gray my-4' href='/home'>
                                    Continue to Home
                                </a>
                            </div>
                        </ContentBoxBody>
                    </ContentBox>
                )}
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountConfirm);

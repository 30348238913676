import React, { useState, useEffect } from 'react';
import { PasswordField } from './password_field';
import * as yup from 'yup';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import CustomerContent from '../CustomerContent';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from '../ContentBox';
import { validatePassword, validatePasswordDetailed } from '../../validationRules';
import { ErrorMessage } from 'sg-ui-components';
import { HashLink } from 'react-router-hash-link';

const StepOneTemplate = ({ step, setStep, verifyFields, setVerifyFields, user, actions }) => {
    const initialStepFields = {
        email: '',
        confirm_email: '',
        password: '',
        confirm: '',
        agreement: false,
        optins: [{ type: 'marketing-communication', subscriptions: [{ type: 'email' }] }],
        marketing_communication: true,
    };

    const schema = yup.object().shape({
        email: yup.string().email('Email is invalid').max(200, 'Email cannot exceed 200 characters').required('Email is required'),

        confirm_email: yup
            .string()
            .oneOf([yup.ref('email'), null], 'Email address must match')
            .required('Email confirmation required'),

        password: validatePassword,

        confirm: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Password Confirmation is required'),

        agreement: yup.boolean().oneOf([true], 'Please agree to the terms before continuing'),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState('');
    const [passwordStatus, setPasswordStatus] = useState('initial');
    const [localErrors, setLocalErrors] = useState({});

    //* clean input
    const cleanInput = (name, value) => {
        let output = value;

        // remove all whitespaces
        if (['email', 'confirm_email'].includes(name)) {
            output = output.replace(/\s/gi, '');
        }

        return output;
    };

    // if the form field changes, we will change the value in the store and
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : cleanInput(target.name, target.value);
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });

        //* Optin field check and setup
        if (name === 'marketing_communication') {
            const optinValue = value
                ? [
                      {
                          type: 'marketing-communication',
                          subscriptions: [
                              {
                                  type: 'email',
                              },
                          ],
                      },
                  ]
                : [];

            setStepFields({
                ...stepFields,
                [name]: value,
                ['optins']: optinValue,
            });
        }

        //* Password inner change
        if (name === 'password') {
            checkPassword(value);
        }
    };

    //  Inline validation for Password to make sure that it matches the password Schema
    const checkPassword = async (password) => {
        const passwordSchema = validatePasswordDetailed;

        const valid = await passwordSchema.isValid(password);

        if (valid) {
            setPasswordStatus('valid');
        } else {
            await passwordSchema.validate(password, { abortEarly: true }).catch(function (err) {
                setPasswordStatus(err.message);
            });
        }
    };

    const passwordStatusForm = (status) => {
        if (status === 'initial') return null;

        return (
            <>
                {status === 'valid' ? (
                    <div className='alert alert-success p-2 my-2' role='alert'>
                        Your password matches the criteria.
                    </div>
                ) : (
                    <div className='alert alert-danger p-2 my-2' role='alert'>
                        {status}
                    </div>
                )}
            </>
        );
    };

    const initialRegister = async () => {
        await actions.userActions.register(stepFields);
    };

    const firstStepSubmit = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(stepFields);

        if (valid) {
            setError('');
            setLocalErrors({});
            setVerifyFields({
                ...verifyFields,
                email: stepFields.email,
            });
            initialRegister();
        } else {
            await schema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                setLocalErrors(getValidationErrors(err));
                setError(err.message);
            });
        }
    };

    const getValidationErrors = (err) => {
        const validationErrors = {};

        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);

        return validationErrors;
    };

    const success = async () => {
        if (!user.registerSuccess) {
            if (user.errors) setError(user.errors);
        } else {
            if (user.registerSuccess) {
                setError('');
                setLocalErrors({});
                await actions.userActions.clearErrors();
                await actions.userActions.login({
                    email: stepFields.email,
                    password: stepFields.password,
                });
            }
        }
    };

    const loginSuccess = async () => {
        if (!user.loggedIn) {
            if (user.errors) setError(user.errors);
        } else {
            if (user.loggedIn) {
                setError('');
                setLocalErrors({});
                setVerifyFields({
                    ...verifyFields,
                    email: user.email,
                });
                actions.userActions.clearErrors();
                setStep({ step: 2 });
            }
        }
    };

    useEffect(success, [user]);
    useEffect(loginSuccess, [user.loggedIn]);

    if (step.step == 1) {
        return (
            <div className='registration-step'>
                <ContentBox>
                    <ContentBoxHead className='registration-step-title'>Login Information</ContentBoxHead>
                    <ContentBoxBody>
                        <div className='inner-step'>
                            <div>
                                <CustomerContent content='ensemble_site_registration_step1' />
                            </div>

                            {error && localErrors && Object.entries(localErrors).length > 0 ? (
                                <div className='alert alert-danger text-center' role='alert'>
                                    {Object.values(localErrors).map((err) => {
                                        return <p>{err}</p>;
                                    })}
                                </div>
                            ) : null}

                            {error && localErrors && Object.entries(localErrors).length === 0 ? (
                                <ErrorMessage code={error} collection='data.messages.ticketErrorMessages.jsonBlock' />
                            ) : null}

                            <div className='form-group'>
                                <label for='enterEmail'>Email</label>
                                <input
                                    type='text'
                                    className='form-control '
                                    id='enterEmail'
                                    value={stepFields.email}
                                    name='email'
                                    onChange={handleChange}
                                    aria-describedby='emailHelp'
                                    placeholder='Enter email'
                                />
                                <small id='emailHelp' className='form-text text-muted'>
                                    username@domain.com
                                </small>
                            </div>
                            <div className='form-group'>
                                <label for='confirmEmail'>Confirm Email</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='confirmEmail'
                                    value={stepFields.confirm_email}
                                    name='confirm_email'
                                    onChange={handleChange}
                                    aria-describedby='confirmEmailHelp'
                                    placeholder='Confirm email'
                                />
                                <small id='confirmEmailHelp' className='form-text text-muted'>
                                    Please retype your email address.
                                </small>
                            </div>

                            <div className='form-group'>
                                <label for='password'>Password</label>
                                <PasswordField label="password" name='password' value={stepFields.password} handleChange={handleChange} newPassword={true} />
                                <small id='passwordHelp' className='form-text text-muted'>
                                    <ul className='mx-0'>
                                        <li>Minimum password length: 10</li>
                                        <li>All characters are accepted</li>
                                        <li>At least one lower case</li>
                                        <li>At least one uppercase</li>
                                        <li>At least one number</li>
                                        <li>At least one special character</li>
                                    </ul>
                                </small>
                                {passwordStatusForm(passwordStatus)}
                            </div>

                            <div className='form-group'>
                                <label for='confirmPassword'>Confirm Password</label>
                                <PasswordField label="confirm password" name='confirm' value={stepFields.confirm} handleChange={handleChange} newPassword={true} />
                                <small id='confirmHelp' className='form-text text-muted'>
                                    Please retype your password.
                                </small>
                            </div>
                        </div>
                    </ContentBoxBody>
                </ContentBox>
                <div className='bottom-section'>
                    <div>
                        <div className='form-group form-check'>
                            <input
                                type='checkbox'
                                id='agreement'
                                name='agreement'
                                value={stepFields.agreement}
                                onChange={handleChange}
                                checked={stepFields.agreement}
                                className='form-check-input'
                            />
                            <label htmlFor='agreement' className='form-check-label'>
                                I agree to the &nbsp;
                                <HashLink to={'/feedback#terms'} activeClassName='active'>
                                    Terms of Use
                                </HashLink>
                            </label>
                        </div>
                        <div className='form-group form-check'>
                            <input
                                type='checkbox'
                                id='marketing_communication'
                                name='marketing_communication'
                                value={stepFields.marketing_communication}
                                onChange={handleChange}
                                checked={stepFields.marketing_communication}
                                className='form-check-input'
                            />
                            <label htmlFor='marketing_communication' className='form-check-label'>
                                I would like to receive account related updates, news alerts, special offers, <br />
                                &amp; communications from the Connecticut Lottery.
                            </label>
                        </div>
                        <div className='mb-4'>
                            <small id='all-fields' className='form-text text-muted'>
                                All fields with * are required
                            </small>
                        </div>
                    </div>

                    <div>
                        <button type='button' className='btn theme-btn continue' onClick={firstStepSubmit}>
                            Login and Continue Registration
                        </button>
                    </div>
                    <div className='text-center m-2'>
                        <p>
                            <a href='/login'>Already have an account? Login Now</a>
                        </p>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const StepOne = connect(mapStateToProps, mapDispatchToProps)(StepOneTemplate);

export { StepOne };

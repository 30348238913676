import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import IfLoggedIn from './IfLoggedIn';
import IfLoggedOut from './IfLoggedOut';
import { Welcome, LogoutButton, LoginButton, RegisterButton, AccountButton } from './Layouts/Header/welcome.js';

import featuredImage from '../assets/images/second_chance_burst.png';

const HomeLoginBox = () => {
    return (
        <div className='h-100 d-flex flex-row align-items-center login-box'>
            <div className='mx-auto'>
                <div className='second-chance-logo text-center'>
                    <img src={featuredImage} alt='Second Chance!' className='img-fluid' />
                </div>
                <div className='login-area'>
                    <Welcome />
                    <IfLoggedIn>
                        <div className='col'>
                            <AccountButton>My Profile</AccountButton>
                        </div>
                    </IfLoggedIn>
                    <IfLoggedOut>
                        <div className='d-flex justify-content-center'>
                            <LoginButton>Login</LoginButton>
                            <RegisterButton>Register</RegisterButton>
                        </div>
                    </IfLoggedOut>
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeLoginBox);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import { MarkdownArea } from 'ui-kit';
import { ContentSectionManifest } from './ContentRequireManifest';

const ContentSection = ({ content, cmsSource }) => {
    const [body, setBody] = useState(window.localStorage.getItem(content));

    const getContentPage = async (pageName) => {
        let db = window.localStorage;
        const res = db.getItem(pageName);

        if (res && JSON.parse(res)) {
            const pages = JSON.parse(res).body;

            setBody(pages);
        }
    };

    useEffect(() => {
        if (cmsSource.ready) {
            getContentPage(content);
        }
    }, [content, cmsSource.ready]);

    return <div>{body ? <MarkdownArea components={ContentSectionManifest}>{body}</MarkdownArea> : ''}</div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentSection);

import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';

import BannerCarousel from './BannerCarousel';
import ActiveSweepstakes from './Sweepstakes/ActiveSweepstakes';
import ExpiredSweepstakes from './Sweepstakes/ExpiredSweepstakes';

import './Sweepstakes/style.scss';

export class Sweepstakes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className='promotions-container'>
                <BannerCarousel name='ctl_promo_page_carousel' />
                <ActiveSweepstakes title='Current Promotions' variant='theme-purple' />
                <ExpiredSweepstakes title='Past Promotions' variant='theme-purple' />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sweepstakes);

import React from 'react';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';

const IfLoggedOutTemplate = ({ user, children }) => {
    if (!user.loggedIn) {
        return <>{children}</>;
    } else {
        return null;
    }
};

const IfLoggedOut = connect(mapStateToProps, mapDispatchToProps)(IfLoggedOutTemplate);

export default IfLoggedOut;

import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { Redirect } from "react-router-dom";

import {
    ContentBox,
    ContentBoxHead,
    ContentBoxBody,
    ContentCollapser,
} from '../ContentBox';

import UserLockout from '../../utils/user_lockout';

const PasswordExpired = ({
    user,
    actions,
}) => {

    //* Password expired
    if (!user.actions.includes('change-password')) {
        return <Redirect to="/home" />;
    }

    return (
        <UserLockout disableRedirect='change-password'>
            <div className='login-container'>
                <div className="my-5"/>
                <ContentBox variant="theme-purple">
                    <ContentBoxHead>
                        PASSWORD EXPIRED
                    </ContentBoxHead>
                    <ContentBoxBody>
                        <div className='text-center'>
                            <p>Your password has expired. Please update it now to continue using all of the features of 2nd Chance</p>
                        </div>
                        <a className='btn theme-btn btn-block theme-gray' href='/my-profile'>Update Now</a>
                    </ContentBoxBody>
                </ContentBox>
            </div>
        </UserLockout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordExpired);
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import DrawingEntries from './DrawingEntries';

import { ContentBox, ContentBoxHead, ContentBoxBody,ContentCollapser } from '../ContentBox';

const MyDrawingEntries = ({ winner, actions }) => {
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        async function fetchData() {
            if (!pageLoaded) {
                setPageLoaded(true);
              
                    await actions.winnerActions.getCurrentDrawings();
                
            }
        }
        fetchData();
    }, []);

    return (
        <ContentBox variant='theme-blue'>
            <ContentBoxHead>
                My Drawing Entries
             <ContentCollapser/>
            </ContentBoxHead>
            <ContentBoxBody>
                <DrawingEntries isLoaded={pageLoaded} />
            </ContentBoxBody>
        </ContentBox>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyDrawingEntries);

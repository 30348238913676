import React, { useEffect, useState } from 'react';
import './mcarousel.scss';

const CarouselMultiple = (props) => {
    const { children, show, infiniteLoop } = props;

    const [currentIndex, setCurrentIndex] = useState(infiniteLoop ? 2 : 0);
    const [length, setLength] = useState(children.length);

    const [isRepeating, setIsRepeating] = useState(
        infiniteLoop && children.length > show
    );
    const [transitionEnabled, setTransitionEnabled] = useState(true);

    const [touchPosition, setTouchPosition] = useState(null);

    // Set the length to match current children from props
    useEffect(() => {
        setLength(children.length);
        setIsRepeating(infiniteLoop && children.length > show);
    }, [children, infiniteLoop, show]);

    useEffect(() => {
        if (isRepeating) {
            if (currentIndex === show || currentIndex === length) {
                setTransitionEnabled(true);
            }
        }
    }, [currentIndex, isRepeating, show, length]);

    const next = () => {
        if (isRepeating || currentIndex < length - show) {
            setCurrentIndex((prevState) => prevState + 1);
        }
    };

    const prev = () => {
        if (isRepeating || currentIndex > 0) {
            setCurrentIndex((prevState) => prevState - 1);
        }
    };

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX;
        setTouchPosition(touchDown);
    };

    const handleTouchMove = (e) => {
        const touchDown = touchPosition;

        if (touchDown === null) {
            return;
        }

        const currentTouch = e.touches[0].clientX;
        const diff = touchDown - currentTouch;

        if (diff > 5) {
            next();
        }

        if (diff < -5) {
            prev();
        }

        setTouchPosition(null);
    };

    const handleTransitionEnd = () => {
        if (isRepeating) {
            if (currentIndex === 0) {
                setTransitionEnabled(false);
                setCurrentIndex(length);
            } else if (currentIndex === length + show) {
                setTransitionEnabled(false);
                setCurrentIndex(show);
            }
        }
    };

    const renderExtraPrev = () => {
        let output = [];
        for (let index = 0; index < show; index++) {
            output.push(children[length - 1 - index]);
        }
        output.reverse();
        return output;
    };

    const renderExtraNext = () => {
        let output = [];
        for (let index = 0; index < show; index++) {
            output.push(children[index]);
        }
        return output;
    };

    //? For debug: console.log(currentIndex % children.length);

    return (
        <div className="mcarousel-container carousel-container">
            <div className="mcarousel-wrapper carousel-wrapper">
                {(isRepeating || currentIndex > 0) && (
                    <a
                        className="carousel-control-prev"
                        onClick={prev}
                        role="button"
                        href="#"
                    >
                        <span
                            aria-hidden="true"
                            className="carousel-control-prev-icon"
                        ></span>
                        <span className="sr-only">Prev</span>
                    </a>
                )}

                <div className="carousel-indicators">
                    {children.map((val, index) => {
                        return (
                            <button
                                type="button"
                                data-bs-target=""
                                aria-label="Slide 3"
                                key={index}
                                onClick={() => {
                                    setCurrentIndex((prevState) => index);
                                }}
                                aria-current={`${
                                    currentIndex / index == 1 ? 'true' : ''
                                }`}
                                className={`${
                                    currentIndex % children.length == index
                                        ? 'active'
                                        : ''
                                }`}
                            ></button>
                        );
                    })}
                </div>

                <div
                    className="mcarousel-content-wrapper carousel-content-wrapper"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                >
                    <div
                        className={`mcarousel-content carousel-content show-${show}`}
                        style={{
                            transform: `translateX(-${
                                currentIndex * (200 / show) + 150 / show
                            }%)`,
                            transition: !transitionEnabled ? 'none' : undefined,
                        }}
                        onTransitionEnd={() => handleTransitionEnd()}
                    >
                        {length > show && isRepeating && renderExtraPrev()}
                        {children}
                        {length > show && isRepeating && renderExtraNext()}
                    </div>
                </div>

                {(isRepeating || currentIndex < length - show) && (
                    <a
                        className="carousel-control-next"
                        onClick={next}
                        role="button"
                        href="#"
                    >
                        <span
                            aria-hidden="true"
                            className="carousel-control-next-icon"
                        ></span>
                        <span className="sr-only">Next</span>
                    </a>
                )}
            </div>
        </div>
    );
};

export default CarouselMultiple;

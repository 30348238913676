import React from 'react';
import moment from 'moment';
import Frame from 'react-frame-component';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Media, Modal, Card, Button } from 'react-bootstrap';
// import { getCMSContent } from '../../../utils/cms_copy_provider';

// import lottery_logo from '../../../global/cms/lottery-header-logo.png';

// import '../../../utils/fontAwesome';

// props.state;
// props.state;
// props.expiration;
// props.redeem;
// props.prize_description;
// props.expiration;
// props.prize_name;
// props.rewardId;
// props.Url;
// props.barcodeHost;
// props.linkText;
// props.rewardId;
// props.redeemedText;
// props.expiredText;
// props.failedText;

export default class Coupon extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            redeemed: false,
            expired: false,
            failed: false,
        };
    }

    handleClose = (event) => {
        this.setState({ show: false });
    };

    handleShow = (event) => {
        this.setState({ show: true });
    };

    handleKeyShow = (event) => {
        if (event.key === 'Enter') {
            this.setState({ show: true });
        }
    };

    handleKeyClose = (event) => {
        if (event.key === 'Enter') {
            this.setState({ show: false });
        }
    };

    componentDidMount() {
        if (this.props.state === 'complete') {
            this.setState({ redeemed: true });
        } else if (this.props.state === 'failed') {
            this.setState({ failed: true });
        }

        if (Math.floor(Date.now() / 1000) > this.props.expiration) {
            this.setState({ expired: true });
        }
    }

    redemptionInstructions = () => {
        return { __html: this.props.redeem };
    };

    printCoupon = (iFrameId) => {
        const thisFrame = document.frames
            ? document.frames[iFrameId]
            : document.getElementById(iFrameId);
        const iframeWindow = thisFrame.contentWindow || thisFrame;

        thisFrame.focus();
        iframeWindow.print();

        return false;
    };

    barcodeUrl = (barcodeUrlString = '', barcodeHostString) => {
        return barcodeUrlString.startsWith('http')
            ? barcodeUrlString
            : barcodeHostString + barcodeUrlString;
    };

    descrioptionHTML = () => {
        return { __html: this.props.prize_description };
    };

    render() {
        const expirationDate = moment
            .unix(this.props.expiration)
            .format('MM/DD/YYYY');

        const buildCouponContent = () => {
            return (
                <Card className="shadow text-center">
                    <Card.Body>
                        <div className="row">
                            <div className="col d-flex justify-content-center">
                                <Media className="align-items-center">
                                    <img
                                        className="align-self-center mr-3"
                                        src={this.props.lottery_logo}
                                        alt={
                                            'something' /*getCMSContent('lottery_name')*/
                                        }
                                    />
                                    <Media.Body>
                                        <h2 className="text-left">
                                            <em>{this.props.prize_name}</em>
                                        </h2>
                                    </Media.Body>
                                </Media>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center prize-description">
                                <div
                                    className="mb-3"
                                    dangerouslySetInnerHTML={this.descrioptionHTML()}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md d-print-none">
                                <Button
                                    className="print-coupon-button"
                                    variant="theme"
                                    onClick={() =>
                                        this.printCoupon(
                                            `printCouponIframe-${this.props.rewardId}`
                                        )
                                    }
                                >
                                    Print Coupon
                                </Button>
                            </div>
                            <div className="col-md">
                                <p className="text-center">
                                    Coupon Expiration
                                    <br />
                                    {expirationDate}
                                </p>
                            </div>
                            <div className="col-md">
                                <img
                                    className="mx-auto d-block img-fluid"
                                    src={this.barcodeUrl(
                                        this.props.Url,
                                        this.props.barcodeHost
                                    )}
                                    alt="coupon barcode"
                                />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            );
        };

        const buildCouponElements = () => {
            return (
                <>
                    <a
                        role="button"
                        tabIndex="0"
                        onKeyDown={this.handleKeyShow}
                        onClick={this.handleShow}
                    >
                        {this.props.linkText}
                    </a>
                    <Modal
                        dialogClassName="ensemble-modal"
                        show={this.state.show}
                        onHide={this.handleClose}
                        centered
                    >
                        <Modal.Header>
                            <a
                                className="modal-close-element"
                                title="Close"
                                tabIndex="0"
                                role="button"
                                onKeyDown={this.handleKeyClose}
                                onClick={this.handleClose}
                            >
                                X
                            </a>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-sm-10 offset-sm-1">
                                    {buildCouponContent()}
                                    <div className="row">
                                        <div className="col my-4">
                                            <p className="text-danger text-center">
                                                <strong>
                                                    COUPON WILL BE STORED IN THE
                                                    TICKET HISTORY
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mb-4">
                                            <div
                                                dangerouslySetInnerHTML={this.redemptionInstructions()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Frame
                        className="hidden"
                        id={`printCouponIframe-${this.props.rewardId}`}
                        head={
                            <link
                                rel="stylesheet"
                                href="/assets/css/print-coupon.css"
                            />
                        }
                    >
                        {buildCouponContent()}
                        <div className="row">
                            <div className="col my-4">
                                <div
                                    dangerouslySetInnerHTML={this.redemptionInstructions()}
                                />
                            </div>
                        </div>
                    </Frame>
                </>
            );
        };

        return (
            <>
                {this.state.redeemed
                    ? this.props.redeemedText
                    : this.state.expired
                    ? this.props.expiredText
                    : this.state.failed
                    ? this.props.failedText
                    : buildCouponElements()}
            </>
        );
    }
}

Coupon.defaultProps = {
    linkText: 'View Coupon',
    expiredText: 'Expired Coupon',
    redeemedText: 'Redeemed',
    failedText: 'Reward pending. Please check back later.',
};

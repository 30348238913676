import React from 'react';
import { Modal } from 'react-bootstrap';
import apiClient from '../../Api/api_client';

import { formatDateWithSlashes as formatDate } from '../../utils/formatDates';


export default class CurrentSweeps extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sweepsData: [],
            show: false,
            failed: false,
        };
    }

    handleClose = () => {
        this.setState({ show: false });
    };

    handleShow = () => {
        this.setState({ show: true });
    };

    handleKeyShow = (event) => {
        if (event.key === 'Enter') {
            this.setState({ show: true });
        }
    };

    handleKeyClose = (event) => {
        if (event.key === 'Enter') {
            this.setState({ show: false });
        }
    };

    async componentDidMount() {
        if (this.props.state != 'complete') {
            this.setState({ failed: true });
        } else {
            try {
                const result = await apiClient().getSweeps({
                    sweeps_id: this.props.sweepId,
                });
                this.setState({ sweepsData: result });
            } catch (error) {
                console.error('sweepsAPI error: ' + error);
            }
        }
    }

    render() {
        const buildTable = () => {
            if (
                this.state.sweepsData[0] &&
                this.state.sweepsData[0].drawing_groups.length > 0
            ) {
                const drawingEntries = (thisDrawing) => {
                    if (
                        thisDrawing.drawing_summaries &&
                        thisDrawing.drawing_summaries.length > 0 &&
                        thisDrawing.drawing_summaries[0].entry_count
                    ) {
                        return thisDrawing.drawing_summaries[0].entry_count;
                    } else {
                        return String.fromCharCode(8212);
                    }
                };

                const prizeListItems = (prizes) => {
                    const sortedPrizes = prizes.sort((a, b) =>
                        a.rank > b.rank ? 1 : -1
                    );

                    sortedPrizes.forEach((prize) => {
                        prize.quantityString =
                            prize.quantity > 1
                                ? '(' + prize.quantity + ' winners)'
                                : '';
                    });

                    return (
                        <>
                            {sortedPrizes.map((prize) => {
                                return (
                                    <li key={`"prize-item-"${prize.rank}`}>
                                        {prize.name} {prize.quantityString}
                                    </li>
                                );
                            })}
                        </>
                    );
                };

                return (
                    <>
                        <table
                            key="sweep-table-{this.state.sweepsData.id}"
                            className="table table-striped d-none d-md-table sweeps-table"
                        >
                            <caption>{this.state.sweepsData[0].name}</caption>
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>Prizes</th>
                                    <th>Entry Period</th>
                                    <th>Drawing Date</th>
                                    <th>Number of Entries</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.sweepsData[0].drawing_groups.map(
                                    (drawing_group) => {
                                        return (
                                            <>
                                                {drawing_group.drawings.map(
                                                    (drawing) => {
                                                        return (
                                                            <tr
                                                                key={
                                                                    'draw-' +
                                                                    drawing.id
                                                                }
                                                            >
                                                                <td>
                                                                    {
                                                                        drawing_group.name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <ul className="list-unstyled prizes-list mb-0">
                                                                        {prizeListItems(
                                                                            drawing_group.prizes
                                                                        )}
                                                                    </ul>
                                                                </td>
                                                                <td>
                                                                    {formatDate(
                                                                        drawing.quantum_start
                                                                    )}{' '}
                                                                    -{' '}
                                                                    {formatDate(
                                                                        drawing.quantum_end
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {formatDate(
                                                                        drawing.draw_date
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {drawingEntries(
                                                                        drawing
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                        <div className="stacked-sweeps-table-container d-md-none">
                            {this.state.sweepsData[0].drawing_groups.map(
                                (drawing_group) => {
                                    return (
                                        <>
                                            {drawing_group.drawings.map(
                                                (drawing) => {
                                                    return (
                                                        <div
                                                            key={
                                                                'drawmob-' +
                                                                drawing.id
                                                            }
                                                            className="row"
                                                        >
                                                            <div className="col">
                                                                <table className="table">
                                                                    <tr>
                                                                        <td>
                                                                            <strong>
                                                                                Drawing
                                                                            </strong>
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                drawing_group.name
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <strong>
                                                                                Prizes
                                                                            </strong>
                                                                        </td>
                                                                        <td>
                                                                            <ul className="list-unstyled prizes-list mb-0">
                                                                                {prizeListItems(
                                                                                    drawing_group.prizes
                                                                                )}
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <strong>
                                                                                Entry
                                                                                Period
                                                                            </strong>
                                                                        </td>
                                                                        <td>
                                                                            {formatDate(
                                                                                drawing.quantum_start
                                                                            )}{' '}
                                                                            -{' '}
                                                                            {formatDate(
                                                                                drawing.quantum_end
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <strong>
                                                                                Drawing
                                                                                Date
                                                                            </strong>
                                                                        </td>
                                                                        <td>
                                                                            {formatDate(
                                                                                drawing.draw_date
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <strong>
                                                                                Number
                                                                                of
                                                                                Entries
                                                                            </strong>
                                                                        </td>
                                                                        <td>
                                                                            {drawingEntries(
                                                                                drawing
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </>
                                    );
                                }
                            )}
                        </div>
                    </>
                );
            }
        };

        const buildLinkAndModal = () => {
            return (
                <>
                    <a
                        role="button"
                        tabIndex="0"
                        onKeyDown={this.handleKeyShow}
                        onClick={this.handleShow}
                    >
                        {this.props.linkText}
                    </a>

                    <Modal
                        dialogClassName="ensemble-modal current-sweeps-modal"
                        show={this.state.show}
                        onHide={this.handleClose}
                    >
                        <Modal.Header>
                            <a
                                className="modal-close-element"
                                title="Close"
                                tabIndex="0"
                                role="button"
                                onKeyDown={this.handleKeyClose}
                                onClick={this.handleClose}
                            >
                                X
                                {/* <FontAwesomeIcon
                                    icon={['fas', 'times-circle']}
                                    size="2x"
                                /> */}
                            </a>
                        </Modal.Header>
                        <Modal.Body>{buildTable()}</Modal.Body>
                    </Modal>
                </>
            );
        };

        return (
            <>
                {this.state.failed
                    ? this.props.failedText
                    : buildLinkAndModal()}
            </>
        );
    }
}

CurrentSweeps.defaultProps = {
    linkText: 'View Drawings',
    failedText: 'Reward pending. Please check back later.',
};

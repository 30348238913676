import React from 'react';

import featuredImage from '../../../assets/images/second_chance_burst.png';

const FeedbackSubHeader = () => {
    return (
        <div className='row'>
            <div id='about' className='my-3 col-lg-4'>
                <img src={featuredImage} alt='Second Chance!' className='img-fluid d-block mx-auto rounded-image' />
            </div>
            <div className='my-3 col-lg-8'>
                <h3>About 2nd Chance</h3>
                <p>
                    Enter your non-winning instant tickets into the CT Lottery's 2nd Chance Drawings for a chance to win additional prizes! Check back often for
                    featured games, drawing deadlines and to see if you're a winner!
                </p>
            </div>
        </div>
    );
};

export default FeedbackSubHeader;

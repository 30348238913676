import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../Store';
import { CMSContent } from 'sg-ui-components';

const Footer = ({ cmsSourceFirebase }) => {
    return (
        <div id="footer" className='footer'>
            <CMSContent localStorageObject='webContent' contentPath='data.siteDisplay.footer.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
        </div>
    );
};

Footer.propTypes = {
    secondaryMenu: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

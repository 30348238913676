import React from 'react';
import { ContentBox, ContentBoxBody, ContentBoxHead, ContentCollapser } from '../Components/ContentBox';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import IfLoggedIn from '../Components/IfLoggedIn';
import IfLoggedOut from '../Components/IfLoggedOut';
import BannerCarousel from '../Components/BannerCarousel';
import HomeLoginBox from '../Components/HomeLoginBox';
import ContentSection from '../Components/ContentSection';
import ActiveSweepstakes from '../Components/Sweepstakes/ActiveSweepstakes';
import { formatDateTBD, getCMSObject, CMSContent, NextDrawings, TeleScript } from 'sg-ui-components';
import promotionConfig from '../promotion_config';
import UserLockout from '../utils/user_lockout';

const homePage = ({ user, winner, cmsSourceFirebase, actions }) => {
    const telescriptNextDrawing = getCMSObject('data.components.teleScripts.nextDrawing.jsonBlock');

    const renderCarouselItem = (drawing) => (
        <div className='next-drawing-card mt-2 mb-5'>
            <h3>{drawing.promotion}</h3>
            <div>{drawing.drawing_date}</div>
            <hr class='next-drawing-divider' />

            <div>
                <TeleScript line={telescriptNextDrawing?.promotionText}>Want More Chances to Win? </TeleScript>
            </div>

            <Button className='next-drawing-button' href='/enter'>
                Enter Now
            </Button>
        </div>
    );

    return (
        <div class='home-container'>
            <div class='row no-gutters'>
                <div class='col-md-3'>
                    <HomeLoginBox />
                </div>
                <div class='col-md-9'>
                    <div class='h-100 hero-carousel'>
                        <BannerCarousel name='ctl_home_page_carousel' />
                    </div>
                </div>
            </div>
            <div class='row justify-content-center'>
                <div class='col-md-6 p-3 entry-box'>
                    <IfLoggedIn>
                        <UserLockout>
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.webNotices'
                                className=''
                                noticePlacement='web-notice-ticket-entry'
                                cmsSourceFirebase={cmsSourceFirebase}
                                delayToShow={1000}>
                                <ContentSection content='ensemble_site_enter_module' />
                            </CMSContent>
                        </UserLockout>
                    </IfLoggedIn>
                    <IfLoggedOut>
                        <ContentBox variant='theme-green'>
                            <ContentBoxHead>ENTER TICKETS</ContentBoxHead>
                            <ContentBoxBody>
                                <div class='enter-logged-out text-center'>
                                    <div class='line-under'>Enter eligible scratch tickets into 2nd Chance promotions from the CT Lottery!</div>
                                    <div class='good-luck'>
                                        <a href='/enter'>Want to enter your tickets?</a>
                                        <br />
                                        <a href='/login'>Login or Register for a 2nd chance account. Good Luck!</a>
                                    </div>
                                </div>
                            </ContentBoxBody>
                        </ContentBox>
                    </IfLoggedOut>
                </div>
                <div class='col-md-6 p-3'>
                    <NextDrawings
                        {...{ user, winner, cmsSourceFirebase, actions }}
                        formatDate={formatDateTBD?.dateWithDayOfTheWeek}
                        telescript={telescriptNextDrawing ?? ''}
                        options={{
                            variant: 'theme-blue',
                            limitOnePerSweepstake: promotionConfig?.nextDrawingsLimitOnePerSweepstake ?? true,
                            maxElement: promotionConfig?.nextDrawingsMaxElement ?? 5,
                            promotionDetailsLink: '/promotion',
                        }}
                        renderCarouselItem={renderCarouselItem}
                    />
                </div>
            </div>
            <div class='row justify-content-center'>
                <div class='col-12 p-3'>
                    <ActiveSweepstakes displayCount={6} title='PROMOTIONS' variant='theme-purple' buttons='view-all-promotions' />
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(homePage);

import React, { useContext, useState, useEffect } from 'react';
import { DataProvider, useData } from '../DataProviders/DataContext';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import WinnerLink from '../Sweeps/WinnerLink';

import { formatDate, formatDateTimeWithDayOfTheWeek as formatDateTime } from '../../utils/formatDates';

const FormatExpiredDrawingsTableTemplate = ({ winner, actions }) => {
    const { state, dispatch } = useData();
    const [pageLoaded, setPageLoaded] = useState(false);

    //? for debug: console.log('🚩 Expired Drawing Data', state.data);

    useEffect(async () => {
        if (state.data.length > 0) {
            dispatch({
                type: 'assign',
                payload:
                    state.data.map((val) => {
                        return {
                            ...val,
                            promotion: <a href={`/promotion?id=${val.sweepstake_id}`}>{val.sweepstake}</a>,
                            entry_period: `${formatDateTime(val.quantum_start)}
                                            -
                                            ${formatDateTime(val.quantum_end)}`,
                            drawing_date: (
                                <>
                                    <div>{formatDate(val.draw_date)}</div>
                                    { val.certified && <div>
                                        <WinnerLink selected={val} />
                                    </div> }
                                </>
                            ),
                            has_winners: val.certified,
                        };
                    }) || [],
            });
        }
    }, [state]);

    return <></>;
};

const FormatExpiredDrawingsTable = connect(
    mapStateToProps,
    mapDispatchToProps
)(FormatExpiredDrawingsTableTemplate);

export { FormatExpiredDrawingsTable };

/////////////// EXAMPLE DATA ////////////////
// {
//     type: 'winner/getExpiredDrawings',
//     payload: {
//         "total": 10,
//         "page": 1,
//         "drawings": [{
//             "id": 26,
//             "quantum_start": 1633924800,
//             "quantum_end": 1635220799,
//             "draw_date": 1635220800,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "26",
//             "sweepstake": "Event Day Sweepstakes",
//             "drawing_group": "Drawing 2"
//         }, {
//             "id": 19,
//             "quantum_start": 1633924800,
//             "quantum_end": 1635220799,
//             "draw_date": 1635220800,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "19",
//             "sweepstake": "$100,000 50th Anniversary Sweeps",
//             "drawing_group": "Drawing 2"
//         }, {
//             "id": 13,
//             "quantum_start": 1633924800,
//             "quantum_end": 1635220799,
//             "draw_date": 1635220800,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "13",
//             "sweepstake": "$50,000 50th Anniversary Sweeps",
//             "drawing_group": "Drawing 2"
//         }, {
//             "id": 8,
//             "quantum_start": 1633924800,
//             "quantum_end": 1635220799,
//             "draw_date": 1635220800,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "8",
//             "sweepstake": "$20,000 50th Anniversary Sweeps",
//             "drawing_group": "Drawing 2"
//         }, {
//             "id": 2,
//             "quantum_start": 1633924800,
//             "quantum_end": 1635220799,
//             "draw_date": 1635220800,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "2",
//             "sweepstake": "$10,000 50th Anniversary Sweeps",
//             "drawing_group": "Drawing 2"
//         }, {
//             "id": 24,
//             "quantum_start": 1633924800,
//             "quantum_end": 1634615999,
//             "draw_date": 1634616000,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "24",
//             "sweepstake": "Event Day Sweepstakes",
//             "drawing_group": "Drawing 1"
//         }, {
//             "id": 18,
//             "quantum_start": 1633924800,
//             "quantum_end": 1634615999,
//             "draw_date": 1634616000,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "18",
//             "sweepstake": "$100,000 50th Anniversary Sweeps",
//             "drawing_group": "Drawing 1"
//         }, {
//             "id": 12,
//             "quantum_start": 1633924800,
//             "quantum_end": 1634615999,
//             "draw_date": 1634616000,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "12",
//             "sweepstake": "$50,000 50th Anniversary Sweeps",
//             "drawing_group": "Drawing 1"
//         }, {
//             "id": 6,
//             "quantum_start": 1633924800,
//             "quantum_end": 1634615999,
//             "draw_date": 1634616000,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "6",
//             "sweepstake": "$20,000 50th Anniversary Sweeps",
//             "drawing_group": "Drawing 1"
//         }, {
//             "id": 1,
//             "quantum_start": 1633924800,
//             "quantum_end": 1634615999,
//             "draw_date": 1634616000,
//             "certified": false,
//             "state": "active",
//             "summarized": true,
//             "extra": null,
//             "drawing_group_id": "1",
//             "sweepstake": "$10,000 50th Anniversary Sweeps",
//             "drawing_group": "Drawing 1"
//         }]
//     }
// }

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import * as yup from 'yup';

import { PasswordField } from '../RegisterForm/password_field';

import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser } from '../ContentBox';
import { validateNewPassword, validatePasswordDetailed } from '../../validationRules';

import { ErrorMessage } from 'sg-ui-components';

const UpdateLogin = ({ verifyFields, setVerifyFields, user, actions }) => {
    const initialStepFields = {
        email: user.player.email ?? '',
        password: '',
        new_password: '',
        confirm: '',
    };

    const schema = yup.object().shape({
        email: yup
            .string()
            .email('Email is invalid')
            .required('Email is required')
            .max(200, 'Email cannot exceed 200 characters'),

        password: yup
            .string()
            .required('Current Password is required'),

        new_password: validateNewPassword,

        confirm: yup
            .string()
            .required('Password Confirmation is required')
            .oneOf([yup.ref('new_password'), null], 'New Passwords must match'),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [passwordStatus, setPasswordStatus] = useState('initial');
    const [error, setError] = useState('');
    const [localErrors, setLocalErrors] = useState({});

    // if the form field changes, we will change the value in the store and
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trimStart();
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });

        //* Password inner change
        if (name === 'new_password') {
            checkPassword(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(stepFields);

        if (valid) {
            setError('');
            setLocalErrors({});

            await actions.userActions.updateSection({ section: 'login', status: 'initial' });

            const payloadData = {
                firstname: user.player.firstname ?? '',
                lastname: user.player.lastname ?? '',
                address: user.player.address ?? {},

                email: stepFields.email,
                old_password: stepFields.password,
                password: stepFields.new_password,
                confirm: stepFields.confirm,
            };

            await actions.userActions.update(payloadData);
        } else {
            await schema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                setLocalErrors(getValidationErrors(err));
                setError(err.message);
            });
        }
    };

    const getValidationErrors = (err) => {
        const validationErrors = {};

        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);

        return validationErrors;
    };

    //  Inline validation for Password to make sure that it matches the password Schema
    const checkPassword = async (password) => {
        const passwordSchema = validatePasswordDetailed;
        const valid = await passwordSchema.isValid(password);

        if (valid) {
            setPasswordStatus('valid');
        } else {
            await passwordSchema.validate(password, {abortEarly: true}).catch(function (err) {
                setPasswordStatus(err.message);
            });
        }
    };

    const passwordStatusForm = (status) => {
        if (status === 'initial') return null;

        return <>
            { status === 'valid' ?
                <div className='alert alert-success p-2 my-2' role='alert'>
                    Your password matches the criteria.
                </div>
                :
                <div className='alert alert-danger p-2 my-2' role='alert'>
                    {status}
                </div>
            }
        </>
    }

    return (
        <ContentBox variant='theme-blue'>
            <ContentBoxHead>LOGIN</ContentBoxHead>
            <ContentBoxBody>
                <div className='registration-step'>
                    <div className='inner-step'>
                        {error ? (
                            <div className='alert alert-danger text-center' role='alert'>
                                {Object.values(localErrors).map((err, index) => {
                                    return <p key={index}>{err}</p>;
                                })}
                            </div>
                        ) : null}

                        {user.updateSection.section === 'login' && user.updateSection.status === 'success' && (
                            <div className='alert alert-success text-center' role='alert'>
                                Login Information updated.
                            </div>
                        )}

                        {user.updateSection.section === 'login' && user.updateSection.status === 'error' && user.updateSection.code && (
                            <div className='alert alert-danger text-center' role='alert'>
                                <ErrorMessage mode='return' code={user.updateSection.code} collection='data.messages.ticketErrorMessages.jsonBlock' />
                            </div>
                        )}

                        <div className='form-group'>
                            <label for='enterEmail'>Email</label>
                            <input
                                type='email'
                                className='form-control'
                                autoComplete='off'
                                id='enterEmail'
                                value={stepFields.email}
                                name='email'
                                onChange={handleChange}
                                placeholder='Enter email'
                            />
                        </div>

                        <div className='form-group'>
                            <label for='password'>Current Password</label>
                            <PasswordField name='password' value={stepFields.password} handleChange={handleChange} />
                        </div>

                        <div className='form-group'>
                            <label for='password'>New Password</label>
                            <PasswordField
                                id='new_password'
                                name='new_password'
                                value={stepFields.new_password}
                                handleChange={handleChange}
                                newPassword={true}
                            />
                            <small id='passwordHelp' className='form-text text-muted'>
                                <ul className='mx-0'>
                                    <li>Minimum password length: 10</li>
                                    <li>All characters are accepted</li>
                                    <li>At least one lower case</li>
                                    <li>At least one uppercase</li>
                                    <li>At least one number</li>
                                    <li>At least one special character</li>
                                    <li>Password must not be the same as previous 10 passwords.</li>
                                </ul>
                            </small>
                            <span toggle='#password-field' className='fa fa-fw fa-eye field-icon toggle-password' />
                            { passwordStatusForm(passwordStatus) }
                        </div>

                        <div className='form-group'>
                            <label for='confirmPassword'>Confirm Password</label>
                            <PasswordField name='confirm' value={stepFields.confirm} autoComplete='off' handleChange={handleChange} />
                            <small id='confirmHelp' className='form-text text-muted'>
                                Please retype your password.
                            </small>
                        </div>

                        <p className='text-center my-2'>
                            Be sure to click the 'Update Information' button when you are done or your changes will not be saved.
                        </p>

                        <button type='button' className='btn theme-btn btn-block theme-gray' onClick={handleSubmit}>
                            Update Information
                        </button>
                    </div>
                </div>
            </ContentBoxBody>
        </ContentBox>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLogin);
